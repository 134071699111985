import { Mapper } from 'src/app/core/base/Mapper';
import { UserDTO, CreateUserResponseDTO, RecoverUserResponseDTO} from './UserDTO';
import { UserModel, CreateUserResponseModel, CreateUserModel,
RecoverUserModel, RecoverUserResponseModel } from '../../core/model/UserModel';

export class UserMapper implements Mapper<UserDTO, UserModel> {

    mapFrom(userDTO: UserDTO): UserModel {
        return {
            id: userDTO.id,
            firstName: userDTO.firstName,
            lastName: userDTO.lastName,
            user: userDTO.firstName
        };
    }

    mapTo(userModel: UserModel): UserDTO {
        return {
            id: userModel.id,
            firstName: userModel.firstName,
            lastName: userModel.lastName
        }
    }
}

export class UserCreateResponseMapper implements Mapper<CreateUserResponseDTO, CreateUserResponseModel> {
    mapFrom(usercreateDTO: CreateUserResponseDTO): CreateUserResponseModel {
        let model = {
            result: usercreateDTO.result,
            message: usercreateDTO.message,
            data: {
                id: usercreateDTO.data.id,
                chrNombre: usercreateDTO.data.chrNombre,
                token: usercreateDTO.data.token,
                intFKIDMunicipio: usercreateDTO.data.intFKIDMunicipio,
                mensaje: usercreateDTO.data.mensaje,
            }
        };
        return model;
    }

    mapTo(usercreateModel: CreateUserResponseModel): CreateUserResponseDTO {
        return {
            result: usercreateModel.result,
            message: usercreateModel.message,
            data: null,
        }
    }
}

export class UserRecoveryResponseMapper implements Mapper<RecoverUserResponseDTO, RecoverUserResponseModel> {
    mapFrom(recoverDTO: RecoverUserResponseDTO): RecoverUserResponseModel {
        let model = {
            result: recoverDTO.result,
            message: {
                emailNoExist: recoverDTO.message.emailNoExist,
            }
        };
        return model;
    }

    mapTo(recoverModel: RecoverUserResponseModel): RecoverUserResponseDTO {
        return {
            result: recoverModel.result,
            message: null,
        }
    }
}
