import { LicenseRepository } from 'src/app/core/repositories/LicenseRepository';
import {
    LicenseRequestModel,
    LicenseResponseModel
} from 'src/app/core/model/LicenseModel';
import { Observable, throwError } from 'rxjs';
import { LicenseResponseMapper, LicenseRequestMapper } from 'src/app/data/dto/LicenseMapper';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { LicenseResponseDTO } from 'src/app/data/dto/LicenseDTO';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
    providedIn: 'root'
})
export class LicenseRestRepository implements LicenseRepository {
    private headers: HttpHeaders;
    mapper = new LicenseResponseMapper();
    requestMapper = new LicenseRequestMapper();

    constructor(private http: HttpClient, private authService: AuthService) {
        let token = this.authService.currentUserValue.token
        this.headers = new HttpHeaders({
            'Content-Type': "application/json",
            'Authorization': "Bearer "+ token
        });
    }

    fetch(request: LicenseRequestModel): Observable<LicenseResponseModel> {
        let endpoint = environment.requests_endpoint;
        let requestDTO: any = this.requestMapper.mapTo(request);
        let params = new HttpParams({
            fromObject: requestDTO
        })
        let httpOptions = { headers: this.headers, params: params };
        return this.http
            .get<LicenseResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(this.mapper.mapFrom));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
