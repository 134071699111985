import { Observable } from 'rxjs';
import { RequirementPdfDTO, AcusePdfDTO, AcuseVisualPdfDTO } from '../../data/dto/UploadFileDTO';

export abstract class RequirementPdfRepository {
    abstract requirementsPdf(file: RequirementPdfDTO): Observable<any>;
    abstract acusePdf(file: AcusePdfDTO): Observable<any>;
    abstract acuseDesistPdf(file: AcusePdfDTO): Observable<any>;
    abstract acuseDiscardPdf(file: AcusePdfDTO): Observable<any>;
    abstract acuseVisualPdf(file: AcuseVisualPdfDTO): Observable<any>;
    abstract acuseNoVisualPdf(file: AcuseVisualPdfDTO): Observable<any>;
    abstract historialPdf(file: AcusePdfDTO): Observable<any>;

}
