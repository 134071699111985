import { Mapper } from 'src/app/core/base/Mapper';
import { UsersListResponseDTO, UsersListRequestDTO } from './UsersListDTO';
import { UsersListRequestModel, UsersListResponseModel} from 'src/app/core/model/UsersListModel';
import { DataTableHelper } from './DataTableHelper';

export class UsersListResponseMapper implements Mapper<UsersListResponseDTO, UsersListResponseModel> {

    mapFrom(dto: UsersListResponseDTO): UsersListResponseModel {
        const model = {
            result: dto.result,
            usuarios: dto.usuarios.map( item => {
                return {
                    id: item.id,
                    chrUsuario: item. chrUsuario,
                    chrNombre: item.chrNombre,
                    chrPaterno: item.chrPaterno,
                    intFKIDRol: item.intFKIDRol,
                    chrCorreo: item.chrCorreo,
                    chrTelefono: item.chrTelefono,
                    chrDireccion: item.chrDireccion,
                    chrColonia: item.chrColonia,
                    chrCP: item.chrCP,
                    intFKIDMunicipio: item.intFKIDMunicipio,
                    rol: {
                      id: item.rol.id,
                      chrRol: item.rol.chrRol
                    },
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: UsersListResponseModel): UsersListResponseDTO {
        return {
            result: model.result,
            usuarios: null
        };
    }
}

export class UsersListRequestMapper implements Mapper<UsersListRequestDTO, UsersListRequestModel> {
    mapFrom(dto: UsersListRequestDTO): UsersListRequestModel {
        const model: UsersListRequestModel = {
            idTown: dto.idMunicipio,
            token: '',
            dataTableParams: null
        };
        return model;
    }

    mapTo(model: UsersListRequestModel): UsersListRequestDTO {
        return {
            idMunicipio: model.idTown,
            token: '',
            draw: model.dataTableParams.draw,
            length: model.dataTableParams.length,
            start: model.dataTableParams.start,
            search: model.dataTableParams.search.value,
            order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}
