import { DataTableParamsModel } from 'src/app/core/model/DataTableParamsModel';

export class DataTableHelper {
    static makeOrderObject(dataTableParams: DataTableParamsModel): string {
        let orderJSON = dataTableParams.order.map(item => {
            return {
                "column": dataTableParams.columns[item.column].data,
                "dir": item.dir
            };
        });
        return JSON.stringify(orderJSON);
    }
}
