import { Observable } from 'rxjs';
import { RequestTypeRequestModel, RequestTypeResponseModel} from '../model/RequestTypeModel';
import { RequestListResponseModel, RequestListRequestModel, ListRequestModel, ListResponseModel} from '../model/RequestListModel';
import { RequestTramiteResponseDTO, RequestTramiteRequestDTO } from '../../data/dto/RequestTramiteDTO';
import {  RequestHistoryModel, RequestResponseHistoryModel,  } from 'src/app/core/model/HistoryModel';

export abstract class RequestTypeRepository {
    abstract fetch(request: RequestTypeRequestModel): Observable<RequestTypeResponseModel>;
    abstract list(request: RequestListRequestModel): Observable<RequestListResponseModel>;
    abstract listRequests(idMunicipio: ListRequestModel): Observable<ListResponseModel>;
    abstract historyfetch(request: RequestHistoryModel): Observable<RequestResponseHistoryModel>;
    abstract listTramites(request: RequestTramiteRequestDTO): Observable<RequestTramiteResponseDTO>;
}
