import { Component, OnInit } from '@angular/core';
import { Localization } from '../../localization';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './sidebar-footer.component.html',
  styleUrls: ['./sidebar-footer.component.scss']
})
export class SidebarFooterComponent implements OnInit {
  logoImg: string;
  namePortal: string;
  localization: Localization;

  constructor(localization: Localization) {
    this.localization = localization;
  }

  ngOnInit() {
    this.logoImg = environment.logo_portal;
    this.namePortal = environment.name_app;
  }
}
