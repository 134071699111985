import { RequestTramiteRequestDTO, RequestTramiteResponseDTO } from './RequestTramiteDTO';
import { Mapper } from 'src/app/core/base/Mapper';
import { RequestTramiteRequestModel, RequestTramiteResponseModel } from '../../core/model/RequestTramiteModel';
import { DataTableHelper } from './DataTableHelper';

export class RequestTramiteResponseMapper implements Mapper<RequestTramiteResponseDTO, RequestTramiteResponseModel> {
    mapFrom(dto: RequestTramiteResponseDTO): RequestTramiteResponseModel {
        const model = {
            result: dto.result,
            requests: dto.requests.map( item => {
                return {
                    id: item.id,
                    name: item.chrTramite,
                    description: item.chrDescripcion,
                    enabled: item.bolActivo,
                    idTown: item.intFKIDMunicipio
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: RequestTramiteResponseModel): RequestTramiteResponseDTO {
        return {
            result: model.result,
            requests: null
        };
    }
}

export class RequestTramiteRequestMapper implements Mapper<RequestTramiteRequestDTO, RequestTramiteRequestModel> {
    mapFrom(dto: RequestTramiteRequestDTO): RequestTramiteRequestModel {
        const model = {
            idTown: dto.idMunicipio,
            token: '',
            dataTableParams: null
        };
        return model;
    }

    mapTo(model: RequestTramiteRequestModel): RequestTramiteRequestDTO {
        return {
            idMunicipio: model.idTown,
            // draw: model.dataTableParams.draw,
            // length: model.dataTableParams.length,
            // start: model.dataTableParams.start,
            // search: model.dataTableParams.search.value,
            // order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}
