import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/shared/localization';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  localization: Localization;
  constructor(
    localization: Localization
  ) {
    this.localization = localization;
  }

  ngOnInit() {
  }

}
