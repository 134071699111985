import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MessageRepository } from '../../repositories/MessageRepository';
import { MessageRequestModel, MessageResponseModel } from '../../model/MessageModel';

@Injectable({
    providedIn: 'root'
})
export class FetchMessageUseCase implements UseCase<MessageRequestModel, MessageResponseModel> {
    constructor(
        private messageRepository: MessageRepository,
    ) { }

    execute(message: MessageRequestModel): Observable<MessageResponseModel> {
        let response = this.messageRepository.fetch(message);
        return response;
    }
}