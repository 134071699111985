import { Mapper } from 'src/app/core/base/Mapper';
import { RequestResponseDTO, RequestRequestDTO } from './RequestDTO';
import { RequestResponseModel, RequestRequestModel } from 'src/app/core/model/RequestModel';
import { DataTableHelper } from './DataTableHelper';
import { StatusMapper } from './StatusMapper';


export class RequestResponseMapper implements Mapper<RequestResponseDTO, RequestResponseModel> {

  mapFrom(dto: RequestResponseDTO): RequestResponseModel {
        const statusMapper = new StatusMapper();
        const model = {
            result: dto.result,
            requests: dto.seguimientos.map( item => {
                return {
                    id: item.id,
                    folio: item.chrFolio,
                    internalFolio: item.chrFolioInterno,
                    intFKIDCitizen: item.intFKIDCiudadano,
                    intFKIDZona: item.intFKIDZona,
                    createdAt: item.createdAt,
                    updatedAt: item.updatedAt,
                    bolDesechoSolventado: item.bolDesechoSolventado,
                    procedure: {
                        id: item.tramite.id,
                        name: item.tramite.chrTramite,
                        bolPrimeroPago: item.tramite.bolPrimeroPago,
                        bolDespuesPago: item.tramite.bolDespuesPago
                    },
                    campos: item.campos,
                    zona: item.zona,
                    digitalSign: item.chrFirmaDigital,
                    property: {
                        name: item.predio.chrClaveCatastral
                    },
                    status: statusMapper.mapFrom(item.estatus),
                    user: {
                        chrName: item.usuario.chrNombre,
                        chrPaternal: item.usuario.chrPaterno,
                    },
                    dateLog: item.dateLog,
                    intFKIDExpediente: item.intFKIDExpediente,
                    bolPagado: item.bolPagado,
                    bolOnePreventiva: item.bolOnePreventiva,
                    bolExpiroPreventiva: item.bolExpiroPreventiva,
                    bolSolventado: item.bolSolventado,
                    usuarioAdmin: item.usuarioAdmin
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: RequestResponseModel): RequestResponseDTO {
        return {
            result: model.result,
            seguimientos: null
        };
    }
}

export class RequestRequestMapper implements Mapper<RequestRequestDTO, RequestRequestModel> {
    mapFrom(dto: RequestRequestDTO): RequestRequestModel {
        const model: RequestRequestModel = {
            idTown: dto.idMunicipio,
            requestType: dto.idTramite,
            bolLicense: dto.bolLicencia,
            bolInitialStatus: dto.bolInicial,
            bolStartedStatus: dto.bolIniciado,
            bolProgressStatus: dto.bolEnProgreso,
            bolSettlementStatus: dto.bolSolventacion,
            bolFinalStatus: dto.bolFinal,
            bolInspeccionStatus: dto.bolInspeccion,
            bolAutorizacionStatus: dto.bolAutorizacion,
            bolPreventivaStatus: dto.bolPreventivaSec,
            bolPagoStatus: dto.bolPago,
            bolSolved: dto.bolSolventado,
            bolInspeccionadoStatus: dto.bolInspeccionado,
            bolDesistimientoStatus: dto.bolDesistimiento,
            bolDesechadoStatus: dto.bolDesechado,
            bolDirector: dto.bolDirector,
            bolPendienteConfirmacion: dto.bolPendienteConfirmacion,
            bolOnePreventiva: dto.bolOnePreventiva,
            bolExpiroPreventiva: dto.bolExpiroPreventiva,
            usuarioAdmin: dto.usuarioAdmin,
            token: '',
            dataTableParams: null
        };
        return model;
    }

    mapTo(model: RequestRequestModel): RequestRequestDTO {
        return {
            idMunicipio: model.idTown,
            idTramite: model.requestType,
            token: '',
            bolLicencia: model.bolLicense,
            bolInicial: model.bolInitialStatus,
            bolIniciado: model.bolStartedStatus,
            bolEnProgreso: model.bolProgressStatus,
            bolSolventacion: model.bolSettlementStatus,
            bolFinal: model.bolFinalStatus,
            bolInspeccion: model.bolInspeccionStatus,
            bolAutorizacion: model.bolAutorizacionStatus,
            bolPago: model.bolPagoStatus,
            bolSolventado: model.bolSolved,
            bolInspeccionado: model.bolInspeccionadoStatus,
            bolDesistimiento: model.bolDesistimientoStatus,
            bolDesechado: model.bolDesechadoStatus,
            bolDirector: model.bolDirector,
            bolPendienteConfirmacion: model.bolPendienteConfirmacion,
            bolPreventivaSec: model.bolPreventivaStatus,
            draw: model.dataTableParams.draw,
            length: model.dataTableParams.length,
            start: model.dataTableParams.start,
            search: model.dataTableParams.search.value,
            order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}
