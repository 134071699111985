import { Mapper } from 'src/app/core/base/Mapper';
import { LicenseResponseDTO, LicenseRequestDTO } from './LicenseDTO';
import { LicenseResponseModel, LicenseRequestModel } from 'src/app/core/model/LicenseModel';
import { DataTableHelper } from './DataTableHelper';

export class LicenseResponseMapper implements Mapper<LicenseResponseDTO, LicenseResponseModel> {
    mapFrom(dto: LicenseResponseDTO): LicenseResponseModel {
        let model = {
            result: dto.result,
            licenses: dto.licencias.map( item => {
                return {
                    id: item.id,
                    folio: item.folio,
                    applicant: item.applicant,
                    turn: item.turn,
                    procedure: item.procedure
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: LicenseResponseModel): LicenseResponseDTO {
        return {
            result: model.result,
            licencias: null
        };
    }
}

export class LicenseRequestMapper implements Mapper<LicenseRequestDTO, LicenseRequestModel> {
    mapFrom(dto: LicenseRequestDTO): LicenseRequestModel {
        let model: LicenseRequestModel = {
            idTown: dto.idMunicipio,
            licenseType: dto.idTramite,
            token: "",
            dataTableParams: null
        };
        return model;
    }

    mapTo(model: LicenseRequestModel): LicenseRequestDTO {
        return {
            idMunicipio: model.idTown,
            idTramite: model.licenseType,
            token: "",
            draw: model.dataTableParams.draw,
            length: model.dataTableParams.length,
            start: model.dataTableParams.start,
            search: model.dataTableParams.search.value,
            order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}