import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../service/auth.service';

@Injectable({ providedIn: 'root' })
export class OfficerDirectorSecretary implements CanActivate {

constructor(
    private router: Router,
    private authenticationService: AuthService
) { }

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser.bolSecretario ||
      currentUser.bolFuncionario || currentUser.bolDirector|| currentUser.bolAdmin) {
        return true;
    }
}

}
