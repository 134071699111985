import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/shared/localization';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  localization: Localization;
  collapedSideBar: boolean;

  constructor(localization: Localization) {
    this.localization = localization;
  }

  ngOnInit() {

  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

}
