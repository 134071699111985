import { RelatedProcedureRequestDTO, RelatedProcedureResponseDTO } from './RequestTramiteDTO';
import { Mapper } from 'src/app/core/base/Mapper';
import { RequestTramiteRequestModel, RequestTramiteResponseModel } from '../../core/model/RequestTramiteModel';

export class RelatedProcedureMapper implements Mapper<RelatedProcedureResponseDTO, RequestTramiteResponseModel> {
    mapFrom(dto: RelatedProcedureResponseDTO): RequestTramiteResponseModel {
        return {
            result: dto.result,
            requests: dto.response.map( item => {
                return {
                    id: item.id,
                    name: item.name
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
    }

    mapTo(model: RequestTramiteResponseModel): RelatedProcedureResponseDTO {
        return {
            result: model.result,
            response: null
        };
    }
}

export class RelatedProcedureRequestMapper implements Mapper<RelatedProcedureRequestDTO, RequestTramiteRequestModel> {
    mapFrom(dto: RelatedProcedureRequestDTO): RequestTramiteRequestModel {
        return {
            idTown: dto.idMunicipio,
            token: '',
            dataTableParams: null
        };
    }

    mapTo(model: RequestTramiteRequestModel): RelatedProcedureRequestDTO {
        return {
            idMunicipio: model.idTown
        };
    }
}
