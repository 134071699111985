import { Mapper } from 'src/app/core/base/Mapper';
import { RequestHistoryDTO, RequestResponseHistoryDTO } from './HistoryDTO';
import {  RequestHistoryModel,RequestResponseHistoryModel,  } from 'src/app/core/model/HistoryModel';
import { DataTableHelper } from './DataTableHelper';
import { CompileShallowModuleMetadata } from '@angular/compiler';

export class RequestResponseHistoryMapper implements Mapper<RequestResponseHistoryDTO, RequestResponseHistoryModel> {

  mapFrom(dto: RequestResponseHistoryDTO): RequestResponseHistoryModel {
        console.log(dto);
        const model = {
            result: dto.result,
            requests: dto.requests.map( item => {
                return {
                    event: item.event       
                };
            } ),
        };
        return model;
    }

  mapTo(model: RequestResponseHistoryModel): RequestResponseHistoryDTO {
      return {
          result: model.result,
          requests: null,
      };
  }
}

export class RequestHistoryMapper implements Mapper<RequestHistoryDTO, RequestHistoryModel> {
  mapFrom(dto: RequestHistoryDTO): RequestHistoryModel {
      const model = {
          idSeguimiento: dto.idSeguimiento,
          chrFolio : dto.chrFolio
      };
      return model;
  }

  mapTo(model: RequestHistoryModel): RequestHistoryDTO {
      return {
          idSeguimiento: model.idSeguimiento,
          chrFolio: model.chrFolio
      };
  }
}
