import { Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { StartRequestRepository } from '../../core/repositories/StartRequestRepository';
import {
  StartRequestDTO,
  StartRequestResponseDTO,
  ValidateRequestDTO,
  ValidateRequestResponseDTO,
  UpdateRequestResponseDTO,
  DesistirRequestDTO,
  DesecharRequestDTO
} from '../dto/StartRequestDTO';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from '../../auth/service/auth.service';
import { environment } from '../../../environments/environment';
import { TermsRequestDTO, TermsUserDTO, TermsUserResponseDTO } from '../dto/RequestDTO';
import { FetchSeguimientoRequestDTO, FetchSeguimientoResponseDTO, FetchSeguimientoByFolioRequestDTO } from '../dto/StartRequestDTO';

@Injectable({
  providedIn: 'root',
})
export class StartRequestRestRepository extends StartRequestRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    const token = this.auth.currentUserValue.token;
    this.headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
  }

  start(request: StartRequestDTO): Observable<StartRequestResponseDTO> {
    const endpoint = environment.start_request_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<StartRequestResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  send(form: StartRequestDTO): Observable<StartRequestResponseDTO> {
    const endpoint = environment.send_request_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<StartRequestResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(form),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  validate(form: ValidateRequestDTO): Observable<ValidateRequestResponseDTO> {
    const endpoint = environment.validate_document_endpoint;
    const params = new HttpParams({
      fromObject: {
        idSeguimiento: form.idSeguimiento.toString(),
      },
    });
    const httpOptions = { headers: this.headers, params: params };
    return this.http
      .get<ValidateRequestResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  update(request: ValidateRequestDTO): Observable<UpdateRequestResponseDTO> {
    const endpoint = environment.update_request_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<UpdateRequestResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  acceptTerms(request: TermsRequestDTO): Observable<TermsUserResponseDTO> {
    const endpoint = environment.accept_terms_request;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<TermsUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetchSeguimiento(request: FetchSeguimientoRequestDTO): Observable<FetchSeguimientoResponseDTO> {
    const endpoint = environment.get_request_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<FetchSeguimientoResponseDTO>(
        `${environment.url_base}/${endpoint}/${request.idSeguimiento}/${request.cveCatastral}`,
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetchSeguimientoByFolio(request: FetchSeguimientoByFolioRequestDTO): Observable<FetchSeguimientoResponseDTO> {
    const endpoint = environment.create_related_seguimiento;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<FetchSeguimientoResponseDTO>(
        `${environment.url_base}/${endpoint}/${request.folio}}`,
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  cancel(request: StartRequestDTO): Observable<StartRequestResponseDTO> {
    const endpoint = environment.cancel_request_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<StartRequestResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }


  desistir(request: DesistirRequestDTO): Observable<StartRequestResponseDTO> {
    const endpoint = environment.desistir_request_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<StartRequestResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  desechar(request: DesecharRequestDTO): Observable<StartRequestResponseDTO> {
    const endpoint = environment.desechar_request_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<StartRequestResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  sendDesist(form: StartRequestDTO): Observable<StartRequestResponseDTO> {
    const endpoint = environment.send_desist_request_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<StartRequestResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(form),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  sendDiscard(form: StartRequestDTO): Observable<StartRequestResponseDTO> {
    const endpoint = environment.send_discard_request_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<StartRequestResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(form),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
