import { Observable } from 'rxjs';
import { LoginModel,
    CreateUserModel,
    CreateUserResponseModel,
    RecoverUserModel,
    RecoverUserResponseModel
} from '../model/UserModel';
import { UserDTO } from '../../data/dto/UserDTO';
import { UsersListRequestModel, UsersListResponseModel } from '../model/UsersListModel';
import { UserFetchRequestDTO, UserFetchResponseDTO, UserUpdateDTO, UserUpdateResponseDTO } from 'src/app/data/dto/UserUpdateDTO';
import { UserProfileUpdateDTO } from '../../data/dto/UserUpdateDTO';
import { TermsUserDTO, TermsUserResponseDTO } from 'src/app/data/dto/RequestDTO';
import { TramiteListRequestDTO, TramitesResponseDTO } from '../../data/dto/UserTramiteDTO';


export abstract class UserRepository {
    abstract create(user: CreateUserModel): Observable<CreateUserResponseModel>;
    abstract recover(user: RecoverUserModel): Observable<RecoverUserResponseModel>;
    abstract list(): Observable<UserDTO>;
    abstract login(auth: LoginModel): Observable<LoginModel>;
    abstract loginSignature(auth: LoginModel): Observable<any>;
    abstract logout(): Observable<String>;
    abstract fetch(request: UsersListRequestModel): Observable<UsersListResponseModel>;
    abstract fetchUser(user: UserFetchRequestDTO): Observable<UserFetchResponseDTO>;
    abstract update(user: UserUpdateDTO): Observable<UserUpdateResponseDTO>;
    abstract updateProfile(user: UserProfileUpdateDTO): Observable<UserUpdateResponseDTO>;
    abstract acceptTerms(request: TermsUserDTO): Observable<TermsUserResponseDTO>;
    abstract listTramites(request: TramiteListRequestDTO): Observable<TramitesResponseDTO>;
}
