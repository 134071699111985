import { Injectable, ViewChild } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Localization } from 'src/app/shared/localization';
import {ModalCloseSessionComponent} from 'src/app/shared/component/modal-close-session/modal-close-session.component';

import { AuthService } from './../service/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  localization: Localization;
  closeSession: ModalCloseSessionComponent;

  constructor(
    private authenticationService: AuthService,
    localization: Localization,
  ) {
    this.localization = localization;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let error = err.error.message || err.statusText;
      if (err.status === 401 || err.status === 403) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        error = this.localization.app_credentialerror;
        localStorage.setItem('status','close')
        location.reload();
      }


      return throwError(error);
    }))
  }
}
