import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import { OtherChargesRepository } from 'src/app/core/repositories/OtherChargesRepository';
import { ListCentrosIngresosResponseDTO, ListIncomeConceptsResponseDTO,
  AddChargeRequestDTO, AddChargeResponseDTO, GetChargesRequestDTO, GetChargesResponseDTO, DeleteChargeRequestDTO, DeleteChargeResponseDTO} from 'src/app/data/dto/OtherChargesDTO';


@Injectable({
  providedIn: 'root'
})
export class OtherChargesRestRepository implements OtherChargesRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthService) {
    const token = this.authService.currentUserValue.token;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + token
    });
  }

  listCentrosIngresos(): Observable<ListCentrosIngresosResponseDTO> {
    const endpoint = environment.center_income_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListCentrosIngresosResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        catchError(this.handleError)
      )
      .pipe(map(result => {
        return result;
      }));
  }

  listIncomeConcepts(centerId: any): Observable<ListIncomeConceptsResponseDTO> {
    const endpoint = environment.income_concepts_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<ListIncomeConceptsResponseDTO>(`${environment.url_base}/${endpoint}/${centerId}`, httpOptions)
      .pipe(
        catchError(this.handleError)
      )
      .pipe(map(result => {
        return result;
      }));
  }

  addCharge(request: AddChargeRequestDTO): Observable<AddChargeResponseDTO> {
    const endpoint = environment.other_charges_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http.
    post<AddChargeResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
    .pipe(
      catchError(this.handleError))
    .pipe(
      map((result) => {
        return result;
      })
    );
  }

  getChargesRequest(request: GetChargesRequestDTO): Observable<GetChargesResponseDTO> {
    const endpoint = environment.other_charges_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<GetChargesResponseDTO>(`${environment.url_base}/${endpoint}/${request.seguimientoId}`, httpOptions)
      .pipe(
        catchError(this.handleError)
      )
      .pipe(map(result => {
        return result;
      }));
  }

  deleteCharge(request: DeleteChargeRequestDTO): Observable<DeleteChargeResponseDTO> {
    const endpoint = environment.other_charges_endpoint;
    const idSeguimiento = request.seguimientoId;
    const concepto = request.concepto;
    const httpOptions = { headers: this.headers };
    return this.http
    .delete<DeleteChargeResponseDTO>(`${environment.url_base}/${endpoint}/${idSeguimiento}/${concepto}`, httpOptions)
    .pipe(
        catchError(this.handleError)
    )
    .pipe(map(result => {
        return result;
    }));
}

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
