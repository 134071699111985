import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/service/auth.service';
import { MenuRepository } from '../../core/repositories/MenuRepository';
import { ListMenuRequestModel, ListMenuResponseModel } from '../../core/model/MenusModel';
import { MenuResponseMapper, MenuRequestMapper } from '../dto/MenuMapper';
import { FetchUserMenuResponseDTO } from '../dto/MenuDTO';
import { ListMenuRequestDTO,
        UpdateMenuRequestDTO,
        UpdateMenuResponseDTO,
        DeleteMenuResponseDTO,
        ListMenuResponseDTO,
        CreateMenuRequestDTO,
        CreateMenuResponseDTO,
        FetchMenuResponseDTO } from '../dto/MenuDTO';

@Injectable({
    providedIn: 'root'
})
export class MenuRestRepository implements  MenuRepository {
    private headers: HttpHeaders;
    mapper = new MenuResponseMapper();
    menuListMapper = new MenuRequestMapper();

    constructor(private http: HttpClient, private authService: AuthService) {
        const token = this.authService.currentUserValue.token;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        });
    }

  listMenu(menu: ListMenuRequestModel): Observable<ListMenuResponseModel> {
    const endpoint = environment.menu_endpoint;
    const menuListDTO: any = this.menuListMapper.mapTo(menu);
    const params = new HttpParams({
        fromObject: menuListDTO
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListMenuResponseModel>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
      .pipe(map(this.mapper.mapFrom));
  }

  list(menu: ListMenuRequestDTO): Observable<ListMenuResponseDTO> {
    const endpoint = environment.menu_endpoint;
    const params = new HttpParams({
        fromObject: {
          draw: menu.draw.toString(),
          length: menu.length.toString(),
          start: menu.start.toString(),
          search: menu.search
        }
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListMenuResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
      .pipe(
        map((result) => {
        return result;
        })
      );
  }

  create(menu: CreateMenuRequestDTO): Observable<CreateMenuResponseDTO> {
    const endpoint = environment.menu_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<CreateMenuResponseDTO>(
        `${environment.url_base}/${endpoint}`, JSON.stringify(menu), httpOptions)
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetch(menuId: number): Observable<FetchMenuResponseDTO> {
    const endpoint = environment.menu_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<FetchMenuResponseDTO>(`${environment.url_base}/${endpoint}/${menuId}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
      .pipe
        (map(result => {
        return result;
      }));
  }

  update(menu: UpdateMenuRequestDTO): Observable<UpdateMenuResponseDTO> {
    const endpoint = environment.menu_endpoint;
    const httpOptions = { headers: this.headers };
    const request = {
      chrMenu: menu.chrMenu,
      chrPath: menu.chrPath,
      intOrden: menu.intOrden,
      intNivelMenu: menu.intNivelMenu,
      intFKIDMenuPadre: menu.intFKIDMenuPadre
    };
    return this.http
      .put<UpdateMenuResponseDTO>(
        `${environment.url_base}/${endpoint}/${menu.id}`,
        JSON.stringify(request), httpOptions)
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  delete(menuId: number): Observable<DeleteMenuResponseDTO> {
    const endpoint = environment.menu_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .delete<DeleteMenuResponseDTO>(`${environment.url_base}/${endpoint}/${menuId}`, httpOptions)
      .pipe(
        catchError(this.handleError)
      )
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetchUserMenu(): Observable<FetchUserMenuResponseDTO> {
    const endpoint = environment.menu_endpoint;
    const usersListEndpoint = environment.users_list_endpoint
    const httpOptions = { headers: this.headers };
    return this.http
      .get<FetchUserMenuResponseDTO>(`${environment.url_base}/${endpoint}/${usersListEndpoint}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
      .pipe
        (map(result => {
        return result;
      }));
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(error);
  }
}
