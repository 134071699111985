import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgbProgressbarModule, NgbToastModule, NgbModalModule
} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    NgbProgressbarModule,
    NgbToastModule,
    NgbModalModule
  ],
  exports: [
    NgbProgressbarModule,
    NgbToastModule,
    NgbModalModule
  ]
})
export class BootstrapModule { }
