import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './../service/auth.service';

@Injectable({ providedIn: 'root' })
export class CitizenGuard implements CanActivate {
	constructor(
		private router: Router,
		private authenticationService: AuthService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const currentUser = this.authenticationService.currentUserValue;
		if (currentUser.bolCiudadano) {
			return true;
		}

    else if (currentUser.bolInspector) {
      this.router.navigate(['/requests-inspector'], { queryParams: { returnUrl: state.url } });
			return false;
		}

    else if (currentUser.bolSecretario) {
      this.router.navigate(['/request-secretary'], { queryParams: { returnUrl: state.url } });
			return false;
		}

    else if (currentUser.bolDirector) {
      this.router.navigate(['/request-director'], { queryParams: { returnUrl: state.url } });
			return false;
		}

    else {this.router.navigate(['/request-officer'], { queryParams: {
      returnUrl: state.url } });
      return false;
    }
	}
}
