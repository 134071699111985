import { Observable, throwError, BehaviorSubject } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { UserRepository } from '../../core/repositories/UserRepository';
import {
  LoginModel,
  CreateUserModel,
  CreateUserResponseModel,
  RecoverUserModel,
  RecoverUserResponseModel,
} from '../../core/model/UserModel';
import {
  UserDTO,
  CreateUserResponseDTO,
  RecoverUserResponseDTO,
  LoginDTO,
} from '../dto/UserDTO';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from './../../auth/service/auth.service';
import { environment } from '../../../environments/environment';
import {
  UsersListRequestModel,
  UsersListResponseModel,
} from 'src/app/core/model/UsersListModel';
import {
  UsersListRequestMapper,
  UsersListResponseMapper,
} from '../dto/UsersListMapper';
import { UsersListResponseDTO } from '../dto/UsersListDTO';
import {
  UserFetchRequestDTO,
  UserFetchResponseDTO,
  UserUpdateDTO,
  UserUpdateResponseDTO,
  UserProfileUpdateDTO,
} from '../dto/UserUpdateDTO';
import { TermsUserDTO, TermsUserResponseDTO } from '../dto/RequestDTO';
import { TramiteListRequestDTO, TramitesResponseDTO } from '../../data/dto/UserTramiteDTO';

@Injectable({
  providedIn: 'root',
})
export class UserRestRepository extends UserRepository {
  private headers: HttpHeaders;
  mapper = new UsersListResponseMapper();
  usersListMapper = new UsersListRequestMapper();

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  login(auth: LoginModel): Observable<LoginModel> {
    const endpoint = environment.login_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<LoginDTO>(`${environment.url_base}/${endpoint}`, auth, httpOptions)
      .pipe(
        map((user) => {
          return this.auth.login(user);
        })
      );
  }

  loginSignature(request: LoginModel): Observable<any> {
    const endpoint = environment.loginSignature_endpoint;
    const httpOptions = { headers: this.headers};
    return this.http
      .post<LoginDTO>(`${environment.url_base}/${endpoint}`, request, httpOptions)
      .pipe(
        catchError(this.handleError)
      )
      .pipe(map(result => {
        return result;
      }));
  }

  logout(): Observable<String> {
    this.auth.logout();
    const subjet = new BehaviorSubject<String>('login');
    return subjet.asObservable();
  }

  create(user: CreateUserModel): Observable<CreateUserResponseModel> {
    const endpoint = environment.create_user_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<CreateUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(user),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  recover(user: RecoverUserModel): Observable<RecoverUserResponseModel> {
    const endpoint = environment.recover_user_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<RecoverUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(user),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  list(): Observable<UserDTO> {
    throw new Error('Method not implemented.');
  }

  fetch(users: UsersListRequestModel): Observable<UsersListResponseModel> {
    const endpoint = environment.users_list_endpoint;
    const usersDTO: any = this.usersListMapper.mapTo(users);
    const params = new HttpParams({
      fromObject: usersDTO,
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<UsersListResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(map(this.mapper.mapFrom));
  }

  fetchUser(user: UserFetchRequestDTO): Observable<UserFetchResponseDTO> {
    const endpoint = environment.user_fetch_endpoint;
    const params = new HttpParams({
      fromObject: {
        id: user.id.toString(),
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<UserFetchResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  update(user: UserUpdateDTO): Observable<UserUpdateResponseDTO> {
    const endpoint = environment.user_update_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<any>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(user),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  updateProfile(user: UserProfileUpdateDTO): Observable<UserUpdateResponseDTO> {
    const endpoint = environment.user_update_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<any>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(user),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  acceptTerms(request: TermsUserDTO): Observable<TermsUserResponseDTO> {
    const endpoint = environment.accept_terms;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<TermsUserResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  listTramites(user: TramiteListRequestDTO): Observable<TramitesResponseDTO> {
    const endpoint = environment.user_tramites_endpoint;
    const params = new HttpParams({
      fromObject: {
        idUser: user.id.toString(),
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<TramitesResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
