import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginModel } from '../../core/model/UserModel';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private currentUserSubject: BehaviorSubject<LoginModel>;
  public currentUser: Observable<LoginModel>;

  get isLoggedIn() {
    return this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoginModel {
    return this.currentUserSubject.value;
  }

  constructor(private router: Router) {
    this.currentUserSubject = new BehaviorSubject<LoginModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(user) {
    localStorage.setItem('currentUser', JSON.stringify(user.result));
    this.currentUserSubject.next(JSON.parse(JSON.stringify(user.result)));
    return user;
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tittle');
    this.currentUserSubject.next(null);
    setTimeout(function() {
      localStorage.removeItem('logged');
    }, 1000);
  }
}
