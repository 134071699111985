import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import { RolesRequestMapper, RolesResponseMapper } from '../dto/RolesMapper';
import { ZoneRepository } from 'src/app/core/repositories/ZoneRepository';
import { ListZoneResponseDTO } from 'src/app/data/dto/ZoneDTO';
import { FetchZoneByUserRequestDTO, FetchZoneByUserResponseDTO } from '../dto/ZoneDTO';

@Injectable({
    providedIn: 'root'
})
export class ZoneRestRepository implements ZoneRepository {
    private headers: HttpHeaders;
    mapper = new RolesResponseMapper();
    rolesMapper = new RolesRequestMapper();

    constructor(private http: HttpClient, private authService: AuthService) {
        const token = this.authService.currentUserValue.token;
        this.headers = new HttpHeaders({
          'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        });
    }

    list(): Observable<ListZoneResponseDTO> {
      const endpoint = environment.zone_list;
      const httpOptions = { headers: this.headers };
      return this.http
        .get<ListZoneResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        )
        .pipe(map(result => {
          return result;
        }));
    }

    fetchZoneByUser(userId:FetchZoneByUserRequestDTO): Observable<FetchZoneByUserResponseDTO> {
      const endpoint = environment.zone_by_user;
      const params = new HttpParams({
        fromObject: {
          idUser: userId.idUser.toString(),
        }
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
        .get<FetchZoneByUserResponseDTO>(
          `${environment.url_base}/${endpoint}`, httpOptions)
        .pipe(catchError(this.handleError))
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
