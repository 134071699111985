import { Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { AuthService } from '../../auth/service/auth.service';
import { environment } from "src/environments/environment";
import { catchError, map, retry } from 'rxjs/operators';
import { EmailTemplateRequestMapper, EmailTemplateResponseMapper } from '../dto/EmailTemplateMapper';
import { EmailTemplateListRequestModel, EmailTemplateListResponseModel } from '../../core/model/EmailTemplateModel';
import { EmailTemplateListResponseDTO, CreateEmailTemplateResponseDTO, CreateEmailTemplateRequestDTO, FetchEmailTemplateRequestDTO, FetchEmailTemplateResponseDTO, EditEmailTemplateRequestDTO, EditEmailTemplateResponseDTO, DeleteEmailTemplateRequestDTO, DeleteEmailTemplateResponseDTO } from '../dto/EmailTemplateDTO';
import { EmailTemplateRepository } from '../../core/repositories/EmailTemplateRepository';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class EmailTemplateRestRepository implements EmailTemplateRepository {
  private headers: HttpHeaders;
  mapper = new EmailTemplateResponseMapper();
  requestMapper = new EmailTemplateRequestMapper();

  constructor(private http: HttpClient, private authService: AuthService) {
    const token = this.authService.currentUserValue.token;
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
  }

  list(request: EmailTemplateListRequestModel):
  Observable<EmailTemplateListResponseModel> {
    const endpoint = environment.email_list_template_endpoint;
    const requestDTO: any = this.requestMapper.mapTo(request);
    const params = new HttpParams({
      fromObject: requestDTO,
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<EmailTemplateListResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError))
      .pipe(map(this.mapper.mapFrom));
  }

  create(template: CreateEmailTemplateRequestDTO):
  Observable<CreateEmailTemplateResponseDTO> {
    const endpoint = environment.email_create_template_endpoint;
    const httpOptions = { headers: this.headers};
    return this.http
    .post<CreateEmailTemplateResponseDTO>(`${environment.url_base}/${endpoint}`,
    JSON.stringify(template), httpOptions)
    .pipe(
        catchError(this.handleError)
    )
    .pipe(map(result => {
      return result;
    }));
  }

  fetchById(idTemplate: FetchEmailTemplateRequestDTO): Observable<FetchEmailTemplateResponseDTO> {
    const endpoint = environment.email_find_template_endpoint;
    const params = new HttpParams({
      fromObject: { id: idTemplate.id },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<FetchEmailTemplateResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  editTemplate(template: EditEmailTemplateRequestDTO):
  Observable<EditEmailTemplateResponseDTO> {
    const endpoint = environment.email_edit_template_endpoint;
    const httpOptions = { headers: this.headers};
    return this.http
    .put<EditEmailTemplateResponseDTO>(`${environment.url_base}/${endpoint}`,
    JSON.stringify(template), httpOptions)
    .pipe(
        catchError(this.handleError)
    )
    .pipe(map(result => {
      return result;
    }));
  }

  delete(template: DeleteEmailTemplateRequestDTO):
  Observable<DeleteEmailTemplateResponseDTO> {
    const endpoint = environment.email_delete_template_endpoint;
    const params = new HttpParams({
      fromObject: { id: template.id.toString() },
    });
    const httpOptions = { headers: this.headers, params};
    return this.http
    .delete<DeleteEmailTemplateResponseDTO>(`${environment.url_base}/${endpoint}`,
     httpOptions)
    .pipe(
        catchError(this.handleError)
    )
    .pipe(map(result => {
      return result;
    }));
  }

  listTemplates(request: any):
  Observable<any> {
    const endpoint = environment.email_list_template_endpoint;
    const params = new HttpParams({
      fromObject: request,
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<any>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError))
      .pipe(map(result => {
        return result;
      }));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
