import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/shared/localization';
import { AuthService } from 'src/app/auth/service/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-profile-card',
    templateUrl: './profile-card.component.html',
    styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
    localization: Localization;
    authService: AuthService;
    backgroundColor = environment.background_menu;
    constructor(
        authService: AuthService,
        localization: Localization,
        private router: Router,
    ) {
        this.localization = localization;
        this.authService = authService;
    }

    ngOnInit() { }

    onUserProfileClicked() {
    this.router.navigate([`user-profile`]);
    }
}
