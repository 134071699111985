import { RequestsValidateRepository } from 'src/app/core/repositories/RequestsValidateRepository';
import { RequestValidateResponseDTO, RequestValidateDTO } from 'src/app/data/dto/RequestsValidateDTO';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
    providedIn: 'root'
})
export class RequestsValidateRestRepository implements  RequestsValidateRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient, private authService: AuthService) {
        const token = this.authService.currentUserValue.token;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        });
    }


    refuse(request: RequestValidateDTO): Observable<RequestValidateResponseDTO> {
        const endpoint = environment.refuse_request_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .put<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    approve(request: RequestValidateDTO): Observable<RequestValidateResponseDTO> {
        const endpoint = environment.approve_request_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .put<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    firmaPreventiva(request: RequestValidateDTO): Observable<RequestValidateResponseDTO> {
        const endpoint = environment.firma_preventiva_request_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .put<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
