import { Observable } from 'rxjs';
import { DeleteChargeResponseDTO } from '../../data/dto/OtherChargesDTO';
import { AddChargeRequestDTO, AddChargeResponseDTO,
  DeleteChargeRequestDTO,
  GetChargesRequestDTO,
  GetChargesResponseDTO,
  ListCentrosIngresosResponseDTO, ListIncomeConceptsResponseDTO } from 'src/app/data/dto/OtherChargesDTO';
export abstract class OtherChargesRepository {
    abstract listCentrosIngresos(): Observable<ListCentrosIngresosResponseDTO>;
    abstract listIncomeConcepts(centerId: any): Observable<ListIncomeConceptsResponseDTO>;
    abstract addCharge(request: AddChargeRequestDTO ): Observable<AddChargeResponseDTO>;
    abstract getChargesRequest(request: GetChargesRequestDTO ): Observable<GetChargesResponseDTO>;
    abstract deleteCharge(request: DeleteChargeRequestDTO ): Observable<DeleteChargeResponseDTO>;
}
