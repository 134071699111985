import { RequestTypeRepository } from 'src/app/core/repositories/RequestTypeRepository';
import { RequestTypeRequestModel, RequestTypeResponseModel } from 'src/app/core/model/RequestTypeModel';
import {  RequestHistoryModel, RequestResponseHistoryModel } from 'src/app/core/model/HistoryModel';
import { Observable, throwError } from 'rxjs';
import { RequestTypeResponseMapper, RequestTypeRequestMapper } from 'src/app/data/dto/RequestTypeMapper';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { RequestTypeResponseDTO } from 'src/app/data/dto/RequestTypeDTO';
import { RequestResponseHistoryDTO } from 'src/app/data/dto/HistoryDTO';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import {
  RequestListResponseModel, RequestListRequestModel, ListRequestModel,
  ListResponseModel
} from 'src/app/core/model/RequestListModel';
import {
  RequestTramiteResponseModel, RequestTramiteRequestModel} from 'src/app/core/model/RequestTramiteModel';
import { RequestTramiteResponseMapper, RequestTramiteRequestMapper } from 'src/app/data/dto/RequestTramiteMapper';
import { RequestTramiteResponseDTO, RequestTramiteRequestDTO} from 'src/app/data/dto/RequestTramiteDTO';
import { RequestListResponseDTO, RequestListRequestDTO } from 'src/app/data/dto/RequestListDTO';
import { RequestListRequestMapper, RequestListResponseMapper } from 'src/app/data/dto/RequestListMapper';
import { RequestResponseHistoryMapper, RequestHistoryMapper } from 'src/app/data/dto/HistoryMapper';
@Injectable({
  providedIn: 'root'
})
export class RequestTypeRestRepository implements RequestTypeRepository {
  private headers: HttpHeaders;
  mapper = new RequestTypeResponseMapper();
  requestMapper = new RequestTypeRequestMapper();
  mapperList = new RequestListResponseMapper();
  requestsMapper = new RequestListRequestMapper();
  requestsTramiteMapper = new RequestTramiteRequestMapper();
  mapperTramite = new RequestTramiteResponseMapper();
  responseHistory = new RequestResponseHistoryMapper();
  requestHistory = new RequestHistoryMapper();


  constructor(private http: HttpClient, private authService: AuthService) {
    const token = this.authService.currentUserValue.token;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + token
    });
  }

  fetch(request: RequestTypeRequestModel): Observable<RequestTypeResponseModel> {
    const endpoint = environment.request_type_endpoint;
    const requestDTO: any = this.requestMapper.mapTo(request);
    const params = new HttpParams({
      fromObject: requestDTO
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<RequestTypeResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
      .pipe(map(this.mapper.mapFrom));
  }

  historyfetch(request: RequestHistoryModel): Observable<RequestResponseHistoryDTO> {
    const endpoint = environment.history_endpoint;
    const requestDTO: any = this.requestHistory.mapTo(request);
    console.log(requestDTO);
    const params = new HttpParams({
      fromObject: requestDTO
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<RequestResponseHistoryDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
      .pipe(map(result => {
        return result;
    }));
  }

  list(request: RequestListRequestModel): Observable<RequestListResponseModel> {
    const endpoint = environment.request_type_endpoint;
    const requestDTO: any = this.requestsMapper.mapTo(request);
    const params = new HttpParams({
      fromObject: requestDTO
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<RequestListResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
      .pipe(map(this.mapperList.mapFrom));
  }

  listRequests(id: ListRequestModel): Observable<ListResponseModel> {
    const endpoint = environment.request_type_endpoint;
    const params = new HttpParams({
      fromObject: {
        idMunicipio: id.idMunicipio.toString()
      }
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ListResponseModel>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
      .pipe(map(result => {
        return result;
      }));
  }


  // listTramites(request: RequestListRequestDTO): Observable<RequestListResponseDTO> {
  //   const endpoint = environment.request_tramites_endpoint;
  //   const requestDTO: RequestListResponseDTO = this.requestsTramiteMapper.mapTo(request);
  //   const params = new HttpParams({
  //     fromObject: requestDTO
  //   });
  //   const httpOptions = { headers: this.headers, params };
  //   return this.http
  //     .get<any>(`${environment.url_base}/${endpoint}`, httpOptions)
  //     .pipe(
  //       retry(1),
  //       catchError(this.handleError)
  //     )
  //     .pipe(map(this.mapperTramite.mapFrom));
  // }


  listTramites(id: RequestTramiteRequestDTO): Observable<RequestTramiteResponseDTO> {

    const endpoint = environment.request_tramites_endpoint;
    const params = new HttpParams({
      fromObject: {
        idMunicipio: id.idMunicipio.toString()
      }
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<RequestTramiteResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
      .pipe(
        map((result) => {
        return result;
        })
      );
  }


  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
