import { ValidateKeyRepository } from "src/app/core/repositories/ValidateKeyRepository";
import { ValidateKeyRequestDTO, ValidateKeyRelatedRequestDTO } from "src/app/data/dto/ValidateKeyDTO";
import { Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { ValidateKeyResponseDTO } from "src/app/data/dto/ValidateKeyDTO";
import { environment } from "src/environments/environment";
import { retry, catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthService } from "src/app/auth/service/auth.service";

@Injectable({
  providedIn: "root",
})
export class ValidateKeyRestRepository implements ValidateKeyRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthService) {
    const token = this.authService.currentUserValue.token;
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer" + token,
    });
  }

  fetch(validate: ValidateKeyRequestDTO): Observable<ValidateKeyResponseDTO> {
    const endpoint = environment.validate_key_endpoint;
    const params = new HttpParams({
      fromObject: {
        cveCatastral: validate.cveCatastral,
        folio: validate.folio,
        idTipoTramite: validate.idTipoTramite,
        intFKIDExpediente: validate.expediente,
        folioOrigen:validate.folioOrigen
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<ValidateKeyResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }


  fetchRelated(request: ValidateKeyRelatedRequestDTO): Observable<ValidateKeyResponseDTO> {
    const endpoint = environment.create_related_seguimiento;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<ValidateKeyResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }


  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
