import { Mapper } from 'src/app/core/base/Mapper';
import { DataTableHelper } from './DataTableHelper';
import { EmailTemplateListResponseDTO, EmailTemplateListRequestDTO } from './EmailTemplateDTO';
import { EmailTemplateListResponseModel, EmailTemplateListRequestModel } from '../../core/model/EmailTemplateModel';

export class EmailTemplateResponseMapper implements Mapper<
EmailTemplateListResponseDTO, EmailTemplateListResponseModel
> {

  mapFrom(dto: EmailTemplateListResponseDTO): EmailTemplateListResponseModel {
        const model = {
            result: dto.result,
            response: dto.response.map( item => {
                return {
                  id: item.id,
                  txtName: item.txtName,
                  codigoTemplate: item.codigoTemplate,
                  txtContentHTML: item.txtContentHTML,
                  txtContent: item.txtContent,
                  intFKIDMunicipio: item.intFKIDMunicipio,
                  createdAt: item.createdAt,
                  updatedAt: item.updatedAt
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: EmailTemplateListResponseModel): EmailTemplateListResponseDTO {
        return {
            result: model.result,
            response: null
        };
    }
}

export class EmailTemplateRequestMapper implements Mapper<EmailTemplateListRequestDTO, EmailTemplateListRequestModel> {
    mapFrom(dto: EmailTemplateListRequestDTO): EmailTemplateListRequestModel {
        const model: EmailTemplateListRequestModel = {
          idTown: dto.idMunicipio,
          token: dto.token,
          dataTableParams: null
        };
        return model;
    }

    mapTo(model: EmailTemplateListRequestModel): EmailTemplateListRequestDTO {
        return {
            idMunicipio: model.idTown,
            token: '',
            draw: model.dataTableParams.draw,
            length: model.dataTableParams.length,
            start: model.dataTableParams.start,
            search: model.dataTableParams.search.value,
            order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}
