import { Observable } from 'rxjs';
import {
  FileDTO,
  FileResponseDTO,
  FileReutilizaResponseDTO,
  DownloadDTO,
  FileReutilizaDTO
} from '../../data/dto/UploadFileDTO';
import {
  UploadFileDTO,
  UploadFileResponseDTO,
} from '../../data/dto/TaxDataDTO';
import { DownloadDictamenDTO, DictamenPDFDTO } from '../../data/dto/DownloadDictamen';
import { FileInspectorDTO, FileInspectorResponseDTO } from '../../data/dto/UploadFileDTO';

export abstract class UploadFileRepository {
  abstract uploadDocR(file: FileReutilizaDTO): Observable<FileReutilizaResponseDTO>;
  abstract upload(file: FileDTO): Observable<FileResponseDTO>;
  abstract download(file: DownloadDTO): Observable<any>;
  abstract downloadAcuse(file: DownloadDTO): Observable<any>;
  abstract uploadKeyFile(
    file: UploadFileDTO
  ): Observable<UploadFileResponseDTO>;
  abstract downloadDictamen(file: DownloadDictamenDTO): Observable<DictamenPDFDTO>;
  abstract uploadInspector(file: FileInspectorDTO): Observable<FileInspectorResponseDTO>;
}
