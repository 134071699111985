import { RolesListRepository } from 'src/app/core/repositories/RolesListRepository';
import { RolesResponseDTO } from 'src/app/data/dto/RolesDTO';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import { CreateRoleRequestDTO,
        CreateRoleResponseDTO,
        DeleteRoleResponseDTO,
        FetchRoleResponseDTO,
        UpdateRoleRequestDTO,
        UpdateRoleResponseDTO } from '../dto/RolesDTO';
import { RolesRequestMapper, RolesResponseMapper } from '../dto/RolesMapper';
import { RolesRequestModel, RolesResponseModel } from 'src/app/core/model/RoleModel';

@Injectable({
    providedIn: 'root'
})
export class RolesListRestRepository implements RolesListRepository {
    private headers: HttpHeaders;
    mapper = new RolesResponseMapper();
    rolesMapper = new RolesRequestMapper();

    constructor(private http: HttpClient, private authService: AuthService) {
        const token = this.authService.currentUserValue.token;
        this.headers = new HttpHeaders({
          'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        });
    }

    fetch(): Observable<RolesResponseDTO> {
        const endpoint = environment.roles_list_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http
            .get<any>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
              retry(1),
              catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    listRoles(roles: RolesRequestModel): Observable<RolesResponseModel> {
      const endpoint = environment.role_endpoint;
      const rolesDTO: any = this.rolesMapper.mapTo(roles);
      const params = new HttpParams({
          fromObject: rolesDTO
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
          .get<RolesResponseModel>(`${environment.url_base}/${endpoint}`, httpOptions)
          .pipe(
            retry(1),
            catchError(this.handleError)
          )
          .pipe(map(this.mapper.mapFrom));
    }

    create(role: CreateRoleRequestDTO): Observable<CreateRoleResponseDTO> {
      const endpoint = environment.role_endpoint;
      const httpOptions = { headers: this.headers };
      return this.http
        .post<CreateRoleResponseDTO>(
          `${environment.url_base}/${endpoint}`, JSON.stringify(role), httpOptions)
        .pipe(catchError(this.handleError))
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    delete(roleId: number): Observable<DeleteRoleResponseDTO> {
      const endpoint = environment.role_endpoint;
      const httpOptions = { headers: this.headers };
      return this.http
        .delete<DeleteRoleResponseDTO>(`${environment.url_base}/${endpoint}/${roleId}`, httpOptions)
        .pipe(
              catchError(this.handleError)
        )
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    fetchRole(roleId: number): Observable<FetchRoleResponseDTO> {
      const endpoint = environment.role_endpoint;
      const httpOptions = { headers: this.headers };
      return this.http
        .get<FetchRoleResponseDTO>(`${environment.url_base}/${endpoint}/${roleId}`, httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        )
        .pipe(map(result => {
          return result;
        }));
    }

    update(role: UpdateRoleRequestDTO): Observable<UpdateRoleResponseDTO> {
      const endpoint = environment.role_endpoint;
      const httpOptions = { headers: this.headers };
      const request = {
        chrRol: role.chrRol,
        bolFiltraUsuario: role.bolFiltraUsuario,
        menus: role.menus
      };
      return this.http
        .put<UpdateRoleResponseDTO>(
          `${environment.url_base}/${endpoint}/${role.id}`,
          JSON.stringify(request), httpOptions
        )
        .pipe(catchError(this.handleError))
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
