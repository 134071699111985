import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './auth/interceptor';
import { AppComponent } from './app.component';
import { UserRestRepository } from './data/repository/UserRestRepository';
import { UserRepository } from './core/repositories/UserRepository';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserMapper, UserCreateResponseMapper, UserRecoveryResponseMapper } from './data/dto/UserMapper';
import { SharedModule } from './shared/shared.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RequestTypeRepository } from './core/repositories/RequestTypeRepository';
import { RequestTypeRestRepository } from './data/repository/RequestTypeRestRepository';
import { RequestRepository } from './core/repositories/RequestRepository';
import { RequestRestRepository } from './data/repository/RequestRestRepository';
import { MessageRepository } from './core/repositories/MessageRepository';
import { MessageRestRepository } from './data/repository/MessageRestRepository';
import { LicenseTypeRepository } from './core/repositories/LicenseTypeRepository';
import { LicenseTypeRestRepository } from './data/repository/LicenseTypeRestRepository';
import { LicenseRepository } from './core/repositories/LicenseRepository';
import { LicenseRestRepository } from './data/repository/LicenseRestRepository';
import { ValidateKeyRepository } from './core/repositories/ValidateKeyRepository';
import { ValidateKeyRestRepository } from './data/repository/ValidateKeyRestRepository';
import { UploadFileRepository } from './core/repositories/UploadFileRepository';
import { UploadFileRestRepository } from './data/repository/UploadFileRestRepository';
import { RequirementPdfRepository } from './core/repositories/RequirementPdfRepository';
import { RequirementPdfRestRepository } from './data/repository/RequirementPdfRestRepository';
import { PersonalDataRepository } from './core/repositories/PersonalDataRepository';
import { PersonalDataRestRepository } from './data/repository/PersonalDataRepository';
import { StartRequestRepository } from './core/repositories/StartRequestRepository';
import { StartRequestRestRepository } from './data/repository/StartRequestRestUseCase';
import { DocumentRepository } from './core/repositories/DocumentRepository';
import { DocumentRestRepository } from './data/repository/DocumentRestRepository';
import { RequestsValidateRepository } from './core/repositories/RequestsValidateRepository';
import { RequestsValidateRestRepository } from './data/repository/RequestsValidateRestRepository';
import { MessageViewRepository } from './core/repositories/MessageViewRepository';
import { MessageViewRestRepository } from './data/repository/MessageViewRestRepository';
import { FieldRepository } from './core/repositories/FieldRepository';
import { FieldRestRepository } from './data/repository/FieldRestRepository';
import { RolesListRepository } from './core/repositories/RolesListRepository';
import { RolesListRestRepository } from './data/repository/RolesListRestRepository';
import { TaxDataRepository } from './core/repositories/TaxDataRepository';
import { TaxDataRestRepository } from './data/repository/TaxDataRestRepository';
import { SpanishLocalization, Localization } from './shared/localization';
import { BootstrapModule } from './bootstrap/bootstrap.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';
import localeEs from '@angular/common/locales/es';
import {registerLocaleData} from '@angular/common';
import { StatusRepository } from './core/repositories/StatusRepository';
import { StatusRestRepository } from './data/repository/StatusRestRepository';
import { MenuRepository } from './core/repositories/MenuRepository';
import { MenuRestRepository } from './data/repository/MenuRestRepository';
import { OtherChargesRepository } from './core/repositories/OtherChargesRepository';
import { OtherChargesRestRepository } from './data/repository/OtherChargesRestRepository';
import { ZoneRepository } from './core/repositories/ZoneRepository';
import { ZoneRestRepository } from './data/repository/ZoneRestRepository';
import { EmailTemplateRepository } from './core/repositories/EmailTemplateRepository';
import { EmailTemplateRestRepository } from './data/repository/EmailTemplateRestRepository';
import { ReportsRepository } from './core/repositories/ReportsRepository';
import { ReportsRestRepository } from './data/repository/ReportsRestRepository';
import { PaymentMethodsRestRepository } from './data/repository/PaymentMethodsRestRepository';
import { PaymentMethodsRepository } from './core/repositories/PaymentMethodsRepository';
import { MunicipiosRepository } from './core/repositories/MunicipioRepository';
import { MunicipiosRestRepository } from './data/repository/MunicipiosRestRepository';
import { RelatedProcedureRepository } from './core/repositories/RelatedProcedureRepository';
import { RelatedProcedureRestRepository } from './data/repository/RelatedProcedureRestRepository';


registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BootstrapModule,
    NgbModalModule,
    DataTablesModule,
    FormsModule
  ],
  providers: [
    Title,
    UserMapper,
    UserCreateResponseMapper,
    UserRecoveryResponseMapper,
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: UserRepository, useClass: UserRestRepository},
    { provide: RequestTypeRepository, useClass: RequestTypeRestRepository },
    { provide: RequestRepository, useClass: RequestRestRepository },
    { provide: LicenseRepository, useClass: LicenseRestRepository },
    { provide: LicenseTypeRepository, useClass: LicenseTypeRestRepository },
    { provide: MessageRepository, useClass: MessageRestRepository },
    { provide: ValidateKeyRepository, useClass: ValidateKeyRestRepository },
    { provide: Localization, useClass: SpanishLocalization },
    { provide: UploadFileRepository, useClass: UploadFileRestRepository },
    { provide: PersonalDataRepository, useClass: PersonalDataRestRepository },
    { provide: RequirementPdfRepository , useClass: RequirementPdfRestRepository },
    { provide: StartRequestRepository, useClass: StartRequestRestRepository },
    { provide: DocumentRepository, useClass: DocumentRestRepository },
    { provide: RequestsValidateRepository, useClass: RequestsValidateRestRepository },
    { provide: MessageViewRepository, useClass: MessageViewRestRepository },
    { provide: FieldRepository, useClass: FieldRestRepository },
    { provide: RolesListRepository, useClass: RolesListRestRepository },
    { provide: TaxDataRepository, useClass: TaxDataRestRepository},
    { provide: StatusRepository, useClass: StatusRestRepository },
    { provide: MenuRepository, useClass: MenuRestRepository },
    { provide: OtherChargesRepository, useClass: OtherChargesRestRepository },
    { provide: ZoneRepository, useClass: ZoneRestRepository },
    { provide: EmailTemplateRepository, useClass: EmailTemplateRestRepository },
    { provide: ReportsRepository, useClass: ReportsRestRepository },
    { provide: PaymentMethodsRepository, useClass: PaymentMethodsRestRepository},
    {provide: MunicipiosRepository, useClass: MunicipiosRestRepository},
    {provide: RelatedProcedureRepository, useClass: RelatedProcedureRestRepository}

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
