import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from './../../auth/service/auth.service'
import { environment } from '../../../environments/environment';
import { ReportsRepository } from 'src/app/core/repositories/ReportsRepository';
import { RequestReportsRequestDTO, RequestByTypeProcedureResponseDTO } from '../dto/RequestReportsDTO';

@Injectable({
providedIn: 'root'
})
export class ReportsRestRepository implements ReportsRepository {
    private headers: HttpHeaders;

    constructor( private http: HttpClient, private auth: AuthService) {
        let token = this.auth.currentUserValue.token
        this.headers = new HttpHeaders({
            'Authorization': "Bearer "+ token,
            'Content-Type': "application/json"
        });
    }

    listByTypeProcedure(request: RequestReportsRequestDTO): Observable<RequestByTypeProcedureResponseDTO> {
      let buildObject = {};
      let requestFinal = {};
      let endpoint = environment.request_report_endpoint;
        Object.keys(request).forEach ((key) => {
          let value = request[key];
          buildObject = { [key] : value }
          Object.assign(requestFinal, buildObject);
        });
        const params = new HttpParams({
            fromObject: requestFinal
        });
        let httpOptions = { headers: this.headers, params };
        return this.http
        .get<RequestByTypeProcedureResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    };
}
