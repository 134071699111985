import { Mapper } from 'src/app/core/base/Mapper';
import { MessageResponseDTO, MessageRequestDTO } from './MessageDTO';
import { MessageResponseModel, MessageRequestModel } from 'src/app/core/model/MessageModel';
import { DataTableHelper } from './DataTableHelper';

export class MessageResponseMapper implements Mapper<MessageResponseDTO, MessageResponseModel> {
    mapFrom(dto: MessageResponseDTO): MessageResponseModel {
        let model = {
            result: dto.result,
            messages: dto.mensajes.map( item => {
                return {
                    id: item.id,
                    chrSubject: item.chrTitulo,
                    chrMessage: item.chrMensaje,
                    dtdTimeStamp: item.dtdTimeStamp,
                    chrType: item.chrTipo,
                    chrNotification: item.chrNotificacion,
                    bolVisto: item.bolVisto,
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: MessageResponseModel): MessageResponseDTO {
        return {
            result: model.result,
            mensajes: null
        };
    }
}

export class MessageRequestMapper implements Mapper<MessageRequestDTO, MessageRequestModel> {
    mapFrom(dto: MessageRequestDTO): MessageRequestModel {
        let model: MessageRequestModel = {
            idTown: dto.idMunicipio,
            token: "",
            dataTableParams: null
        };
        return model;
    }

    mapTo(model: MessageRequestModel): MessageRequestDTO {
        return {
            idMunicipio: model.idTown,
            token: "",
            draw: model.dataTableParams.draw,
            length: model.dataTableParams.length,
            start: model.dataTableParams.start,
            search: model.dataTableParams.search.value,
            order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}
