import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class InspectorGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (
      currentUser.bolInspector ||
      currentUser.bolAdmin
    ) {
      return true;
    }

    this.router.navigate(['/requests-inspector'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
