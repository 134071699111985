import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, map } from 'rxjs/operators';
import { AuthService } from './../../auth/service/auth.service'
import { environment } from '../../../environments/environment';
import { RelatedProcedureRepository } from 'src/app/core/repositories/RelatedProcedureRepository';
import { RelatedProceduresDTO } from 'src/app/data/dto/RequestListDTO';
import { RequestListRequestModel } from 'src/app/core/model/RequestListModel';
import { RelatedProcedureMapper, RelatedProcedureRequestMapper } from '../dto/RelatedProcedureMapper';

@Injectable({
providedIn: 'root'
})
export class RelatedProcedureRestRepository implements RelatedProcedureRepository {
    private headers: HttpHeaders;
    mapper = new RelatedProcedureMapper();
    procedureMapper = new RelatedProcedureRequestMapper();

    constructor( private http: HttpClient, private auth: AuthService) {
        let token = this.auth.currentUserValue.token
        this.headers = new HttpHeaders({
            'Authorization': "Bearer "+ token,
            'Content-Type': "application/json"
        });
    }

    create(request: RelatedProceduresDTO): Observable<any> {
      const endpoint = environment.related_procedure_endpoint;
      const httpOptions = { headers: this.headers };
      return this.http
        .post<any>(
          `${environment.url_base}/${endpoint}`,
          JSON.stringify(request),
          httpOptions
        )
        .pipe(catchError(this.handleError))
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    list(request: RequestListRequestModel): Observable<any> {
      const endpoint = environment.list_related_procedure_endpoint;
      const requestDTO: any = this.procedureMapper.mapTo(request);
      const params = new HttpParams({
          fromObject: requestDTO
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
          .get<any>(`${environment.url_base}/${endpoint}`, httpOptions)
          .pipe(
            retry(1),
            catchError(this.handleError)
          )
          .pipe(map(this.mapper.mapFrom));
    }

    fetch(request: any): Observable<any> {
      const endpoint = environment.fetch_related_procedure_endpoint;
      const params = new HttpParams({
        fromObject: request
    });
      const httpOptions = { headers: this.headers, params };
      return this.http
        .get<any>(
          `${environment.url_base}/${endpoint}`,
          httpOptions
        )
        .pipe(retry(1), catchError(this.handleError))
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    update(request: any): Observable<any> {
      const endpoint = environment.fetch_related_procedure_endpoint;
      const httpOptions = { headers: this.headers };
      return this.http
        .put<any>(
          `${environment.url_base}/${endpoint}`,
          JSON.stringify(request),
          httpOptions
        )
        .pipe(catchError(this.handleError))
        .pipe(
          map((result) => {
            return result;
          })
        );
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

}
