import { Observable } from 'rxjs';
import {
  TermsRequestDTO,
  TermsUserResponseDTO,
} from 'src/app/data/dto/RequestDTO';
import { FetchSeguimientoRequestDTO, FetchSeguimientoResponseDTO, FetchSeguimientoByFolioRequestDTO } from '../../data/dto/StartRequestDTO';
import {
  StartRequestDTO,
  StartRequestResponseDTO,
  ValidateRequestDTO,
  ValidateRequestResponseDTO,
  UpdateRequestResponseDTO,
  DesistirRequestDTO,
  DesecharRequestDTO
} from '../../data/dto/StartRequestDTO';

export abstract class StartRequestRepository {
  abstract start(request: StartRequestDTO): Observable<StartRequestResponseDTO>;
  abstract validate(
    form: ValidateRequestDTO
  ): Observable<ValidateRequestResponseDTO>;
  abstract send(form: StartRequestDTO): Observable<StartRequestResponseDTO>;
  abstract update(
    request: ValidateRequestDTO
  ): Observable<UpdateRequestResponseDTO>;
  abstract acceptTerms(request: TermsRequestDTO): Observable<TermsUserResponseDTO>;
  abstract fetchSeguimiento(request: FetchSeguimientoRequestDTO): Observable<FetchSeguimientoResponseDTO>;
  abstract fetchSeguimientoByFolio(request: FetchSeguimientoByFolioRequestDTO): Observable<FetchSeguimientoResponseDTO>;
  abstract cancel(request: StartRequestDTO): Observable<StartRequestResponseDTO>;
  abstract desistir(request: DesistirRequestDTO): Observable<StartRequestResponseDTO>;
  abstract sendDesist(request: StartRequestDTO): Observable<StartRequestResponseDTO>;
  abstract desechar(request: DesecharRequestDTO): Observable<StartRequestResponseDTO>;
  abstract sendDiscard(request: StartRequestDTO): Observable<StartRequestResponseDTO>;
}
