import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { RequirementPdfDTO, AcusePdfDTO, AcuseVisualPdfDTO, AcusePdfHistoryDTO } from '../dto/UploadFileDTO';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RequirementPdfRepository } from '../../core/repositories/RequirementPdfRepository';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
providedIn: 'root'
})
export class RequirementPdfRestRepository extends RequirementPdfRepository {
private headers: HttpHeaders;

    constructor(private http: HttpClient, private auth: AuthService) {
        super();
        const token = this.auth.currentUserValue.token;
        this.headers = new HttpHeaders({
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
    }

    requirementsPdf(file: RequirementPdfDTO): Observable<any> {
        const endpoint = environment.requirement_pdf_endpoint;
        const httpOptions = {
            headers: this.headers,
            responseType: 'blob' as 'json'
        };
        return this.http.post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(file), httpOptions)
        .pipe(
            retry(0),
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }

    acusePdf(file: AcusePdfDTO): Observable<any> {
        const endpoint = environment.pdf_acuse_endpoint;
        const httpOptions = {
            headers: this.headers,
            responseType: 'blob' as 'json'
        };
        return this.http.post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(file), httpOptions)
        .pipe(
            catchError(this.handleError),
            map(result => {
                return result;
            }),
        );
    }

    historialPdf(file: AcusePdfHistoryDTO): Observable<any> {
        const endpoint = environment.pdf_history_endpoint;
        const httpOptions = {
            headers: this.headers,
            responseType: 'blob' as 'json'
        };
        return this.http.post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(file), httpOptions)
        .pipe(
            catchError(this.handleError),
            map(result => {
                return result;
            }),
        );
    }

    acuseDesistPdf(file: AcusePdfDTO): Observable<any> {
      const endpoint = environment.pdf_acuse_desist_endpoint;
      const httpOptions = {
          headers: this.headers,
          responseType: 'blob' as 'json'
      };
      return this.http.post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(file), httpOptions)
      .pipe(
          catchError(this.handleError),
          map(result => {
              return result;
          }),
      );
    }

    acuseDiscardPdf(file: AcusePdfDTO): Observable<any> {
      const endpoint = environment.pdf_acuse_endpoint;
      const httpOptions = {
          headers: this.headers,
          responseType: 'blob' as 'json'
      };
      return this.http.post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(file), httpOptions)
      .pipe(
          catchError(this.handleError),
          map(result => {
              return result;
          }),
      );
    }

    acuseVisualPdf(file: AcuseVisualPdfDTO): Observable<any> {
        const endpoint = environment.preventiva_vista_endpoint;
        const httpOptions = {
            headers: this.headers,
            responseType: 'blob' as 'json'
        };
        return this.http.post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(file), httpOptions)
        .pipe(
            catchError(this.handleError),
            map(result => {
                return result;
            }),
        );
    }

    acuseNoVisualPdf(file: AcuseVisualPdfDTO): Observable<any> {
        const endpoint = environment.preventiva_no_vista_endpoint;
        const httpOptions = {
            headers: this.headers,
            responseType: 'blob' as 'json'
        };
        return this.http.post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(file), httpOptions)
        .pipe(
            catchError(this.handleError),
            map(result => {
                return result;
            }),
        );
    }

    handleError(error: HttpErrorResponse, caught: any) {
        console.log(error);
        return throwError(error);
    }
}
