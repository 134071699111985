import { Observable } from 'rxjs';
import { 
    ValidateKeyRequestDTO,
    ValidateKeyResponseDTO,
    ValidateKeyRelatedRequestDTO
} from '../../data/dto/ValidateKeyDTO';

export abstract class ValidateKeyRepository {
    abstract fetch(validate: ValidateKeyRequestDTO): Observable<ValidateKeyResponseDTO>;
    abstract fetchRelated(validate: ValidateKeyRelatedRequestDTO): Observable<ValidateKeyResponseDTO>;
}