import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { retry, catchError, map } from 'rxjs/operators';
import { AuthService } from '../../auth/service/auth.service';
import { TaxDataRepository } from '../../core/repositories/TaxDataRepository';
import { UploadFileDTO, UploadFileResponseDTO } from '../dto/TaxDataDTO';
import { environment } from '../../../environments/environment';
import { TaxDataListRequestDTO, TaxDataListResponseDTO } from '../dto/TaxDataListDTO';
import { DataKeyRequestDTO, DataKeyResponseDTO} from '../dto/DataKeyDTO';

@Injectable({
  providedIn: 'root'
})
export class TaxDataRestRepository implements  TaxDataRepository  {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthService) {
      const token = this.authService.currentUserValue.token;
      this.headers = new HttpHeaders({
          'Content-Type': 'application/json',
           Authorization: 'Bearer ' + token
      });
  }

  createDataKey(request: DataKeyRequestDTO): Observable<DataKeyResponseDTO> {
    const endpoint = environment.create_tax_data_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<DataKeyResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
      .pipe(
        catchError(this.handleError)
      )
      .pipe(map(result => {
        return result;
      }));
  }

  fetchUser(user: TaxDataListRequestDTO): Observable<TaxDataListResponseDTO> {
    const endpoint = environment.tax_data_list_endpoint;
    const params = new HttpParams ({
      fromObject: {
        intFKIDUsuario: user.intFKIDUsuario.toString(),
      }
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
    .get<TaxDataListResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
    .pipe(
        catchError(this.handleError)
    )
    .pipe(map(result => {
      return result;
    }));
  }

  handleError(error: HttpErrorResponse) {
      return throwError(error);
  }
}
