import { Observable, throwError } from 'rxjs';
import {
  HttpParams,
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { FileDTO, FileReutilizaDTO, FileResponseDTO, FileReutilizaResponseDTO,DownloadDTO, FileInspectorDTO, FileInspectorResponseDTO } from '../dto/UploadFileDTO';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UploadFileRepository } from '../../core/repositories/UploadFileRepository';
import { AuthService } from 'src/app/auth/service/auth.service';
import { UploadFileDTO, UploadFileResponseDTO } from '../dto/TaxDataDTO';
import { DictamenPDFDTO, DownloadDictamenDTO } from '../dto/DownloadDictamen';

@Injectable({
  providedIn: 'root',
})
export class UploadFileRestRepository extends UploadFileRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
    const token = this.auth.currentUserValue.token;
    this.headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
    });
  }

  upload(file: FileDTO): Observable<FileResponseDTO> {

    const endpoint = environment.upload_files_endpoint;
    const httpOptions = { headers: this.headers };
    const formData = new FormData();
    formData.append('fileToUpload', file.fileToUpload);
    formData.append('idSeguimiento', file.idSeguimiento.toString());
    formData.append('idDocumento', file.idDocumento.toString());
    formData.append('idTramite', file.idTramite.toString());
    formData.append('observacion', file.observacion);
    return this.http
      .post<FileResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        formData,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadDocR(document: FileReutilizaDTO): Observable<FileReutilizaResponseDTO> {
    const endpoint = environment.document_uploadDocR_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<FileReutilizaResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        document,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  download(file: DownloadDTO): Observable<any> {
    const endpoint = environment.download_file_endpoint;
    const idFile = file.idDocumento;
    const httpOptions = {
      headers: this.headers,
      responseType: 'blob' as 'json',
    };
    return this.http
      .get<any>(`${environment.url_base}/${endpoint}/${idFile}`, httpOptions)
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  downloadAcuse(file: DownloadDTO): Observable<any> {
    const endpoint = environment.download_acuse_endpoint;
    const idFile = file.idDocumento;
    const httpOptions = {
      headers: this.headers,
      responseType: 'blob' as 'json',
    };
    return this.http
      .get<any>(`${environment.url_base}/${endpoint}/${idFile}`, httpOptions)
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadKeyFile(file: UploadFileDTO): Observable<UploadFileResponseDTO> {
    const endpoint = environment.upload_files_tax_data_endpoint;
    const httpOptions = { headers: this.headers };
    const formData = new FormData();
    formData.append('fileToUpload', file.fileToUpload);
    return this.http
      .post<UploadFileResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        formData,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  downloadDictamen(file: DownloadDictamenDTO): Observable<DictamenPDFDTO> {
    const endpoint = environment.download_dictamen_endpoint;
    const idFile = file.idSeguimiento;
    const httpOptions = { headers: this.headers };

    return this.http
      .post<DictamenPDFDTO>(
        `${environment.url_base}/${endpoint}/${idFile}`,
        file,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  uploadInspector(
    file: FileInspectorDTO
  ): Observable<FileInspectorResponseDTO> {
    const endpoint = environment.upload_inspector_endpoint;
    const httpOptions = { headers: this.headers };
    const formData = new FormData();
    formData.append('fileToUpload', file.fileToUpload);
    formData.append('seguimientoId', file.seguimientoId.toString());
    formData.append('bolPreventiva', file.bolPreventiva.toString());
    return this.http
      .post<FileInspectorResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        formData,
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
