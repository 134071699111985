import { Observable } from 'rxjs';
import { FieldListRequestDTO, FieldListResponseDTO, FieldSaveRequestDTO, FieldSaveResponseDTO } from '../../data/dto/FieldsListDTO';
import { FieldsCatalogueRequestModel, FieldsCatalogueResponseModel} from '../model/FieldsCatalogueModel';
import { FieldCreateDTO, FieldCreateResponseDTO } from '../../data/dto/FieldCreateDTO';
import { FieldUpdateDTO, FieldUpdateResponseDTO, FieldFetchRequestDTO, FieldFetchResponseDTO } from '../../data/dto/FieldUpdateDTO';
import { FieldDeleteDTO, FieldDeleteResponseDTO } from '../../data/dto/FieldDeleteDTO';
import { FieldsFetchListRequestDTO, FieldsFetchListResponseDTO } from '../../data/dto/FieldFetchListDTO';
import { MatrixFieldCreateDTO, MatrixFieldCreateResponseDTO } from '../../data/dto/MatrixFieldCreateDTO';
import { MatrixFieldDeleteDTO, MatrixFieldDeleteResponseDTO } from '../../data/dto/MatrixFieldDeleteDTO';
import { MatrixFieldEditDTO, MatrixFieldEditResponseDTO, ProfileListResponseDTO } from '../../data/dto/MatrixFieldEditDTO';
import { FetchMatrixFieldDTO, FetchMatrixFieldResponseDTO } from '../../data/dto/MatrixFieldEditDTO';
import { RequestFetchRequestDTO, RequestFetchResponseDTO } from '../../data/dto/RequestFetchDTO';

export abstract class FieldRepository {
    abstract list(fields: FieldListRequestDTO): Observable<FieldListResponseDTO>;
    abstract save(field: FieldSaveRequestDTO): Observable<FieldSaveResponseDTO>;
    abstract catalogue(field: FieldsCatalogueRequestModel): Observable<FieldsCatalogueResponseModel>;
    abstract create(field: FieldCreateDTO): Observable<FieldCreateResponseDTO>;
    abstract update(field: FieldUpdateDTO): Observable<FieldUpdateResponseDTO>;
    abstract fetch(field: FieldFetchRequestDTO): Observable<FieldFetchResponseDTO>;
    abstract delete(field: FieldDeleteDTO): Observable<FieldDeleteResponseDTO>;
    abstract fieldsList(field: FieldsFetchListRequestDTO): Observable<FieldsFetchListResponseDTO>;
    abstract createMatrix(field: MatrixFieldCreateDTO): Observable<MatrixFieldCreateResponseDTO>;
    abstract deleteMatrix(field: MatrixFieldDeleteDTO): Observable< MatrixFieldDeleteResponseDTO>;
    abstract editMatrix(field: MatrixFieldEditDTO): Observable<MatrixFieldEditResponseDTO>;
    abstract fetchMatrixField(field: FetchMatrixFieldDTO): Observable<FetchMatrixFieldResponseDTO>;
    abstract listFields(field: RequestFetchRequestDTO): Observable<RequestFetchResponseDTO>;
    abstract getProfile(): Observable<ProfileListResponseDTO>;
}
