import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LogOutUseCase } from './core/usecases/auth/LogOutUseCase';
import { AuthService } from './auth/service/auth.service';
import { UserModel, LoginModel } from './core/model/UserModel';
import { Title } from '@angular/platform-browser';
import { Localization } from './shared/localization';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'san-pedro';
  currentUser: LoginModel;

  localization: Localization;

  constructor(
    private router: Router,
    private logOutUseCase: LogOutUseCase,
    private service: AuthService,
    private titleMain: Title,
    localization: Localization
  ) {
    this.localization = localization;
    titleMain.setTitle(environment.app_title);
    this.service.currentUser.subscribe(x => this.currentUser = x);

  }

  logout() {
    this.logOutUseCase.execute().subscribe( url => {
      this.router.navigate(['/login']);
    });
  }

}
