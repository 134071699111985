import { Mapper } from 'src/app/core/base/Mapper';
import { RoleListResponseDTO, RoleListRequestDTO } from './RolesDTO';
import { RolesRequestModel, RolesResponseModel } from 'src/app/core/model/RoleModel';
import { DataTableHelper } from './DataTableHelper';

export class RolesResponseMapper implements Mapper<RoleListResponseDTO, RolesResponseModel> {

    mapFrom(dto: RoleListResponseDTO): RolesResponseModel {
        const model = {
            result: dto.result.map( item => {
                return {
                  id: item.id,
                  chrRol: item.chrRol,
                  bolFiltraUsuario: item.bolFiltraUsuario,
                  menus: item.menus.map( menu => {
                    return {
                      chrMenu: menu.chrMenu,
                      chrPath: menu.chrPath,
                      intOrden: menu.intOrden,
                      intNivelMenu: menu.intNivelMenu,
                      intFKIDMenuPadre: menu.intFKIDMenuPadre,
                      id: menu.id
                    };
                  })
                };
            }),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: RolesResponseModel): RoleListResponseDTO {
        return {
            total: model.total,
            result: null
        };
    }
}

export class RolesRequestMapper implements Mapper<RoleListRequestDTO, RolesRequestModel> {
  mapFrom(dto: RoleListRequestDTO): RolesRequestModel {
      const model: RolesRequestModel = {
          token: '',
          dataTableParams: null
      };
      return model;
  }

  mapTo(model: RolesRequestModel): RoleListRequestDTO {
      return {
          token: '',
          draw: model.dataTableParams.draw,
          length: model.dataTableParams.length,
          start: model.dataTableParams.start,
          search: model.dataTableParams.search.value,
          order: DataTableHelper.makeOrderObject(model.dataTableParams)
      };
  }
}
