import { Observable } from 'rxjs';
import {  RequestRequestModel,
          RequestResponseModel,
          PaymentRequestModel,
          PaymentRequestResponseModel,
          FetchRequestPaidResponseModel} from '../model/RequestModel';
import { RequestCreateRequestDTO, RequestCreateResponseDTO } from '../../data/dto/RequestCreateDTO';
import { RequestUpdateDTO, RequestUpdateResponseDTO } from '../../data/dto/RequestUpdateDTO';
import { RequestDeleteDTO, RequestDeleteResponseDTO } from '../../data/dto/RequestDeleteDTO';
import { RequestFetchResponseDTO } from '../../data/dto/RequestFetchDTO';
import { MatrixRequestCreateDTO, MatrixRequestCreateResponseDTO } from '../../data/dto/MatrixRequestCreateDTO';
import { MatrixRequestDeleteDTO, MatrixRequestDeleteResponseDTO } from '../../data/dto/MatrixRequestDeleteDTO';
import { SupportMessageRequestDTO, SupportMessageResponseDTO } from '../../data/dto/SupportMessageDTO';
import { MatrixRequestEditDTO, MatrixRequestEditResponseDTO, FetchMatrixRequestDTO, FetchMatrixRequestResponseDTO } from '../../data/dto/MatrixRequestEditDTO';

export abstract class RequestRepository {
    abstract fetch(request: RequestRequestModel): Observable<RequestResponseModel>;
    abstract update(request: RequestUpdateDTO): Observable<RequestUpdateResponseDTO>;
    abstract create(request: RequestCreateRequestDTO): Observable<RequestCreateResponseDTO>;
    abstract delete(request: RequestDeleteDTO): Observable<RequestDeleteResponseDTO>;
    abstract fetchRequest(request: number): Observable<RequestFetchResponseDTO>;
    abstract fetchPaymentRequest(request: PaymentRequestModel): Observable<PaymentRequestResponseModel>;
    abstract fetchPaidRequest(folio: string): Observable<FetchRequestPaidResponseModel>;
    abstract createMatrix(request: MatrixRequestCreateDTO): Observable<MatrixRequestCreateResponseDTO>;
    abstract deleteMatrix(request: MatrixRequestDeleteDTO): Observable<MatrixRequestDeleteResponseDTO>;
    abstract createSupportMessage(message: SupportMessageRequestDTO): Observable<SupportMessageResponseDTO>;
    abstract editMatrix(request: MatrixRequestEditDTO): Observable<MatrixRequestEditResponseDTO>;
    abstract fetchMatrixRequest(request: FetchMatrixRequestDTO): Observable<FetchMatrixRequestResponseDTO>;
}
