import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Localization } from 'src/app/shared/localization';
import { MenuItemModel } from './MenuModel';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';
import { environment } from 'src/environments/environment';
import { UserMenuFetchUseCase } from '../../../core/usecases/menu/UserMenuFetchUseCase';
import { UserMenuDTO } from '../../../data/dto/MenuDTO';
import { ToastService } from 'src/app/services/toast.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class SidebarComponent implements OnInit {
    localization: Localization;
    menus: MenuItemModel[];
    isActive: boolean;
    collapsed: boolean;
    showMenu: string;
    pushRightClass: string;
    loaderPage = false;
    showMenuSettings = false;
    listMenus: UserMenuDTO[] = [];
    linkMap: MenuItemModel[];
    backgroundColor = environment.background_menu;
    sidebarEscobedo = environment.sidebar_escobedo;
    envMonterrey = environment.environment_monterrey;


  @Output() collapsedEvent = new EventEmitter<boolean>();

    constructor(
        private router: Router,
        localization: Localization,
        private userMenuFetchUseCase: UserMenuFetchUseCase,
        private authService: AuthService,
        public toastService: ToastService
    ) {
        this.localization = localization;
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
                this.toggleSidebar();
            }
        });
    }

    ngOnInit() {
        this.setupMenu();
        this.activateMenu(this.router.url);
        this.isActive = false;
        this.collapsed = false;
        this.showMenu = '';
        this.pushRightClass = 'push-right';
        this.getListMenu();
    }

    getListMenu(): void {
      this.loaderPage = true;
      this.userMenuFetchUseCase.execute().subscribe(
        (data) => {
          this.loaderPage = false;
          this.listMenus = this.listMenus.concat(data.menu);
          this.listMenus.sort((a, b) => {
            if (a.intOrden > b.intOrden) {
              return 1;
            }
            if (a.intOrden < b.intOrden) {
              return -1
            }
            return 0;
          });
          this.orderSubmenus(this.listMenus);
        },
        (error) => {
          this.loaderPage = false;
          this.toastService.show(error, {
            classname: 'bg-danger text-light',
            delay: 5000,
            autohide: true,
            headertext: ''
          });
        }
      );
    }

    orderSubmenus(listMenus: UserMenuDTO[]): void {
      listMenus.forEach((element) => {
        if (element.submenus.length > 0) {
          element.submenus.sort((a, b) => {
            if (a.intOrden > b.intOrden) {
              return 1;
            }
            if (a.intOrden < b.intOrden) {
              return -1
            }
            return 0;
          });
        }
      });
    }


  eventCalled() {
    this.isActive = !this.isActive;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
    this.collapsedEvent.emit(this.collapsed);
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  rltAndLtr() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle('rtl');
  }

  onMenuClicked(menu: MenuItemModel) {
    if (menu.type === 'simple') {
      this.show(menu);
    } else {
      this.toggle(menu);
    }
  }

  toggle(currentMenu: MenuItemModel) {
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }
      });
    }
  }

  show(menu: MenuItemModel) {
    if (menu.target !== '') {
      this.router.navigate([menu.target]);
      this.activateMenu(menu.target);
    }
  }

  getState(currentMenu: MenuItemModel) {
    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  activateMenu(target: String) {
    this.menus.forEach(item => {
      item.active = (target === item.target);
    });
  }

  onLinkClicked(path: string): void{
    if (path !== '') {
        this.router.navigate([path]);
    }
  }

  redirectToMap(): void {
    location.assign(environment.mapa_url);
  }

  setupMenu() {
        if ( environment.environment_escobedo === true) {
          this.menus = [
            {
                title: this.localization.menu_requests,
                icon: 'fa fa-list',
                type: 'simple',
                target: '/requests',
                active: true,
                roles: ['bolCiudadano']
            },
            {
                title: this.localization.menu_mylicense,
                icon: 'fa fa-list-alt',
                type: 'simple',
                target: '/licenses',
                active: false,
                roles: ['']
            },
            {
                title: this.localization.menu_mymessage,
                icon: 'fa fa-comment',
                type: 'simple',
                target: '/messages',
                active: false,
                roles: ['bolCiudadano']
            },
            {
                title: this.localization.menu_newtransact,
                icon: 'fa fa-plus-circle',
                type: 'dropdown',
                active: false,
                roles: ['bolCiudadano'],
                submenus: [
                    {
                        title: this.localization.transacts_cve1,
                        type: 'simple',
                        target: '/new-transact/1',
                        active: false,
                        roles: ['bolCiudadano']
                    },
                    {
                      title: this.localization.transacts_cve77,
                      type: 'simple',
                      target: '/new-transact/77',
                      active: false,
                      roles: ['bolCiudadano']
                    },
                ]
            },
            {
              title: this.localization.menu_settlement,
              icon: 'fa fa-wrench',
              type: 'simple',
              target: '/settlement',
              roles: ['bolCiudadano']
            },
            {
                title: this.localization.menu_status_visualizer,
                icon: 'fa fa-search',
                type: 'simple',
                target: '/status-display',
                roles: ['bolCiudadano']
            },
            {
                title: this.localization.menu_requests_official,
                icon: 'fa fa-list',
                type: 'simple',
                target: '/request-officer',
                roles: ['bolAdmin', 'bolFuncionario']
            },
            {
                title: this.localization.menu_finished_requests,
                icon: 'fa fa-wrench',
                type: 'simple',
                target: '/finished-requests',
                roles: ['bolAdmin', 'bolFuncionario']
            },
            {
                title: this.localization.menu_settlement,
                icon: 'fa fa-wrench',
                type: 'simple',
                target: '/settlement-officer',
                roles: ['bolAdmin', 'bolFuncionario']
            },
            {
              title: this.localization.menu_settlement_inspectory,
              icon: 'fa fa-pen-alt',
              type: 'simple',
              target: '/settlement-inspectory',
              roles: ['bolAdmin', 'bolFuncionario']
            },
            {
              title: this.localization.menu_inspector_requests,
              icon: 'fa fa-pen-alt',
              type: 'simple',
              target: '/requests-inspector',
              roles: ['bolInspector']
            },
            {
              title: this.localization.menu_secretary_requests,
              icon: 'fa fa-pen-alt',
              type: 'simple',
              target: '/request-director',
              roles: ['bolDirector']
            },
            {
              title: this.localization.menu_secretary_requests,
              icon: 'fa fa-pen-alt',
              type: 'simple',
              target: '/request-secretary',
              roles: ['bolSecretario']
            },
            {
              title: this.localization.menu_preventiva,
              icon: 'fa fa-info',
              type: 'simple',
              target: '/request-preventiva',
              roles: ['bolSecretario']
            },
            {
              title: this.localization.menu_desist,
              icon: 'fa fa-window-close',
              type: 'simple',
              target: '/request-desist',
              active: true,
              roles: ['bolCiudadano', 'bolSecretario']
            },
            {
              title: this.localization.menu_status_visualizer,
                icon: 'fa fa-search',
                type: 'simple',
                target: '/status-display-officer',
              roles: ['bolFuncionario', 'bolSecretario', 'bolDirector','bolAdmin']
            },
            {
              title: this.localization.menu_historial,
              icon: 'fa fa-search',
              type: 'simple',
              target: '/report-display-officer',
              roles: ['bolFuncionario', 'bolSecretario', 'bolDirector','bolAdmin']
            },
            {
                title: this.localization.menu_admin,
                icon: 'fa fa-user-cog',
                type: 'dropdown',
                active: true,
                roles: ['bolAdmin'],
                submenus: [
                    {
                        title: this.localization.menu_fields,
                        type: 'simple',
                        target: '/fields',
                        active: false,
                        roles: ['bolAdmin']
                    },
                    {
                        title: this.localization.menu_users,
                        type: 'simple',
                        target: '/users',
                        active: false,
                        roles: ['bolAdmin']
                    },
                    {
                        title: this.localization.menu_admin_requests,
                        type: 'simple',
                        target: '/requests-admin',
                        active: false,
                        roles: ['bolAdmin']
                    },
                    {
                        title: this.localization.menu_documents,
                        type: 'simple',
                        target: '/documents',
                        active: false,
                        roles: ['bolAdmin']
                    },
                    {
                        title: this.localization.status,
                        type: 'simple',
                        target: '/config-status',
                        active: false,
                        roles: ['bolAdmin']
                    },
                    {
                      title: this.localization.menu_admin_list_menu,
                      type: 'simple',
                      target: '/menus',
                      active: false,
                      roles: ['bolAdmin']
                    },
                    {
                      title: this.localization.menu_admin_roles,
                      type: 'simple',
                      target: '/roles',
                      active: false,
                      roles: ['bolAdmin']
                    },
                    {
                      title: this.localization.menu_related_procedure,
                      type: 'simple',
                      target: '/related-procedures',
                      active: true,
                      roles: ['bolAdmin']
                    },
                    {
                      title: this.localization.menu_email_templates,
                      type: 'simple',
                      target: '/email-templates',
                      active: false,
                      roles: ['bolAdmin']
                    },
                    {
                      title: this.localization.menu_payment_methods,
                      type: 'simple',
                      target: '/payment-methods',
                      active: true,
                      roles: ['bolAdmin']
                    },
                    {
                      title: this.localization.menu_settings_municipio,
                      type: 'simple',
                      target: '/signature',
                      active: true,
                      roles: ['bolAdmin']
                    },   
                ]
            }
          ];
          this.linkMap = [{
            title: '',
            type: 'simple',
            target: '',
            active: true,
            roles: ['bolCiudadano', 'bolInspector']
          }];
        } else if((environment.env === 'cliente' || environment.env === 'prod')) {
            this.menus = [
                {
                    title: this.localization.menu_requests,
                    icon: 'fa fa-list',
                    type: 'simple',
                    target: '/requests',
                    active: true,
                    roles: ['bolCiudadano']
                },
                {
                    title: this.localization.menu_mylicense,
                    icon: 'fa fa-list-alt',
                    type: 'simple',
                    target: '/licenses',
                    active: false,
                    roles: ['']
                },
                {
                    title: this.localization.menu_mymessage,
                    icon: 'fa fa-comment',
                    type: 'simple',
                    target: '/messages',
                    active: false,
                    roles: ['bolCiudadano']
                },
                {
                  title: this.localization.menu_settlement,
                  icon: 'fa fa-wrench',
                  type: 'simple',
                  target: '/settlement',
                  roles: ['bolCiudadano']
                },
                {
                  title: this.localization.menu_status_visualizer,
                  icon: 'fa fa-search',
                  type: 'simple',
                  target: '/status-display',
                  roles: ['bolCiudadano']
                },
                {
                    title: this.localization.menu_requests_official,
                    icon: 'fa fa-list',
                    type: 'simple',
                    target: '/request-officer',
                    roles: ['bolAdmin', 'bolFuncionario']
                },
                {
                    title: this.localization.menu_finished_requests,
                    icon: 'fa fa-wrench',
                    type: 'simple',
                    target: '/finished-requests',
                    roles: ['bolAdmin', 'bolFuncionario']
                },
                {
                    title: this.localization.menu_settlement,
                    icon: 'fa fa-wrench',
                    type: 'simple',
                    target: '/settlement-officer',
                    roles: ['bolAdmin', 'bolFuncionario']
                },
                {
                  title: this.localization.menu_settlement_inspectory,
                  icon: 'fa fa-pen-alt',
                  type: 'simple',
                  target: '/settlement-inspectory',
                  roles: ['bolAdmin', 'bolFuncionario']
                },
                {
                  title: this.localization.menu_inspector_requests,
                  icon: 'fa fa-pen-alt',
                  type: 'simple',
                  target: '/requests-inspector',
                  roles: ['bolInspector']
                },
                {
                  title: this.localization.menu_secretary_requests,
                  icon: 'fa fa-pen-alt',
                  type: 'simple',
                  target: '/request-director',
                  roles: ['bolDirector']
                },
                {
                  title: this.localization.menu_secretary_requests,
                  icon: 'fa fa-pen-alt',
                  type: 'simple',
                  target: '/request-secretary',
                  roles: ['bolSecretario']
                },
                {
                  title: this.localization.menu_preventiva,
                  icon: 'fa fa-info',
                  type: 'simple',
                  target: '/request-preventiva',
                  roles: ['bolSecretario']
                },
                {
                  title: this.localization.menu_desist,
                  icon: 'fa fa-window-close',
                  type: 'simple',
                  target: '/request-desist',
                  active: true,
                  roles: ['bolCiudadano', 'bolSecretario']
                },
                {
                  title: this.localization.menu_status_visualizer,
                    icon: 'fa fa-search',
                    type: 'simple',
                    target: '/status-display-officer',
                  roles: ['bolFuncionario', 'bolSecretario', 'bolDirector','bolAdmin']
                },
                {
                  title: this.localization.menu_historial,
                  icon: 'fa fa-search',
                  type: 'simple',
                  target: '/report-display-officer',
                  roles: ['bolFuncionario', 'bolSecretario', 'bolDirector','bolAdmin']
                },
                {
                  title: this.localization.menu_status_pending_payment,
                    icon: 'fa fa-clock',
                    type: 'simple',
                    target: '/request-pending-payment',
                  roles: ['bolFuncionario']
                },
                {
                  title: this.localization.menu_reports,
                    icon: 'fa fa-chart-bar',
                    type: 'simple',
                    target: '/reports',
                  roles: ['bolSecretario', 'bolAdmin']
                },
                {
                    title: this.localization.menu_admin,
                    icon: 'fa fa-user-cog',
                    type: 'dropdown',
                    active: true,
                    roles: ['bolAdmin'],
                    submenus: [
                        {
                            title: this.localization.menu_fields,
                            type: 'simple',
                            target: '/fields',
                            active: false,
                            roles: ['bolAdmin']
                        },
                        {
                            title: this.localization.menu_users,
                            type: 'simple',
                            target: '/users',
                            active: false,
                            roles: ['bolAdmin']
                        },
                        {
                            title: this.localization.menu_admin_requests,
                            type: 'simple',
                            target: '/requests-admin',
                            active: false,
                            roles: ['bolAdmin']
                        },
                        {
                            title: this.localization.menu_documents,
                            type: 'simple',
                            target: '/documents',
                            active: false,
                            roles: ['bolAdmin']
                        },
                        {
                            title: this.localization.status,
                            type: 'simple',
                            target: '/config-status',
                            active: false,
                            roles: ['bolAdmin']
                        },
                        {
                          title: this.localization.menu_related_procedure,
                          type: 'simple',
                          target: '/related-procedures',
                          active: true,
                          roles: ['bolAdmin']
                        },
                        {
                          title: this.localization.menu_email_templates,
                          type: 'simple',
                          target: '/email-templates',
                          active: false,
                          roles: ['bolAdmin']
                        },
                        {
                          title: this.localization.menu_payment_methods,
                          type: 'simple',
                          target: '/payment-methods',
                          active: true,
                          roles: ['bolAdmin']
                        },
                        {
                          title: this.localization.menu_settings_municipio,
                          type: 'simple',
                          target: '/signature',
                          active: true,
                          roles: ['bolAdmin']
                        },
                    ]
                }
            ];
            this.linkMap = [{
              title: '',
              type: 'simple',
              target: '',
              active: true,
              roles: ['bolCiudadano', 'bolInspector']
            }];
        }
        else {
            this.menus = [
                {
                    title: this.localization.menu_requests,
                    icon: 'fa fa-list',
                    type: 'simple',
                    target: '/requests',
                    active: true,
                    roles: ['bolCiudadano']
                },
                {
                    title: this.localization.menu_mylicense,
                    icon: 'fa fa-list-alt',
                    type: 'simple',
                    target: '/licenses',
                    active: false,
                    roles: ['']
                },
                {
                    title: this.localization.menu_mymessage,
                    icon: 'fa fa-comment',
                    type: 'simple',
                    target: '/messages',
                    active: false,
                    roles: ['bolCiudadano']
                },
                {
                    title: this.localization.menu_newtransact,
                    icon: 'fa fa-plus-circle',
                    type: 'dropdown',
                    active: false,
                    roles: ['bolCiudadano'],
                    submenus: [
                      {
                          title: this.localization.transacts_cve1,
                          type: 'simple',
                          target: '/new-transact/1',
                          active: false,
                          roles: ['bolCiudadano']
                      },
                      {
                        title: this.localization.transacts_cve77,
                        type: 'simple',
                        target: '/new-transact/77',
                        active: false,
                        roles: ['bolCiudadano']
                      },
                      {
                        title: this.localization.transacts_cve79,
                        type: 'simple',
                        target: '/new-transact/79',
                        active: false,
                        roles: ['bolCiudadano']
                      },
                      {
                        title: this.localization.transacts_cve80,
                        type: 'simple',
                        target: '/new-transact/80',
                        active: false,
                        roles: ['bolCiudadano']
                      },
                  ]
                },

                {
                  title: this.localization.menu_settlement,
                  icon: 'fa fa-wrench',
                  type: 'simple',
                  target: '/settlement',
                  roles: ['bolCiudadano']
                },
                {
                    title: this.localization.menu_status_visualizer,
                    icon: 'fa fa-search',
                    type: 'simple',
                    target: '/status-display',
                    roles: ['bolCiudadano']
                },
                {
                    title: this.localization.menu_requests_official,
                    icon: 'fa fa-list',
                    type: 'simple',
                    target: '/request-officer',
                    roles: ['bolAdmin', 'bolFuncionario']
                },
                {
                    title: this.localization.menu_finished_requests,
                    icon: 'fa fa-wrench',
                    type: 'simple',
                    target: '/finished-requests',
                    roles: ['bolAdmin', 'bolFuncionario']
                },
                {
                    title: this.localization.menu_settlement,
                    icon: 'fa fa-wrench',
                    type: 'simple',
                    target: '/settlement-officer',
                    roles: ['bolAdmin', 'bolFuncionario']
                },
                {
                  title: this.localization.menu_settlement_inspectory,
                  icon: 'fa fa-pen-alt',
                  type: 'simple',
                  target: '/settlement-inspectory',
                  roles: ['bolAdmin', 'bolFuncionario']
                },
                {
                  title: this.localization.menu_inspector_requests,
                  icon: 'fa fa-pen-alt',
                  type: 'simple',
                  target: '/requests-inspector',
                  roles: ['bolInspector']
                },
                {
                  title: this.localization.menu_secretary_requests,
                  icon: 'fa fa-pen-alt',
                  type: 'simple',
                  target: '/request-director',
                  roles: ['bolDirector']
                },
                {
                  title: this.localization.menu_secretary_requests,
                  icon: 'fa fa-pen-alt',
                  type: 'simple',
                  target: '/request-secretary',
                  roles: ['bolSecretario']
                },
                {
                  title: this.localization.menu_preventiva,
                  icon: 'fa fa-info',
                  type: 'simple',
                  target: '/request-preventiva',
                  roles: ['bolSecretario']
                },
                {
                  title: this.localization.menu_desist,
                  icon: 'fa fa-window-close',
                  type: 'simple',
                  target: '/request-desist',
                  active: true,
                  roles: ['bolCiudadano', 'bolSecretario']
                },
                {
                  title: this.localization.menu_reports,
                    icon: 'fa fa-chart-bar',
                    type: 'simple',
                    target: '/reports',
                  roles: ['bolSecretario']
                },
                {
                  title: this.localization.menu_status_visualizer,
                    icon: 'fa fa-search',
                    type: 'simple',
                    target: '/status-display-officer',
                  roles: ['bolFuncionario', 'bolSecretario', 'bolDirector','bolAdmin']
                },
                {
                  title: this.localization.menu_historial,
                  icon: 'fa fa-search',
                  type: 'simple',
                  target: '/report-display-officer',
                  roles: ['bolFuncionario', 'bolSecretario', 'bolDirector','bolAdmin']
                },
                {
                  title: this.localization.menu_status_pending_payment,
                    icon: 'fa fa-clock',
                    type: 'simple',
                    target: '/request-pending-payment',
                  roles: ['bolFuncionario']
                },
                {
                  title: this.localization.menu_reports,
                    icon: 'fa fa-chart-bar',
                    type: 'simple',
                    target: '/reports',
                  roles: ['bolSecretario', 'bolAdmin']
                },
                {
                    title: this.localization.menu_admin,
                    icon: 'fa fa-user-cog',
                    type: 'dropdown',
                    active: true,
                    roles: ['bolAdmin'],
                    submenus: [
                        {
                            title: this.localization.menu_fields,
                            type: 'simple',
                            target: '/fields',
                            active: false,
                            roles: ['bolAdmin']
                        },
                        {
                            title: this.localization.menu_users,
                            type: 'simple',
                            target: '/users',
                            active: false,
                            roles: ['bolAdmin']
                        },
                        {
                            title: this.localization.menu_admin_requests,
                            type: 'simple',
                            target: '/requests-admin',
                            active: false,
                            roles: ['bolAdmin']
                        },
                        {
                            title: this.localization.menu_documents,
                            type: 'simple',
                            target: '/documents',
                            active: false,
                            roles: ['bolAdmin']
                        },
                        {
                            title: this.localization.status,
                            type: 'simple',
                            target: '/config-status',
                            active: false,
                            roles: ['bolAdmin']
                        },

                        {
                          title: this.localization.menu_admin_roles,
                          type: 'simple',
                          target: '/roles',
                          active: false,
                          roles: ['bolAdmin']
                        },

                        {
                          title: this.localization.menu_related_procedure,
                          type: 'simple',
                          target: '/related-procedures',
                          active: true,
                          roles: ['bolAdmin']
                        },
                        {
                          title: this.localization.menu_email_templates,
                          type: 'simple',
                          target: '/email-templates',
                          active: false,
                          roles: ['bolAdmin']
                        },
                        {
                          title: this.localization.menu_payment_methods,
                          type: 'simple',
                          target: '/payment-methods',
                          active: true,
                          roles: ['bolAdmin']
                        },
                        {
                          title: this.localization.menu_settings_municipio,
                          type: 'simple',
                          target: '/signature',
                          active: true,
                          roles: ['bolAdmin']
                        },
                    ]
                }
            ];
            this.linkMap = [{
              title: '',
              type: 'simple',
              target: '',
              active: true,
              roles: ['bolCiudadano', 'bolInspector']
            }];
        }

  }

  canView(menu: MenuItemModel): boolean {
    const roles = menu.roles;
    if (this.authService.currentUserValue.bolAdmin && roles.includes('bolAdmin')) {
      return true;
    }
    if (this.authService.currentUserValue.bolFuncionario &&
      (this.authService.currentUserValue.bolInspector === false &&
        this.authService.currentUserValue.bolSecretario === false &&
        this.authService.currentUserValue.bolDirector === false) &&
      roles.includes('bolFuncionario')) {
      return true;
    }
    if (this.authService.currentUserValue.bolCiudadano && roles.includes('bolCiudadano')) {
      return true;
    }
    if (this.authService.currentUserValue.bolInspector && roles.includes('bolInspector')) {
      return true;
    }
    if (this.authService.currentUserValue.bolSecretario && roles.includes('bolSecretario')) {
      return true;
    }
    if (this.authService.currentUserValue.bolDirector &&  roles.includes('bolDirector')) {
      return true;
    }
    return false;
  }

  getBackgroundColor() {
    return {background: environment.background_sidebar_header};
  }

  getTextColor() {
    return {color: environment.environment_monterrey !== true ? '#ffff': '#000'};
  }



}
