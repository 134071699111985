import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './../../../auth/service/auth.service';
import { Localization } from 'src/app/shared/localization';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FetchMessageUseCase } from 'src/app/core/usecases/messages/FetchMessagesUseCase';
import { MessageRequestModel, MessageModel } from '../../../core/model/MessageModel';
import { ToastService } from 'src/app/services/toast.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userName: String;
  showSideBar: Boolean = true;
  localization: Localization;
  public pushRightClass: string;
  dateNow;
  logoBlanco_h: string;
  backgroundColor: any;
  showNotification = false;
  messages: MessageModel[] = [];
  messagesFilter : MessageModel[]=[];
  isCitizen: Boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthService,
    localization: Localization,
    private fetchMessageUseCase: FetchMessageUseCase,
    public toastService: ToastService,
  ) {
    this.localization = localization;
    this.authenticationService.currentUser
    .subscribe(currentUser => this.userName = currentUser != null ? currentUser.user : '');
    this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
            this.toggleSidebar();
        }
    });
  }

  ngOnInit() {
    this.isCitizen = this.authenticationService.currentUserValue.bolCiudadano;
    this.fetchMessages();
    this.pushRightClass = 'push-right';
    this.dateNow = Date.now();
    this.logoBlanco_h = environment.logoBlanco_header;
    this.backgroundColor = environment.color_header;
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  rltAndLtr() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle('rtl');
  }

  closeLogout() {
    this.router.navigate(['/login']);
    localStorage.removeItem('infoTramite');
    localStorage.setItem('logged', 'false');
    this.authenticationService.logout();
  }

  fetchMessages(): void {
    const request = this.makeMessagesRequest();
    this.fetchMessageUseCase.execute(request).subscribe(
      data => {
        this.messages = data.messages;
        this.filterNotification();
      },
      error => {
        this.toastService.show(error, {
          classname: 'bg-danger text-light',
          delay: 5000,
          autohide: true,
          headertext: ''
        });
      }
    );
  }

  filterNotification(): void {
    this.messagesFilter = this.messages.filter(message => message.bolVisto === false);
  }

  makeMessagesRequest(): MessageRequestModel {
    const searchModel = {
      value: '',
      regex: '',
    };
    const dataTableParams: any = {
      draw: 1,
      length: 20,
      start: 0,
      columns: [],
      search: searchModel,
      order: [],
    };
    const request: MessageRequestModel = {
      token: this.authenticationService.currentUserValue.token,
      idTown : 1,
      dataTableParams: dataTableParams,

    };
    return request;
  }

  goToMessages(): void {
    this.router.navigate(['/messages']);
  }

}
