import { Observable } from 'rxjs';
import {    DocumentListResponseDTO,
            DocumentListRequestDTO,
            DocumentRefuseRequestDTO,
            DocumentResponseListDTO,
            DocumentApproveRequestDTO } from '../../data/dto/DocumentListDTO';
import {    DocumentUpdateDTO,
            DocumentUpdateResponseDTO,
            DocumentFetchRequestDTO,
            DocumentFetchResponseDTO } from '../../data/dto/DocumentUpdateDTO';
import { DocumentCatalogueRequestModel, DocumentCatalogueResponseModel } from '../model/DocumentModel';
import { DocumentCreateDTO, DocumentCreateResponseDTO } from '../../data/dto/DocumentCreateDTO';
import { DocumentDeleteDTO, DocumentDeleteResponseDTO } from '../../data/dto/DocumentDeleteDTO';
import { UploadFormatDTO, UploadFormatResponseDTO } from '../../data/dto/DocumentUploadFormat';
import { DocumentsFetchListRequestDTO, DocumentsFetchListResponseDTO } from '../../data/dto/DocumentsFetchListDTO';
import { ListDocumentTypesResponse } from '../../data/dto/DocumentDTO';
import { DocumentsExistListRequestDTO, DocumentsExistListResponseDTO, DocumentsDictamenListRequestDTO, DictamenByOfficerListRequestDTO, DictamenByOfficerListResponseDTO } from '../../data/dto/DocumentExistListDTO'
import { RequestModel, RequestResponseModel} from 'src/app/core/model/RequestHistoryModel';

export abstract class DocumentRepository {
    abstract list(document: DocumentListRequestDTO): Observable<DocumentListResponseDTO>;
    abstract refuse(document: DocumentRefuseRequestDTO): Observable<DocumentResponseListDTO>;
    abstract approve(document: DocumentApproveRequestDTO): Observable<DocumentResponseListDTO>;
    abstract catalogue(document: DocumentCatalogueRequestModel): Observable<DocumentCatalogueResponseModel>;
    abstract create(document: DocumentCreateDTO): Observable<DocumentCreateResponseDTO>;
    abstract update(document: DocumentUpdateDTO): Observable<DocumentUpdateResponseDTO>;
    abstract fetch(document: DocumentFetchRequestDTO): Observable<DocumentFetchResponseDTO>;
    abstract delete(document: DocumentDeleteDTO): Observable<DocumentDeleteResponseDTO>;
    abstract upload(file: UploadFormatDTO): Observable<UploadFormatResponseDTO>;
    abstract fieldsList(documents: DocumentsFetchListRequestDTO): Observable<DocumentsFetchListResponseDTO>;
    abstract listDocumentTypes(): Observable<ListDocumentTypesResponse>;
    abstract existList(document: DocumentsExistListRequestDTO): Observable<DocumentsExistListResponseDTO>;
    abstract existOne(document: DocumentsExistListRequestDTO): Observable<DocumentsExistListResponseDTO>;
    abstract dictamenList(document: DocumentsDictamenListRequestDTO): Observable<DocumentsDictamenListRequestDTO>;
    abstract dictamenByOfficerList(document: DictamenByOfficerListRequestDTO): Observable<DictamenByOfficerListResponseDTO>;
    abstract refuseList(document:RequestModel):Observable<RequestResponseModel>
}
