import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToastGlobalComponent } from './toast-global.component';
import { ToastGlobalRoutingModule } from './toast-global-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BootstrapModule } from '../bootstrap/bootstrap.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    ToastGlobalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ToastGlobalRoutingModule,
    FormsModule,
    BootstrapModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [
    ToastGlobalComponent
  ]
})
export class ToastGlobalModule { }
