import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { UserRepository } from '../../repositories/UserRepository';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogOutUseCase implements UseCase<String, String> {
  constructor(private userRepository: UserRepository) { }

  execute(): Observable<String> {
    return this.userRepository.logout();
  }
}
