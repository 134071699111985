import { Mapper } from 'src/app/core/base/Mapper';
import { FieldsCatalogueRequestDTO, FieldsCatalogueResponseDTO } from './FieldsCatalogueDTO';
import { FieldsCatalogueResponseModel, FieldsCatalogueRequestModel } from 'src/app/core/model/FieldsCatalogueModel';
import { DataTableHelper } from './DataTableHelper';

export class FieldsCatalogueResponseMapper implements Mapper<FieldsCatalogueResponseDTO, FieldsCatalogueResponseModel> {

    mapFrom(dto: FieldsCatalogueResponseDTO): FieldsCatalogueResponseModel {
        const model = {
            result: dto.result,
            campos: dto.campos.map( item => {
                return {
                    id: item.id,
                    chrCampo: item.chrCampo,
                    chrDescripcion: item.chrDescripcion,
                    chrTipoCampo: item.chrTipoCampo,
                    intOrden: item.intOrden,
                    intFKIDMunicipio: item.intFKIDMunicipio,
                    chrGrupo: item.chrGrupo,
                    chrEncabezado: item.chrEncabezado,
                    chrColor: item.chrColor,
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: FieldsCatalogueResponseModel): FieldsCatalogueResponseDTO {
        return {
            result: model.result,
            campos: null
        };
    }
}

export class FieldsCatalogueRequestMapper implements Mapper<FieldsCatalogueRequestDTO, FieldsCatalogueRequestModel> {
    mapFrom(dto: FieldsCatalogueRequestDTO): FieldsCatalogueRequestModel{
        const model: FieldsCatalogueRequestModel = {
            idTown: dto.idMunicipio,
            token: '',
            dataTableParams: null
        };
        return model;
    }

    mapTo(model: FieldsCatalogueRequestModel): FieldsCatalogueRequestDTO {
        return {
            idMunicipio: model.idTown,
            token: '',
            draw: model.dataTableParams.draw,
            length: model.dataTableParams.length,
            start: model.dataTableParams.start,
            search: model.dataTableParams.search.value,
            order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}
