import { 
    LicenseTypeResponseDTO, LicenseTypeRequestDTO 
} from './LicenseTypeDTO';
import { Mapper } from 'src/app/core/base/Mapper';
import { 
    LicenseTypeResponseModel, LicenseTypeRequestModel 
} from '../../core/model/LicenseTypeModel';

export class LicenseTypeResponseMapper implements Mapper<LicenseTypeResponseDTO, LicenseTypeResponseModel> {
    mapFrom(dto: LicenseTypeResponseDTO): LicenseTypeResponseModel {
        let model = {
            result: dto.result,
            licenses: dto.licencias.map( item => {
                return {
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    enabled: item.enabled,
                    idTown: item.idTown
                };
            } )
        };
        return model;
    }

    mapTo(model: LicenseTypeResponseModel): LicenseTypeResponseDTO {
        return {
            result: model.result,
            licencias: null
        };
    }
    
}

export class LicenseTypeRequestMapper implements Mapper<LicenseTypeRequestDTO, LicenseTypeRequestModel> {
    mapFrom(dto: LicenseTypeRequestDTO): LicenseTypeRequestModel {
        let model = {
            idTown: dto.idTown,
            token: ""
        };
        return model;
    }

    mapTo(model: LicenseTypeRequestModel): LicenseTypeRequestDTO {
        return {
            idTown: model.idTown,
        };
    }
    
}