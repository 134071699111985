import { Observable } from 'rxjs';
import { EmailTemplateListRequestModel, EmailTemplateListResponseModel } from '../model/EmailTemplateModel';
import {
  EditEmailTemplateRequestDTO, EditEmailTemplateResponseDTO,
  DeleteEmailTemplateRequestDTO, DeleteEmailTemplateResponseDTO,
  CreateEmailTemplateRequestDTO, CreateEmailTemplateResponseDTO,
  FetchEmailTemplateRequestDTO, FetchEmailTemplateResponseDTO
} from '../../data/dto/EmailTemplateDTO';

export abstract class EmailTemplateRepository {
    abstract list(template: EmailTemplateListRequestModel): Observable<EmailTemplateListResponseModel>;
    abstract create(template: CreateEmailTemplateRequestDTO): Observable<CreateEmailTemplateResponseDTO>;
    abstract fetchById(idTemplate: FetchEmailTemplateRequestDTO): Observable<FetchEmailTemplateResponseDTO>;
    abstract editTemplate(template: EditEmailTemplateRequestDTO): Observable<EditEmailTemplateResponseDTO>;
    abstract delete(idTemplate: DeleteEmailTemplateRequestDTO): Observable<DeleteEmailTemplateResponseDTO>;
    abstract listTemplates(template: any): Observable<any>
}
