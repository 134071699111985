import { Mapper } from 'src/app/core/base/Mapper';
import { RequestResponseDTO, RequestDTO } from './RequestHistoryDTO';
import { RequestResponseModel, RequestModel } from 'src/app/core/model/RequestHistoryModel';
import { DataTableHelper } from './DataTableHelper';

export class RequestResponseMapper implements Mapper<RequestResponseDTO, RequestResponseModel> {

  mapFrom(dto: RequestResponseDTO): RequestResponseModel {
        const model = {
            result: dto.result,
            requests: dto.requests.map( item => {
                return {
                    id: item.id,
                    createdAt: item.createdAt,
                    chrMotivoRechazo: item.chrMotivoRechazo,
                    chrEvento: item.chrEvento,
                    chrUser: {
                      chrNombre: item.chrUser.chrNombre,
                      chrPaterno: item.chrUser.chrPaterno,
                      intFKIDRol: item.chrUser.intFKIDRol,
                    },
                    chrDocumento: item.chrDocumento,
                    chrNotificacion: {
                      id : item.chrNotificacion.id,
                      chrNombreArchivo : item.chrNotificacion.chrNombreArchivo,
                      chrTipoArchivo : item.chrNotificacion.chrTipoArchivo,
                      chrRuta : item.chrNotificacion.chrRuta,
                      intFKIDSeguimientoTramite : item.chrNotificacion.intFKIDSeguimientoTramite,
                      intValidado : item.chrNotificacion.intValidado,
                      intFKIDDocumento : item.chrNotificacion.intFKIDDocumento,
                      chrObservacion : item.chrNotificacion.chrObservacion,
                      intFKIDMunicipio : item.chrNotificacion.intFKIDMunicipio,
                      chrMotivoRechazo : item.chrNotificacion.chrMotivoRechazo,
                      intEstatus : item.chrNotificacion.intEstatus,
                      createdAt : item.chrNotificacion.createdAt,
                      updatedAt : item.chrNotificacion.updatedAt,
                    },
                    userId: item.userId,
                    documentId: item.documentId,
                    idFolio: item.idFolio
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

  mapTo(model: RequestResponseModel): RequestResponseDTO {
      return {
          result: model.result,
          requests: null,
          total: model.total,
          rowsFiltered: model.rowsFiltered
      };
  }
}

export class RequestMapper implements Mapper<RequestDTO, RequestModel> {
    mapFrom(dto: RequestDTO): RequestModel {
        const model: RequestModel = {
          userId: dto.userId,
          documentId: dto.documentId,
          idSeguimiento: dto.idSeguimiento,
          chrEvento: dto.chrEvento,
          idFolio: dto.idFolio,
          dataTableParams: null
        };
        return model;
    }

    mapTo(model: RequestModel): RequestDTO {
        return {
          userId: model.userId,
          documentId: model.documentId,
          idSeguimiento: model.idSeguimiento,
          chrEvento: model.chrEvento,
          idFolio: model.idFolio,
          draw: model.dataTableParams.draw,
          length: model.dataTableParams.length,
          start: model.dataTableParams.start,
          order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}
