import { RequestListRequestDTO, RequestListResponseDTO } from './RequestListDTO';
import { Mapper } from 'src/app/core/base/Mapper';
import { RequestListRequestModel, RequestListResponseModel } from '../../core/model/RequestListModel';
import { DataTableHelper } from './DataTableHelper';

export class RequestListResponseMapper implements Mapper<RequestListResponseDTO, RequestListResponseModel> {
    mapFrom(dto: RequestListResponseDTO): RequestListResponseModel {
        const model = {
            result: dto.result,
            requests: dto.tramites.map( item => {
                return {
                    id: item.id,
                    name: item.chrTramite,
                    description: item.chrDescripcion,
                    enabled: item.bolActivo,
                    idTown: item.intFKIDMunicipio
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: RequestListResponseModel): RequestListResponseDTO {
        return {
            result: model.result,
            tramites: null
        };
    }
}

export class RequestListRequestMapper implements Mapper<RequestListRequestDTO, RequestListRequestModel> {
    mapFrom(dto: RequestListRequestDTO): RequestListRequestModel {
        const model = {
            idTown: dto.idMunicipio,
            token: '',
            dataTableParams: null
        };
        return model;
    }

    mapTo(model: RequestListRequestModel): RequestListRequestDTO {
        return {
            idMunicipio: model.idTown,
            draw: model.dataTableParams.draw,
            length: model.dataTableParams.length,
            start: model.dataTableParams.start,
            search: model.dataTableParams.search.value,
            order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}
