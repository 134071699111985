import { PaymentMethodsRepository } from 'src/app/core/repositories/PaymentMethodsRepository';
import { EditPaymentMethodsDTO, ListPaymentMethodsResponseDTO, DownloadReferenciaDTO, RequestPaymentMethodsDTO } from 'src/app/data/dto/PaymentMethodsDTO';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodsRestRepository implements PaymentMethodsRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient, private authService: AuthService) {
        const token = this.authService.currentUserValue.token;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        });
    }

    list(request: RequestPaymentMethodsDTO): Observable<ListPaymentMethodsResponseDTO> {
      const endpoint = environment.payment_methods_endpoint;
      const params = new HttpParams({
        fromObject: {
          bolActivo:  request.bolActivo
        }
    });
      const httpOptions = { headers: this.headers, params};
      return this.http
        .get<ListPaymentMethodsResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
        .pipe(
          catchError(this.handleError)
        )
        .pipe(map(result => {
          return result;
        }));
    }

    update(request: EditPaymentMethodsDTO): Observable<ListPaymentMethodsResponseDTO> {
      const endpoint = environment.update_payment_methods_endpoint;
      const httpOptions = { headers: this.headers };

      return this.http
        .put<ListPaymentMethodsResponseDTO>(`${environment.url_base}/${endpoint}`, request, httpOptions)
        .pipe(
          catchError(this.handleError)
        )
        .pipe(map(result => {
          return result;
        }));
    }

    download(file: DownloadReferenciaDTO): Observable<any> {
      const endpoint = environment.dowload_referencia_pago;
      const httpOptions = {
          headers: this.headers,
          responseType: 'blob' as 'json'
      };
      return this.http.post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(file), httpOptions)
      .pipe(
          catchError(this.handleError),
          map(result => {
              return result;
          }),
      );
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
