import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import { StatusRepository } from 'src/app/core/repositories/StatusRepository';
import { StatusCreateRequestModel, StatusCreateResponseModel,
  StatusListRequestModel, StatusListResponseModel } from 'src/app/core/model/StatusModel';

@Injectable({
  providedIn: 'root'
})
export class StatusRestRepository implements StatusRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthService) {
    const token = this.authService.currentUserValue.token;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + token
    });
  }

  list(id: StatusListRequestModel): Observable<StatusListResponseModel> {
    const endpoint = environment.status_list;
    const params = new HttpParams({
      fromObject: {
        IdMunicipio: id.IdMunicipio.toString()
      }
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<StatusListResponseModel>(`${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
      .pipe(map(result => {
        return result;
      }));
  }

  create(estatus: StatusCreateRequestModel): Observable<StatusCreateResponseModel> {
    const endpoint = environment.status_create;
    const httpOptions = { headers: this.headers};
    return this.http
      .post<StatusCreateResponseModel>(`${environment.url_base}/${endpoint}`, JSON.stringify(estatus), httpOptions)
      .pipe(
        catchError(this.handleError)
      )
      .pipe(map(result => {
        return result;
      }));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
