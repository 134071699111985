import { MessageViewRepository } from 'src/app/core/repositories/MessageViewRepository';
import { MessageViewRequestDTO, MessageViewResponseDTO } from 'src/app/data/dto/MessageDTO';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MessageViewRestRepository implements MessageViewRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient, private authService: AuthService) {
        const token = this.authService.currentUserValue.token;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        });
    }

    view(message: MessageViewRequestDTO): Observable<MessageViewResponseDTO> {
        const endpoint = environment.messages_view_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .put<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(message), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                console.log('view response: ', result)
                return result;
            }));
    }

    visualizacion(message: MessageViewRequestDTO): Observable<MessageViewResponseDTO> {
        const endpoint = environment.preventiva_vista_message_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(message), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    visualizacionPreventiva(message: MessageViewRequestDTO): Observable<MessageViewResponseDTO> {
        const endpoint = environment.preventiva_vista_preventiva_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(message), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    noVisualizacion(message: MessageViewRequestDTO): Observable<MessageViewResponseDTO> {
        const endpoint = environment.preventiva_no_vista_message_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .post<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(message), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
