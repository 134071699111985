import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LayoutComponent } from './component/layout/layout.component';
import { CitizenGuard } from '../auth/interceptor/citizen.guard';
import { OfficerGuard } from '../auth/interceptor/officer.guard';
import { AdminGuard } from '../auth/interceptor/admin.guard';
import { ProfileGuard } from '../auth/interceptor/profile.guard';
import { InspectorGuard } from '../auth/interceptor/inspector.guard';
import { SecretaryGuard } from '../auth/interceptor/secretary.guard';
import { DirectorGuard } from '../auth/interceptor/director.guard';
import { CitizenSecretaryGuard } from '../auth/interceptor/citizensecretary.guard'
import { OfficerDirectorSecretary } from '../auth/interceptor/officerdirectorsecretary.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./../home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'requests',
        loadChildren: () =>
          import('./../request/request.module').then((m) => m.RequestModule),
        canActivate: [CitizenGuard],
      },
      {
        path: 'licenses',
        loadChildren: () =>
          import('./../license/license.module').then((m) => m.LicenseModule),
        canActivate: [CitizenGuard],
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./../message/message.module').then((m) => m.MessageModule),
        canActivate: [CitizenGuard],
      },
      {
        path: 'new-transact/:idTipoTramite',
        loadChildren: () =>
          import('./../new-transact/new-transact.module').then(
            (m) => m.NewTransactModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'status-display',
        loadChildren: () =>
          import('./../status-display/status-display.module').then(
            (m) => m.StatusDisplayModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'settlement',
        loadChildren: () =>
          import('./../settlement/settlement.module').then(
            (m) => m.SettlementModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'personal-data/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import('./../citizen/personal-data/personal-data.module').then(
            (m) => m.PersonalDataModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'personal-owner-data/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import(
            '../citizen/personal-owner-data/personal-owner-data.module'
          ).then((m) => m.PersonalOwnerDataModule),
        canActivate: [CitizenGuard],
      },
      {
        path: 'owner-data/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import('./../citizen/owner-data/owner-data.module').then(
            (m) => m.OwnerDataModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'agent-owner-data/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import('./../citizen/agent-owner-data/agent-owner-data.module').then(
            (m) => m.AgentOwnerDataModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'personal-tenant-data/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import(
            './../citizen/tenant/personal-tenant-data/personal-tenant-data.module'
          ).then((m) => m.PersonalTenantDataModule),
        canActivate: [CitizenGuard],
      },
      {
        path: 'tenant-data/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import('./../citizen/tenant/tenant-data/tenant-data.module').then(
            (m) => m.TenantDataModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'agent-tenant-data/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import(
            './../citizen/tenant/agent-tenant-data/agent-tenant-data.module'
          ).then((m) => m.AgentTenantDataModule),
        canActivate: [CitizenGuard],
      },
      {
        path: 'personal-poa-data/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import(
            './../citizen/power-of-attorney/personal-poa-data/personal-poa-data.module'
          ).then((m) => m.PersonalPoaDataModule),
        canActivate: [CitizenGuard],
      },
      {
        path: 'poa-data/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import(
            './../citizen/power-of-attorney/poa-data/poa-data.module'
          ).then((m) => m.PoaDataModule),
        canActivate: [CitizenGuard],
      },
      {
        path: 'agent-poa-data/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import(
            './../citizen/power-of-attorney/agent-poa-data/agent-poa-data.module'
          ).then((m) => m.AgentPoaDataModule),
        canActivate: [CitizenGuard],
      },
      {
        path: 'nav-citizen/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import('./../citizen/nav-citizen/nav-citizen.module').then(
            (m) => m.NavCitizenModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path:
          'requirement-pdf/:cveCatastral/:folio/:idTipoTramite/:nameTramitante',
        loadChildren: () =>
          import('./../citizen/requirement-pdf/requirement-pdf.module').then(
            (m) => m.RequirementPdfModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'success-payment/:idFolio',
        loadChildren: () =>
          import('./../citizen/success-payment/success-payment.module').then(
            (m) => m.SuccessPaymentModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'user-profile',
        loadChildren: () =>
          import('./../user-profile/user-profile.module').then(
            (m) => m.UserProfileModule
          ),
        canActivate: [ProfileGuard],
      },
      {
        path: 'request-officer',
        loadChildren: () =>
          import('./../request-officer/request-officer.module').then(
            (m) => m.RequestOfficerModule
          ),
        canActivate: [OfficerGuard],
      },
      {
        path: 'finished-requests',
        loadChildren: () =>
          import('./../finished-request/finished-requests.module').then(
            (m) => m.FinishedRequestsModule
          ),
        canActivate: [OfficerGuard],
      },
      {
        path: 'request-form/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import('./../citizen/request-form/request-form.module').then(
            (m) => m.RequestFormModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'request-end/:idSeguimiento/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import('./../citizen/request-end/request-end.module').then(
            (m) => m.RequestEndModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'request-validate/:idSeguimiento/:nameTramite/:idTipoTramite',
        loadChildren: () =>
          import('./../validate-request/validate-request.module').then(
            (m) => m.ValidateRequestModule
          ),
        canActivate: [OfficerGuard],
      },
      {
        path: 'solvent-form/:cveCatastral/:idSeguimiento/:idTipoTramite/:folio',
        loadChildren: () =>
          import('./../citizen/solvent-form/solvent-form.module').then(
            (m) => m.SolventFormModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'request-fields',
        loadChildren: () =>
          import('./../request-fields/request-fields.module').then(
            (m) => m.RequestFieldsModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'request-fields-view',
        loadChildren: () =>
          import('./../request-fields-view/request-fields-view.module').then(
            (m) => m.RequestFieldsViewModule
          ),
        canActivate: [OfficerGuard],
      },
      {
        path: 'integration-request/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import('./../integration-request/integration-request.module').then(
            (m) => m.IntegrationRequestModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'settlement-officer',
        loadChildren: () =>
          import('./../settlement-officer/settlement-officer.module').then(
            (m) => m.SettlementOfficerModule
          ),
        canActivate: [OfficerGuard],
      },
      {
        path: 'continue-request/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import('./../citizen/continue-request/continue-request.module').then(
            (m) => m.ContinueRequestModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./../admin/users/users.module').then((m) => m.UsersModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'create-user',
        loadChildren: () =>
          import('./../admin/create-user/create-user.module').then(
            (m) => m.CreateUserModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'edit-user/:idUser',
        loadChildren: () =>
          import('./../admin/edit-user/edit-user.module').then(
            (m) => m.EditUserModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'fields',
        loadChildren: () =>
          import('./../admin/fields/fields.module').then((m) => m.FieldsModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'requests-admin',
        loadChildren: () =>
          import('./../admin/requests/requests.module').then(
            (m) => m.RequestsModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'create-field',
        loadChildren: () =>
          import('./../admin/create-field/create-field.module').then(
            (m) => m.CreateFieldModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'documents',
        loadChildren: () =>
          import('./../admin/documents/documents.module').then(
            (m) => m.DocumentsModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'edit-field/:idUser',
        loadChildren: () =>
          import('./../admin/edit-field/edit-field.module').then(
            (m) => m.EditFieldModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'create-document',
        loadChildren: () =>
          import('./../admin/create-document/create-document.module').then(
            (m) => m.CreateDocumentModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'create-request',
        loadChildren: () =>
          import('./../admin/create-request/create-request.module').then(
            (m) => m.CreateRequestModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'edit-document/:idDoc',
        loadChildren: () =>
          import('./../admin/edit-document/edit-document.module').then(
            (m) => m.EditDocumentModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'edit-request/:idRequest',
        loadChildren: () =>
          import('./../admin/edit-request/edit-request.module').then(
            (m) => m.EditRequestModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'add-document/:idRequest',
        loadChildren: () =>
          import('./../admin/add-document/add-document.module').then(
            (m) => m.AddDocumentModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'add-field/:idRequest',
        loadChildren: () =>
          import('./../admin/add-field/add-field.module').then(
            (m) => m.AddFieldModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'edit-field-matriz/:idRequest/:idCampo',
        loadChildren: () =>
          import('./../admin/matriz-edit-field/matriz-edit-field.module').then(
            (m) => m.MatrizEditFieldModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'request-fields-solvent',
        loadChildren: () =>
          import(
            './../request-fields-solvent/request-fields-solvent.module'
          ).then((m) => m.RequestFieldsSolventModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'edit-related-procedures/:id',
        loadChildren: () =>
          import('./../admin/edit-related-procedure/edit-related-procedure.module').then(
            (m) => m.EditRelatedProcedureModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'create-related-procedures',
        loadChildren: () =>
          import('./../admin/create-related-procedure/create-related-procedure.module').then(
            (m) => m.CreateRelatedProcedureModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'related-procedures',
        loadChildren: () =>
          import(
            './../admin/related-procedures/related-procedures.module'
          ).then((m) => m.RelatedProcedureModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'requests-inspector',
        loadChildren: () =>
          import('./../request-by-officer/request-by-officer.module').then(
            (m) => m.RequestByOfficerModule
          ),
        canActivate: [InspectorGuard],
      },
      {
        path: 'request-desist',
        loadChildren: () =>
          import('./../request-desist/request-desist.module').then(
            (m) => m.RequestDesistModule
          ),
        canActivate: [CitizenSecretaryGuard],
      },
      {
        path: 'request-preventiva',
        loadChildren: () =>
          import('./../request-preventiva/request-preventiva.module').then(
            (m) => m.RequestPreventivaModule
          ),
        canActivate: [SecretaryGuard],
      },
      {
        path: 'requests',
        loadChildren: () =>
          import('./../request/request.module').then((m) => m.RequestModule),
        canActivate: [CitizenGuard],
      },
      {
        path: 'request-secretary',
        loadChildren: () =>
          import('./../request-secretary/request-secretary.module').then(
            (m) => m.RequestSecretaryModule
          ),
        canActivate: [SecretaryGuard],
      },
      {
        path: 'validate-inspector/:idSeguimiento/:nameTramite/:idTipoTramite',
        loadChildren: () =>
          import('./../validate-inspector/validate-inspector.module').then(
            (m) => m.ValidateInspectorModule
          ),
        canActivate: [InspectorGuard],
      },
      {
        path: 'validate-secretary/:idSeguimiento/:nameTramite/:idTipoTramite',
        loadChildren: () =>
          import('./../validate-secretary/validate-secretary.module').then(
            (m) => m.ValidateSecretaryModule
          ),
        canActivate: [SecretaryGuard],
      },
      {
        path: 'settlement-inspectory',
        loadChildren: () =>
          import('./../settlement-inspector/settlement-inspector.module').then(
            (m) => m.SettlementInspectorModule
          ),
        canActivate: [OfficerGuard],
      },
      {
        path: 'validate-request/:idSeguimiento/:nameTramite/:idTipoTramite',
        loadChildren: () =>
          import('./../validate-request/validate-request.module').then(
            (m) => m.ValidateRequestModule
          ),
        canActivate: [OfficerGuard],
      },
      {
        path: 'request-inspected/:idSeguimiento/:nameTramite/:idTipoTramite',
        loadChildren: () =>
          import('./../validate-inspected/validate-inspected.module').then(
            (m) => m.ValidateInspectedModule
          ),
        canActivate: [OfficerGuard],
      },
      {
        path: 'cargos',
        loadChildren: () =>
          import('./../other-charges/other-charges.module').then(
            (m) => m.OtherChargesModule
          ),
        canActivate: [OfficerGuard],
      },
      {
        path: 'status-display-officer',
        loadChildren: () =>
          import('./../status-display-officer/status-display-officer.module').then(
            (m) => m.StatusDisplayOfficerModule
          ),
        canActivate: [OfficerDirectorSecretary],
      },
      {
        path: 'report-display-officer',
        loadChildren: () =>
          import('./../report-display-officer/report-display-officer.module').then(
            (m) => m.ReportDisplayOfficerModule
          ),
        canActivate: [OfficerDirectorSecretary],
      },
      {
        path: 'request-pending-payment',
        loadChildren: () =>
          import('./../request-pending-payment/request-pending-payment.module').then(
            (m) => m.RequestPendingPaymentModule
          ),
        canActivate: [OfficerGuard],
      },
      {
        path: 'solvent-end/:idSeguimiento/:cveCatastral/:folio/:idTipoTramite',
        loadChildren: () =>
          import('./../citizen/solvent-end/solvent-end.module').then(
            (m) => m.SolventEndModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'owner-request-form/:cveCatastral/:folio/:idTipoTramite/:idPerson',
        loadChildren: () =>
          import('./../citizen/personal-questions/owner/owner.module').then(
            (m) => m.OwnerModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'legal-representative-form/:cveCatastral/:folio/:idTipoTramite/:idPerson',
        loadChildren: () =>
          import('./../citizen/personal-questions/legal-representative/legal-representative.module').then(
            (m) => m.LegalRepresentativeModule
          ),
        canActivate: [CitizenGuard],
      },
      {
        path: 'config-status',
        loadChildren: () =>
          import('./../config-status/config-status.module').then(
            (m) => m.ConfigStatusModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'menus',
        loadChildren: () =>
          import('./../admin/list-menu/list-menu.module').then((m) => m.ListMenuModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'create-menu',
        loadChildren: () =>
          import('./../admin/create-menu/create-menu.module').then((m) => m.CreateMenuModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'edit-menu/:idMenu',
        loadChildren: () =>
          import('./../admin/edit-menu/edit-menu.module').then((m) => m.EditMenuModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'roles',
        loadChildren: () =>
          import('./../admin/roles/roles.module').then((m) => m.RolesModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'create-role',
        loadChildren: () =>
          import('./../admin/create-role/create-role.module').then((m) => m.CreateRoleModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'edit-role/:idRole',
        loadChildren: () =>
          import('./../admin/edit-role/edit-role.module').then((m) => m.EditRoleModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'update-document/:idRequest/:idDocument',
        loadChildren: () =>
          import('./../admin/update-document/update-document.module').then((m) => m.UpdateDocumentModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'create-UMA',
        loadChildren: () =>
          import('./../admin/uma/create-uma/create-uma.module').then((m) => m.CreateUmaModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'create-calculation',
        loadChildren: () =>
          import('./../admin/costs-procedure/execute-calculation/execute-calculation.module').then((m) => m.ExecuteCalculationModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'add-factor',
        loadChildren: () =>
          import('./../admin/procedure-factor/add-factor/add-factor.module').then((m) => m.AddFactorModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'list-factor',
        loadChildren: () =>
          import('./../admin/procedure-factor/list-factors/list-factors.module').then((m) => m.ListFactorsModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'list-cost-procedure',
        loadChildren: () =>
          import('./../admin/costs-procedure/list-cost-procedure/list-cost-procedure.module').then((m) => m.ListCostProcedureModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'list-uma',
        loadChildren: () =>
          import('./../admin/uma/list-uma/list-uma.module').then((m) => m.ListUmaModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'request-director',
        loadChildren: () =>
          import('./../request-director/request-director.module').then((m) => m.RequestDirectorModule),
        canActivate: [DirectorGuard],
      },
      {
        path: 'email-templates',
        loadChildren: () =>
          import(
            './../admin/email-templates/email-templates.module'
          ).then((m) => m.EmailTemplatesModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'create-email-template',
        loadChildren: () =>
          import(
            './../admin/create-email-template/create-email-template.module'
          ).then((m) => m.CreateEmailTemplateModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'edit-email-template/:idTemplate',
        loadChildren: () =>
          import(
            './../admin/edit-email-template/edit-email-template.module'
          ).then((m) => m.EditEmailTemplateModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'reports',
        loadChildren: () =>
          import(
            './../reports/reports.module'
          ).then((m) => m.ReportsModule),
        canActivate: [SecretaryGuard],
      },
      {
        path: 'payment-methods',
        loadChildren: () =>
          import('./../admin/payment-methods/edit-payment-methods/edit-payment-methods.module').then((m) => m.EditPaymentMethodsModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'signature',
        loadChildren: () =>
          import('./../admin/signatures/signature.module').then((m) => m.SignatureModule),
        canActivate: [AdminGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
