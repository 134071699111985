import { Observable } from 'rxjs';
import { ListMenuRequestModel, ListMenuResponseModel } from '../model/MenusModel';
import { FetchUserMenuResponseDTO } from '../../data/dto/MenuDTO';
import { ListMenuRequestDTO,
        ListMenuResponseDTO,
        CreateMenuRequestDTO,
        CreateMenuResponseDTO,
        FetchMenuResponseDTO,
        UpdateMenuRequestDTO,
        UpdateMenuResponseDTO,
        DeleteMenuResponseDTO } from '../../data/dto/MenuDTO';

export abstract class MenuRepository {
    abstract listMenu(menu: ListMenuRequestModel): Observable<ListMenuResponseModel>;
    abstract list(params: ListMenuRequestDTO): Observable<ListMenuResponseDTO>;
    abstract create(menu: CreateMenuRequestDTO): Observable<CreateMenuResponseDTO>;
    abstract fetch(menuId: number): Observable<FetchMenuResponseDTO>;
    abstract update(menu: UpdateMenuRequestDTO): Observable<UpdateMenuResponseDTO>;
    abstract delete(menuId: number): Observable<DeleteMenuResponseDTO>;
    abstract fetchUserMenu(): Observable<FetchUserMenuResponseDTO>;
}
