import { StatusDTO } from './StatusDTO';
import { StatusModel } from 'src/app/core/model/StatusModel';
import { Mapper } from 'src/app/core/base/Mapper';

export class StatusMapper implements Mapper<StatusDTO, StatusModel> {
    mapFrom(dto: StatusDTO): StatusModel {
        const model: StatusModel = {
            id: dto.id,
            status: dto.chrEstatus,
            bolInicial: dto.bolInicial,
            bolIniciado: dto.bolIniciado,
            bolEnProgreso: dto.bolEnProgreso,
            bolSolventacion: dto.bolSolventacion,
            bolInspeccion: dto.bolInspeccion,
            bolAutorizacion: dto.bolAutorizacion,
            bolPago: dto.bolPago,
            bolFinal: dto.bolFinal,
            bolInspeccionado: dto.bolInspeccionado,
            bolDesistimiento: dto.bolDesistimiento,
            bolDesechado: dto.bolDesechado,
            bolDirector: dto.bolDirector,
            bolPreventivaSec: dto.bolPreventivaSec
        };
        return model;
    }

    mapTo(model: StatusModel): StatusDTO {
        return {
            id: model.id,
            chrEstatus: model.status,
            bolInicial: model.bolInicial,
            bolIniciado: model.bolIniciado,
            bolEnProgreso: model.bolEnProgreso,
            bolSolventacion: model.bolSolventacion,
            bolInspeccion: model.bolInspeccion,
            bolAutorizacion: model.bolAutorizacion,
            bolPago: model.bolPago,
            bolFinal: model.bolFinal,
            bolInspeccionado: model.bolInspeccionado,
            bolDesistimiento: model.bolDesistimiento,
            bolDesechado: model.bolDesechado,
        };
    }
}
