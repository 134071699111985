const url = "https://backend.ciudadideal.gestion-territorial.com";
const cajavirtual_url = "https://...cajavirtualdemo.escobedo.gob.mx/paynet.aspx";
const cajavirtual_origen = "2";
const mapa_url = "https://visorurbano.monterrey.gob.mx/v2/";
const logoBlanco_header = "/assets/ciudad-ideal.png";
const logo_portal = "/assets/monterrey/Escudo-Horizontal.png";
const name_app = "";
const app_logo_name = "/assets/ciudad-ideal.png";
const color_header = '#929292';
const background_sidebar_header = "#C9C9C9";
const background_menu = 'darken(#636571, 15%)';
const background_title_section_form = "#E5E5E5";
const sidebar_escobedo = false;
const environment_escobedo = false;
const environment_monterrey = true;
const app_title = "Ciudad Ideal";
const urlTerminos = "/assets/docs/terminosycondiciones.pdf";
const urlFormatoPreventiva = '/assets/docs/formato_contestacion_preventiva.pdf';
export const environment = {
  env: "cliente",
  production: false,
  url_base: `${url}/api`,
  url: `${url}`,
  cajavirtual_origen,
  cajavirtual_url,
  mapa_url,
  logoBlanco_header,
  logo_portal,
  name_app,
  app_logo_name,
  color_header,
  background_sidebar_header,
  background_menu,
  sidebar_escobedo,
  environment_escobedo,
  app_title,
  urlTerminos,
  urlFormatoPreventiva,
  background_title_section_form,
  environment_monterrey,
  login_endpoint: "user/login",
  loginSignature_endpoint: 'user/loginSignature',
  create_user_endpoint: "user/create",
  recover_user_endpoint: "user/recovery",
  requests_endpoint: "seguimientos/list",
  request_type_endpoint: "tramites/list",
  validate_key_endpoint: "seguimientos/verifica",
  messages_endpoint: "mensajes/list",
  upload_files_endpoint: "documentos/upload",
  send_files_endpoint: "seguimientos/datosPersonales",
  download_file_endpoint: "documentos/download",
  download_acuse_endpoint: 'documentos/downloadAcuse',
  requirement_pdf_endpoint: "pdf/createpdf",
  start_request_endpoint: "seguimientos/iniciar",
  document_list_endpoint: "documentos/list",
  document_listAll_endpoint: "documentos/listAll",
  document_findOne_endpoint: 'documentos/findOne',
  document_listDictamen_endpoint: 'documentos/listDictamen',
  document_refuseHistorial_endpoint: "documentos/refuseHistorial",
  request_exist_files_endpoint: "documentos/listDocumentosExistentes",
  request_tramites_endpoint: 'tramites/listAll',
  send_request_endpoint: "seguimientos/enviar",
  send_desist_request_endpoint: 'seguimientos/enviarDesist',
  send_discard_request_endpoint: 'seguimientos/enviarDiscard',
  get_request_endpoint: "seguimientos/fetch",
  create_related_seguimiento: 'seguimientos/fetchRelatedSeguimiento',
  validate_document_endpoint: "seguimientos/validaDocumentos",
  refuse_document_endpoint: "documentos/rechazar",
  approve_document_endpoint: "documentos/aceptar",
  refuse_request_endpoint: "seguimientos/rechazar",
  approve_request_endpoint: "seguimientos/aceptar",
  firma_preventiva_request_endpoint: 'seguimientos/firmaPreventiva',
  pdf_acuse_endpoint: "pdf/createpdfacuse",
  pdf_acuse_desist_endpoint: 'pdf/createpdfacusedesist',
  preventiva_vista_endpoint: 'pdf/createpdfacusevisual',
  preventiva_no_vista_endpoint: 'pdf/createpdfacusenovisual',
  preventiva_vista_message_endpoint: 'seguimientos/visualizacion',
  preventiva_vista_preventiva_endpoint: 'seguimientos/visualizacionPreventiva',
  preventiva_no_vista_message_endpoint: 'seguimientos/novisualizacion',
  messages_view_endpoint: "mensajes/marcarVisto",
  field_list_endpoint: "campos/list",
  field_save_endpoint: "campos/save",
  field_create_endpoint: "campos/crear",
  roles_list_endpoint: "roles/list",
  users_list_endpoint: "user/list",
  user_update_endpoint: "user/update",
  user_fetch_endpoint: "user/fetch",
  user_tramites_endpoint: "user/listTramitesByUser",
  fields_catalogue_endpoint: "campos/listaCatalogo",
  documents_catalogue_endpoint: "documentos/listaCatalogo",
  document_create_endpoint: "documentos/crear",
  document_update_endpoint: "documentos/update",
  document_fetch_endpoint: "documentos/fetch",
  field_update_endpoint: "campos/update",
  field_fetch_endpoint: "campos/fetch",
  fetch_related_procedure_endpoint: 'tramites/fetchRelatedProcedure',
  related_procedure_endpoint: 'tramites/crearRelacionTramite',
  list_related_procedure_endpoint: 'tramites/listRelacionTramite',
  request_create_endpoint: "tramites/crear",
  request_update_endpoint: "tramites/update",
  field_delete_endpoint: "campos/eliminar",
  document_delete_endpoint: "documentos/eliminar",
  request_delete_endpoint: "tramites/eliminar",
  document_upload_format_endpoint: "documentos/cargaFormato",
  document_uploadDocR_endpoint: "documentos/uploadDocR",
  request_fetch_endpoint: "tramites/fetch",
  matrix_request_endpoint: "matrizTramite/crear",
  matrix_field_endpoint: "matrizCampos/crear",
  delete_matrix_request_endpoint: "matrizTramite/eliminar",
  delete_matrix_field_endpoint: "matrizCampos/eliminar",
  edit_matrix_field_endpoint: "matrizCampos/editar",
  fetch_matrix_field: "matrizCampos/fetch",
  create_tax_data_endpoint: "datosFiscales/crear",
  upload_files_tax_data_endpoint: "datosFiscales/file",
  tax_data_list_endpoint: "datosFiscales/list",
  payment_request: "tramites/coutatramite",
  paid_request: "tramites/pay",
  update_request_endpoint: "seguimientos/actualiza",
  cancel_request_endpoint: 'seguimientos/cancela',
  desistir_request_endpoint: 'seguimientos/desistir',
  desechar_request_endpoint: 'seguimientos/desechar',
  download_dictamen_endpoint: "seguimientos/dictamen",
  list_dictamen_endpoint: 'seguimientos/dictamenByUser',
  upload_inspector_endpoint: "documentos/uploadDictamen",
  send_support_message: "email/sendSupportMessage",
  accept_terms: "user/acceptTerms",
  accept_terms_request: "seguimientos/acceptTerms",
  town_id: 1,
  profile: "profile",
  status_list: "estatus/list",
  status_create: "estatus/crear",
  menu_endpoint: "menu",
  role_endpoint: "roles",
  matrix_request_update_endpoint: "matrizTramite/update",
  matrix_request_fetch_endpoint: "matrizTramite/fetch",
  extensiones_endpoint: "extensiones",
  center_income_endpoint: "otrosCargos/centroIngresos",
  income_concepts_endpoint: "otrosCargos/conceptosIngresos",
  other_charges_endpoint: "otrosCargos",
  zone_list: 'zonas/list',
  zone_by_user: 'user/listZonasByUser',
  email_list_template_endpoint: 'email/listTemplate',
  email_create_template_endpoint: 'email/createTemplate',
  email_find_template_endpoint: 'email/findTemplateById',
  email_edit_template_endpoint: 'email/updateTemplate',
  email_delete_template_endpoint: 'email/deleteTemplate',
  request_report_endpoint: 'seguimientos/report',
  payment_methods_endpoint: 'formaspago/list',
  update_payment_methods_endpoint: 'formaspago/update',
  dowload_referencia_pago: 'formaspago/createpdfpayment',
  municipio_endpoint: 'municipios/list',
  update_municipio_endpoint: 'municipios/update',
  history_endpoint: 'seguimientos/historial',
  pdf_history_endpoint: 'pdf/createpdfhistorial',
};
