import { Observable } from 'rxjs';
import { RelatedProceduresDTO } from 'src/app/data/dto/RequestListDTO';
import { RequestListRequestModel } from '../model/RequestListModel';


export abstract class RelatedProcedureRepository {
    abstract fetch(request: any): Observable<any>;
    abstract update(request: RelatedProceduresDTO): Observable<any>;
    abstract create(request: RelatedProceduresDTO): Observable<any>;
    abstract list(request: RequestListRequestModel): Observable<any>;


}
