import { MessageRepository } from 'src/app/core/repositories/MessageRepository';
import {
    MessageRequestModel,
    MessageResponseModel
} from 'src/app/core/model/MessageModel';
import { Observable, throwError } from 'rxjs';
import { MessageResponseMapper, MessageRequestMapper } from 'src/app/data/dto/MessageMapper';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MessageResponseDTO } from 'src/app/data/dto/MessageDTO';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MessageRestRepository implements MessageRepository {
    private headers: HttpHeaders;
    mapper = new MessageResponseMapper();
    messageMapper = new MessageRequestMapper();

    constructor(private http: HttpClient, private authService: AuthService) {
        let token = this.authService.currentUserValue.token
        this.headers = new HttpHeaders({
            'Content-Type': "application/json",
            'Authorization': "Bearer "+ token
        });
    }

    fetch(message: MessageRequestModel): Observable<MessageResponseModel> {
        let endpoint = environment.messages_endpoint;
        let messageDTO: any = this.messageMapper.mapTo(message);
        let params = new HttpParams({
            fromObject: messageDTO
        })
        let httpOptions = { headers: this.headers, params: params };
        return this.http
            .get<MessageResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(this.mapper.mapFrom));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
