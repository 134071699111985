import { Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';
import { Localization } from '../../localization';
declare var $: any;

@Component({
  selector: 'app-modal-close-session',
  templateUrl: './modal-close-session.component.html',
  styleUrls: ['./modal-close-session.component.scss']
})
export class ModalCloseSessionComponent implements OnInit {
  localization: Localization;

  constructor(
    localization: Localization,
    private router: Router,
    private auth: AuthService,
  ) {
    this.localization = localization;
  }

  ngOnInit() {
   this.auth.currentUser.subscribe( currentUser => {
     if (!currentUser) {
      this.showModal();
     }
   } ) 
  }

  showModal() {
    if (localStorage.getItem('logged') !== 'false') {
      $('#ModalCloseSession').modal();
    }
  }

  goLogin(): void {
    this.router.navigate(['/login']);
  }

}
