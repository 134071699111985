import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './component/header/header.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { FooterComponent } from './component/footer/footer.component';
import { ProfileCardComponent } from './component/profile-card/profile-card.component';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './component/layout/layout.component';
import { SharedRoutingModule } from './shared-routing.module';
import { SidebarFooterComponent } from './component/sidebar-footer/sidebar-footer.component';
import { ModalCloseSessionComponent } from './component/modal-close-session/modal-close-session.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastGlobalModule } from '../toast-global/toast-global.module';
@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    ProfileCardComponent,
    SidebarFooterComponent,
    ModalCloseSessionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedRoutingModule,
    NgbModule,
    ToastGlobalModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
  ]
})
export class SharedModule { }
