import { DocumentRepository } from 'src/app/core/repositories/DocumentRepository';
import { DocumentListRequestDTO,
            DocumentListResponseDTO,
            DocumentResponseListDTO,
            DocumentRefuseRequestDTO,
            DocumentApproveRequestDTO } from 'src/app/data/dto/DocumentListDTO';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';
import { DocumentCatalogueRequestModel, DocumentCatalogueResponseModel } from '../../core/model/DocumentModel';
import { DocumentRequestMapper, DocumentResponseMapper } from '../dto/DocumentMapper';
import { DocumentCreateDTO, DocumentCreateResponseDTO } from '../dto/DocumentCreateDTO';
import { DocumentUpdateDTO, DocumentUpdateResponseDTO, DocumentFetchRequestDTO, DocumentFetchResponseDTO } from '../dto/DocumentUpdateDTO';
import { DocumentDeleteResponseDTO, DocumentDeleteDTO } from '../dto/DocumentDeleteDTO';
import { UploadFormatDTO, UploadFormatResponseDTO } from '../dto/DocumentUploadFormat';
import { DocumentsFetchListResponseDTO, DocumentsFetchListRequestDTO } from '../dto/DocumentsFetchListDTO';
import { ListDocumentTypesResponse } from '../dto/DocumentDTO';
import { DocumentsExistListResponseDTO, DocumentsExistListRequestDTO, DocumentsDictamenListRequestDTO, DictamenByOfficerListRequestDTO, DictamenByOfficerListResponseDTO } from '../dto/DocumentExistListDTO'
import { RequestModel, RequestResponseModel} from 'src/app/core/model/RequestHistoryModel';
import { RequestResponseMapper,RequestMapper } from "src/app/data/dto/RequestHistoryMapper";
import { RequestResponseDTO, RequestDTO } from '../dto/RequestHistoryDTO';

@Injectable({
    providedIn: 'root'
})
export class DocumentRestRepository implements  DocumentRepository {
    private headers: HttpHeaders;
    mapper = new DocumentResponseMapper();
    documentsCatalogueMapper = new DocumentRequestMapper();
    mapperHistory = new RequestResponseMapper();
    requestMapper = new RequestMapper();


    constructor(private http: HttpClient, private authService: AuthService) {
        const token = this.authService.currentUserValue.token;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        });
    }

    list(document: DocumentListRequestDTO): Observable<DocumentListResponseDTO> {
        const endpoint = environment.document_list_endpoint;
        const params = new HttpParams({
            fromObject: {
                idMunicipio:  document.idMunicipio.toString(),
                idTramite: document.idTramite.toString(),
                idSeguimiento: document.idSeguimiento.toString()
            }
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<DocumentListResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    refuse(document: DocumentRefuseRequestDTO): Observable<DocumentResponseListDTO> {
        const endpoint = environment.refuse_document_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .put<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(document), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    approve(document: DocumentApproveRequestDTO): Observable<DocumentResponseListDTO> {
        const endpoint = environment.approve_document_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .put<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(document), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    catalogue(document: DocumentCatalogueRequestModel): Observable<DocumentCatalogueResponseModel> {
        const endpoint = environment.documents_catalogue_endpoint;
        const docsDTO: any = this.documentsCatalogueMapper.mapTo(document);
        const params = new HttpParams({
            fromObject: docsDTO
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<DocumentCatalogueResponseModel>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(this.mapper.mapFrom));
    }

    create(document: DocumentCreateDTO): Observable<DocumentCreateResponseDTO> {
        const endpoint = environment.document_create_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.post<DocumentCreateResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(document), httpOptions)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }

    update(document: DocumentUpdateDTO): Observable<DocumentUpdateResponseDTO> {
        const endpoint = environment.document_update_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .put<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(document), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    fetch(document: DocumentFetchRequestDTO): Observable<DocumentFetchResponseDTO> {
        const endpoint = environment.document_fetch_endpoint;
        const params = new HttpParams({
            fromObject: {
                id:  document.id.toString(),
            }
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<DocumentFetchResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    upload(file: UploadFormatDTO): Observable<UploadFormatResponseDTO> {
        const endpoint = environment.document_upload_format_endpoint;
        const uploadHeader = new HttpHeaders({
            Authorization: 'Bearer ' + this.authService.currentUserValue.token
        });
        const httpOptions = { headers: uploadHeader };
        const formData = new FormData();
        formData.append('fileToUpload', file.fileToUpload);
        return this.http.post<UploadFormatResponseDTO>(`${environment.url_base}/${endpoint}`, formData, httpOptions)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }

    fieldsList(document: DocumentsFetchListRequestDTO): Observable<DocumentsFetchListResponseDTO> {
        const endpoint = environment.documents_catalogue_endpoint;
        const params = new HttpParams({
            fromObject: {
                idMunicipio: document.idMunicipio.toString(),
            }
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<DocumentsFetchListResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    delete(documentID: DocumentDeleteDTO): Observable<DocumentDeleteResponseDTO> {
        const endpoint = environment.document_delete_endpoint;
        const params = new HttpParams({
            fromObject: {
                id:  documentID.id.toString(),
            }
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .delete<DocumentDeleteResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    listDocumentTypes(): Observable<ListDocumentTypesResponse> {
      const endpoint = environment.extensiones_endpoint;
      const httpOptions = { headers: this.headers };
      return this.http
        .get<ListDocumentTypesResponse>(
          `${environment.url_base}/${endpoint}`,
          httpOptions
        )
        .pipe(
          retry(1),
          catchError(this.handleError)
        )
        .pipe(map((result) => {
            return result;
        }));
    }


    existList(document: DocumentsExistListRequestDTO): Observable<DocumentsExistListResponseDTO> {
      const endpoint = environment.document_listAll_endpoint;
      const params = new HttpParams({
          fromObject: {
              userId: document.userId.toString(),
              documentId: document.documentId.toString()
          }
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
          .get<DocumentsExistListResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
          .pipe(
              catchError(this.handleError)
          )
          .pipe(map(result => {
              return result;
          }));
    }

    existOne(document: DocumentsExistListRequestDTO): Observable<DocumentsExistListResponseDTO> {
      const endpoint = environment.document_findOne_endpoint;
      const params = new HttpParams({
          fromObject: {
              userId: document.userId.toString(),
              documentId: document.documentId.toString()
          }
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
          .get<DocumentsExistListResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
          .pipe(
              catchError(this.handleError)
          )
          .pipe(map(result => {
              return result;
          }));
    }

    dictamenList(document: DocumentsDictamenListRequestDTO): Observable<DocumentsDictamenListRequestDTO> {
      const endpoint = environment.document_listDictamen_endpoint;
      const params = new HttpParams({
          fromObject: {
              userId: document.userId.toString(),
              documentId: document.documentId.toString()
          }
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
          .get<any>(`${environment.url_base}/${endpoint}`, httpOptions)
          .pipe(
              catchError(this.handleError)
          )
          .pipe(map(result => {
              return result;
          }));
    }

    dictamenByOfficerList(document: DictamenByOfficerListRequestDTO): Observable<DictamenByOfficerListResponseDTO> {
      const endpoint = environment.list_dictamen_endpoint;
      const params = new HttpParams({
          fromObject: {
              userId: document.userId.toString()
          }
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
          .get<DictamenByOfficerListResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
          .pipe(
              catchError(this.handleError)
          )
          .pipe(map(result => {
              return result;
          }));
    }

    refuseList(request: RequestModel): Observable<RequestResponseModel> {
      const endpoint = environment.document_refuseHistorial_endpoint;
      const requestDTO: any = this.requestMapper.mapTo(request);
      const params = new HttpParams({
        fromObject: requestDTO,
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
          .get<RequestResponseModel>(`${environment.url_base}/${endpoint}`, httpOptions)
          .pipe(retry(1), catchError(this.handleError))
          .pipe(map(this.mapperHistory.mapFrom));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
