import { Mapper } from 'src/app/core/base/Mapper';
import { DocumentCatalogueRequestDTO, DocumentCatalogueResponseDTO } from './DocumentDTO';
import { DocumentCatalogueRequestModel, DocumentCatalogueResponseModel } from 'src/app/core/model/DocumentModel';
import { DataTableHelper } from './DataTableHelper';

export class DocumentResponseMapper implements Mapper<DocumentCatalogueResponseDTO, DocumentCatalogueResponseModel> {

    mapFrom(dto: DocumentCatalogueResponseDTO): DocumentCatalogueResponseModel {
        const model = {
            result: dto.result,
            documentos: dto.documentos.map( item => {
                return {
                    id: item.id,
                    chrDocumento: item.chrDocumento,
                    chrPathFormato: item.chrPathFormato,
                    intOrden: item.intOrden,
                    boolPreguntaPersonal: item.boolPreguntaPersonal,
                    boolEsGeneral: item.boolEsGeneral,
                    intFKIDMunicipio: item.intFKIDMunicipio
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: DocumentCatalogueResponseModel): DocumentCatalogueResponseDTO {
        return {
            result: model.result,
            documentos: null
        };
    }
}

export class DocumentRequestMapper implements Mapper<DocumentCatalogueRequestDTO, DocumentCatalogueRequestModel> {
    mapFrom(dto: DocumentCatalogueRequestDTO): DocumentCatalogueRequestModel {
        const model: DocumentCatalogueRequestModel = {
            idTown: dto.idMunicipio,
            token: '',
            dataTableParams: null
        };
        return model;
    }

    mapTo(model: DocumentCatalogueRequestModel): DocumentCatalogueRequestDTO {
        return {
            idMunicipio: model.idTown,
            token: '',
            draw: model.dataTableParams.draw,
            length: model.dataTableParams.length,
            start: model.dataTableParams.start,
            search: model.dataTableParams.search.value,
            order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}
