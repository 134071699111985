import { FieldRepository } from '../../core/repositories/FieldRepository';
import { FieldListRequestDTO, FieldListResponseDTO, FieldSaveResponseDTO, FieldSaveRequestDTO} from '../dto/FieldsListDTO';
import { FieldsCatalogueRequestModel, FieldsCatalogueResponseModel } from '../../core/model/FieldsCatalogueModel';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/service/auth.service';
import { FieldsCatalogueRequestMapper, FieldsCatalogueResponseMapper } from '../dto/FieldsCatalogueMapper';
import { FieldCreateDTO, FieldCreateResponseDTO } from '../dto/FieldCreateDTO';
import {    FieldUpdateDTO,
            FieldUpdateResponseDTO,
            FieldFetchRequestDTO,
            FieldFetchResponseDTO   } from '../dto/FieldUpdateDTO';
import { FieldDeleteResponseDTO, FieldDeleteDTO } from '../dto/FieldDeleteDTO';
import { FieldsFetchListRequestDTO, FieldsFetchListResponseDTO } from '../dto/FieldFetchListDTO';
import { MatrixFieldCreateResponseDTO, MatrixFieldCreateDTO } from '../dto/MatrixFieldCreateDTO';
import { MatrixFieldDeleteDTO, MatrixFieldDeleteResponseDTO } from '../dto/MatrixFieldDeleteDTO';
import { MatrixFieldEditResponseDTO, MatrixFieldEditDTO, ProfileListResponseDTO } from '../dto/MatrixFieldEditDTO';
import { FetchMatrixFieldDTO, FetchMatrixFieldResponseDTO } from '../dto/MatrixFieldEditDTO';
import { RequestFetchRequestDTO, RequestFetchResponseDTO } from 'src/app/data/dto/RequestFetchDTO';

@Injectable({
    providedIn: 'root'
})
export class FieldRestRepository implements  FieldRepository {
    private headers: HttpHeaders;
    mapper = new FieldsCatalogueResponseMapper();
    fieldsCatalogueMapper = new FieldsCatalogueRequestMapper();

    constructor(private http: HttpClient, private authService: AuthService) {
        const token = this.authService.currentUserValue.token;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        });
    }

    list(field: FieldListRequestDTO): Observable<FieldListResponseDTO> {
        const endpoint = environment.field_list_endpoint;
        const params = new HttpParams({
            fromObject: {
                idMunicipio:  field.idMunicipio.toString(),
                idTramite: field.idTramite.toString(),
                idSeguimiento: field.idSeguimiento.toString(),
                bolCiudadano: field.bolCiudadano,
                bolInspector: field.bolInspector,
                bolSecretario: field.bolSecretario,
                bolFuncionario: field.bolFuncionario,

            }
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<FieldListResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    listFields(field: RequestFetchRequestDTO): Observable<RequestFetchResponseDTO> {
      const endpoint = environment.request_fetch_endpoint;
      const params = new HttpParams ({
        fromObject: {
          bolCiudadano: field.bolCiudadano,
          bolInspector: field.bolInspector,
          bolSecretario: field.bolSecretario,
          bolFuncionario: field.bolFuncionario,
        }
      });
      const httpOptions = { headers: this.headers, params };
      return this.http
            .get<RequestFetchResponseDTO>(`${environment.url_base}/${endpoint}/${field.id}`, httpOptions)
          .pipe(
              catchError(this.handleError)
          )
          .pipe(map(result => {
              return result;
          }));
    }

    getProfile(): Observable<ProfileListResponseDTO> {
        const endpoint = environment.profile;
        const httpOptions = { headers: this.headers };
        return this.http
              .get<ProfileListResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
      }


    save(field: FieldSaveRequestDTO): Observable<FieldSaveResponseDTO> {
        const endpoint = environment.field_save_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.post<FieldSaveResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(field), httpOptions)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }

    catalogue(field: FieldsCatalogueRequestModel): Observable<FieldsCatalogueResponseModel> {
        const endpoint = environment.fields_catalogue_endpoint;
        const fieldsDTO: any = this.fieldsCatalogueMapper.mapTo(field);
        const params = new HttpParams({
            fromObject: fieldsDTO
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<FieldsCatalogueResponseModel>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(this.mapper.mapFrom));
    }

    create(field: FieldCreateDTO): Observable<FieldCreateResponseDTO> {
        const endpoint = environment.field_create_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.post<FieldCreateResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(field), httpOptions)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }

    update(field: FieldUpdateDTO): Observable<FieldUpdateResponseDTO> {
        const endpoint = environment.field_update_endpoint;
        const httpOptions = { headers: this.headers};
        return this.http
            .put<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(field), httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    fetch(field: FieldFetchRequestDTO): Observable<FieldFetchResponseDTO> {
        const endpoint = environment.field_fetch_endpoint;
        const params = new HttpParams({
            fromObject: {
                id:  field.id.toString(),
            }
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<FieldFetchResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    fieldsList(field: FieldsFetchListRequestDTO): Observable<FieldsFetchListResponseDTO> {
        const endpoint = environment.fields_catalogue_endpoint;
        const params = new HttpParams({
            fromObject: {
                idMunicipio: field.idMunicipio.toString(),
            }
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<FieldsFetchListResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    delete(fieldID: FieldDeleteDTO): Observable<FieldDeleteResponseDTO> {
        const endpoint = environment.field_delete_endpoint;
        const params = new HttpParams({
            fromObject: {
                id:  fieldID.id.toString(),
            }
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .delete<FieldFetchResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    createMatrix(field: MatrixFieldCreateDTO): Observable<MatrixFieldCreateResponseDTO> {
        const endpoint = environment.matrix_field_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.post<MatrixFieldCreateResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(field), httpOptions)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }

    deleteMatrix(field: MatrixFieldDeleteDTO): Observable<MatrixFieldDeleteResponseDTO> {
        const endpoint = environment.delete_matrix_field_endpoint;
        const idRequest = field.idTramite;
        const idField = field.idCampo;
        const httpOptions = { headers: this.headers };
        return this.http
        .delete<MatrixFieldDeleteResponseDTO>(`${environment.url_base}/${endpoint}/${idRequest}/${idField}`, httpOptions)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }

    editMatrix(field: MatrixFieldEditDTO): Observable<MatrixFieldEditResponseDTO> {
        const endpoint = environment.edit_matrix_field_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.put<any>(`${environment.url_base}/${endpoint}`, JSON.stringify(field), httpOptions)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }

    fetchMatrixField(field: FetchMatrixFieldDTO): Observable<FetchMatrixFieldResponseDTO> {
        const endpoint = environment.fetch_matrix_field;
        const params = new HttpParams({
            fromObject: {
                intFKIDTramite: field.intFKIDTramite.toString(),
                intFKIDCampos:  field.intFKIDCampos.toString()
            }
        });
        const httpOptions = { headers: this.headers, params };
        return this.http
            .get<FetchMatrixFieldResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
