import { LicenseTypeRepository } from 'src/app/core/repositories/LicenseTypeRepository';
import {
    LicenseTypeRequestModel,
    LicenseTypeResponseModel
} from 'src/app/core/model/LicenseTypeModel';
import { Observable, throwError } from 'rxjs';
import { LicenseTypeResponseMapper, LicenseTypeRequestMapper } from 'src/app/data/dto/LicenseTypeMapper';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { LicenseTypeResponseDTO } from 'src/app/data/dto/LicenseTypeDTO';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
    providedIn: 'root'
})
export class LicenseTypeRestRepository implements LicenseTypeRepository {
    private headers: HttpHeaders;
    mapper = new LicenseTypeResponseMapper();
    licenseMapper = new LicenseTypeRequestMapper();

    constructor(private http: HttpClient, private authService: AuthService) {
        let token = this.authService.currentUserValue.token
        this.headers = new HttpHeaders({
            'Content-Type': "application/json",
            'Authorization': "Bearer "+ token
        });
    }

    fetch(request: LicenseTypeRequestModel): Observable<LicenseTypeResponseModel> {
        let endpoint = environment.request_type_endpoint;
        let licenseDTO: any = this.licenseMapper.mapTo(request);
        let params = new HttpParams({
            fromObject: licenseDTO
        });
        let httpOptions = { headers: this.headers, params: params};
        return this.http
            .get<LicenseTypeResponseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                catchError(this.handleError)
            )
            .pipe(map(this.mapper.mapFrom));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
