import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuRepository } from '../../repositories/MenuRepository';
import { FetchUserMenuResponseDTO } from '../../../data/dto/MenuDTO';

@Injectable({
    providedIn: 'root'
})
export class UserMenuFetchUseCase implements UseCase<string, FetchUserMenuResponseDTO> {
    constructor(
        private menuRepository: MenuRepository,
    ) { }

    execute(): Observable<FetchUserMenuResponseDTO> {
      return  this.menuRepository.fetchUserMenu();
    }
}
