// tslint:disable: variable-name
export abstract class Localization {
  status: string;
  label_generales: string;
  label_styles_municipio: string;
  label_color_botones: string;
  label_color_background: string;
  label_color_pie: string;
  label_color_layout: string;
  label_color_menu: string;
  label_color_header: string;
  label_name_municipio: string;
  label_direccion_municipio: string;
  label_url_firma_municipio: string;
  label_url_firma_global: string;
  label_status_official: string;
  label_status_citizen: string;
  title_dual_list_status: string;
  label_status: string;
  label_procedure: string;
  title_dual_list_notification: string;
  title_dual_list_see_status: string;
  title_dual_list_update_status: string;
  title_config_status: string;
  button_edit_doc: string;
  label_warning_close_modal: string;
  label_title_close_modal: string;
  closeModalSession: string;
  label_tittle_document_edit: string;
  label_tittle_document: string;
  label_tittle_request_edit: string;
  label_tittle_request: string;
  label_tittle_user_edit: string;
  label_tittle_users: string;
  label_tittle_field_edit: string;
  label_tittle_fields: string;
  label_cancel_confirm: string;
  label_cancel_confirm_create: string;
  label_tramite_default: string;
  label_preventive: string;
  label_procedure_inspected: string;
  label_edit: string;
  label_procedure_process: string;
  label_admin: string;
  menu: string;
  name_procedure: string;
  label_view_field: string;
  label_capture_field: string;
  label_select: string;
  label_dont_select: string;
  label_suffix: string;
  label_prefix: string;
  label_alignment: string;
  label_type_field: string;
  label_type_file: string;
  label_no_confirm: string;
  label_confirmation: string;
  label_confirmation_create: string;
  app_title: string;
  app_credentialerror: string;
  login_title: string;
  login_email: string;
  login_password: string;
  login_create: string;
  login_forgotpasword: string;
  login_login: string;
  register_title: string;
  register_name: string;
  register_firstname: string;
  register_lastname: string;
  register_phone: string;
  register_confirmpassword: string;
  register_register: string;
  register_required: string;
  register_requireda: string;
  register_onlynumber: string;
  register_mintendigits: string;
  register_correctemail: string;
  register_correctcurp: string;
  register_msgminpassword: string;
  register_msgconfirmpassword: string;
  register_usersucess: string;
  register_usermessage: string;
  register_linkcontinue: string;
  register_linkreturn: string;
  recovery_title: string;
  recovery_subtitle: string;
  recovery_send: string;
  recovery_sendSuccess: string;
  recovery_sendError: string;
  recovery_emailnotfound: string;
  menu_hidden: string;
  menu_general: string;
  menu_title: string;
  menu_requests: string;
  menu_mylicense: string;
  menu_mymessage: string;
  menu_newtransact: string;
  menu_status_visualizer: string;
  menu_historial: string;
  menu_status_pending_payment: string;
  menu_settlement: string;
  menu_settlement_inspectory: string;
  menu_requests_official: string;
  menu_finished_requests: string;
  menu_inspector_requests: string;
  menu_secretary_requests: string;
  menu_secretary_requests_discard: string;
  menu_director_requests: string;
  menu_users: string;
  menu_admin: string;
  menu_admin_requests: string;
  menu_fields: string;
  menu_documents: string;
  menu_admin_cost_request: string;
  menu_admin_factor: string;
  menu_admin_UMA: string;
  menu_admin_Payment_Methods: string;
  menu_admin_signature: string;
  menu_desist: string;
  menu_preventiva: string;
  menu_payment_methods: string;
  menu_settings_municipio: string;
  menu_related_procedure: string;
  menu_discard: string;
  menu_email_templates: string;
  home_notification: string;
  home_footer: string;
  transacts_cve1: string;
  transacts_cve2: string;
  transacts_cve3: string;
  transacts_cve4: string;
  transacts_cve5: string;
  transacts_cve6: string;
  transacts_cve7: string;
  transacts_cve8: string;
  transacts_cve9: string;
  transacts_cve10: string;
  transacts_cve11: string;
  transacts_cve67: string;
  transacts_cve68: string;
  transacts_cve69: string;
  transacts_cve70: string;
  transacts_cve71: string;
  transacts_cve77: string;
  transacts_cve79: string;
  transacts_cve80: string;
  error_404: string;
  error_404_title: string;
  menu_town_name: string;
  requests_title: string;
  requests_preventiva_title: string;
  requests_user: string;
  requests_folio: string;
  requests_expediente: string;
  requests_folio_procedure: string;
  requests_street: string;
  requests_number: string;
  requests_suburb: string;
  requests_procedure: string;
  requests_payment: string;
  requests_property: string;
  requests_date: string;
  requests_date_log: string;
  requests_status: string;
  requests_zona: string;
  requests_address: string;
  requests_type: string;
  requests_actions: string;
  requests_selectbox: string;
  requests_modal_title: string;
  request_modal_history: string;
  requests_modal_sing_title: string;
  requests_modal_payment_title: string;
  requests_modal_close: string;
  requests_modal_pay: string;
  user_column_name: string;
  folio_column_name: string;
  request_column_name: string;
  property_column_name: string;
  property_column_status: string;
  property_column_zona: string;
  property_column_expediente: string;
  property_column_follow: string;
  property_column_address: string;
  datecreate_column_name: string;
  dateupdate_column_name: string;
  unexpected_error: string;
  messages_title: string;
  aprove_payment_title: string;
  messages_no: string;
  messages_subject: string;
  messages_content: string;
  messages_date: string;
  messages_type: string;
  messages_notification: string;
  messages_actions: string;
  messages_modal_title: string;
  messages_modal_close: string;
  no_column_name: string;
  subject_column_name: string;
  content_column_name: string;
  date_column_name: string;
  type_column_name: string;
  licenses_title: string;
  licenses_type: string;
  licenses_folio: string;
  licenses_applicant: string;
  licenses_turn: string;
  licenses_procedure: string;
  licenses_actions: string;
  licenses_selectcombo: string;
  licenses_modal_title: string;
  licenses_modal_close: string;
  newtransact_title: string;
  newtransact_clvC: string;
  newtransact_required: string;
  newtransact_button: string;
  status_display_title: string;
  settlement_title: string;
  personaldata_title: string;
  owner_button: string;
  lessee_button: string;
  letter_button: string;
  am_owner_button: string;
  am_agent_button: string;
  am_tenant_button: string;
  am_tenant_agent_button: string;
  am_poa_property_button: string;
  am_poa_legal_button: string;
  owner_documents_title: string;
  documents_info: string;
  historial_info: string;
  historial_tramite: string;
  agent_documents_title: string;
  owner_ine: string;
  agent_owner_ine: string;
  owner_document_property: string;
  agent_document_act: string;
  button_delete_doc: string;
  button_download_doc: string;
  button_cancel_doc: string;
  button_desist_procedure: string;
  button_discard_procedure: string;
  button_save_doc: string;
  button_send_doc: string;
  button_send_doc_sign: string;
  button_send_preventiva: string;
  button_return_data: string;
  tenant_documents_title: string;
  tenant_ine: string;
  tenant_ine_property: string;
  tenant_document_legal: string;
  table_files: string;
  poa_title: string;
  agent_poa_title: string;
  poa_ine_document: string;
  poa_document: string;
  ine_poa_agent: string;
  ine_poa: string;
  error_upload_file: string;
  info_send_file: string;
  success_upload_file: string;
  success_save_file: string;
  nav_citizen_request: string;
  nav_citizen_cveKey: string;
  requirement_pdf_title: string;
  requirement_pdf_name: string;
  requirement_pdf_link: string;
  requirement_pdf_button: string;
  star_request_title: string;
  requests_officer_title: string;
  requests_inspector_title: string;
  requests_secretary_title: string;
  requests_director_title: string;
  finished_requests_title: string;
  star_request_button: string;
  star_request_modal: string;
  end_request_close: string;
  end_request_title: string;
  end_request_message: string;
  end_request_button: string;
  request_form_validate: string;
  files_required: string;
  validate_request_button: string;
  continue_request_button: string;
  view_request_info: string;
  request_validate_title: string;
  request_validate_file: string;
  button_validate: string;
  button_invalidate: string;
  observation_to_inspector_button: string;
  request_validate_button: string;
  request_invalidate_button: string;
  modal_refuse_title: string;
  modal_preventiva_title: string;
  modal_refese_text: string;
  modal_preventiva_text: string;
  input_refuse: string;
  input_preventiva: string;
  input_desist: string;
  input_discard: string;
  input_refuse_invalid: string;
  input_desist_invalid: string;
  input_discard_invalid: string;
  message_refuse_doc: string;
  message_preventiva_doc: string;
  message_approve_doc: string;
  refuse_request_title: string;
  approve_secretary_request_title: string;
  preventiva_request_title: string;
  observation_to_inspector_title: string;
  desist_title: string;
  discard_title: string;
  refuse_request_content: string;
  desist_request_content: string;
  discard_request_content: string;
  refuse_request_alert: string;
  approve_request_title: string;
  approve_desecho_title: string;
  approve_request_content: string;
  approve_request_pendiente_pago: string;
  approve_request_pago_aprobado: string;
  approve_request_alert: string;
  approve_refuse_request_alert: string;
  info_INE_tooltip: string;
  info_INE_owner: string;
  info_INE_tenant: string;
  info_INE_property: string;
  solvent_label: string;
  link_get_pdf: string;
  request_name_user: string;
  request_fields_panel: string;
  request_field_input: string;
  request_field_num_input: string;
  request_field_save: string;
  fields_save: string;
  invalid_fields_data: string;
  invalid_email_field: string;
  invalid_fraccion_field: string;
  invalid_curp_field: string;
  note_type_document: string;
  continue_request: string;
  error_field_input: string;
  error_add_fiel: string;
  error_add_doc: string;
  invalid_email_input: string;
  info_document_property: string;
  info_INE_attorney: string;
  info_document_act: string;
  info_power_attorney: string;
  info_ine_legal_person: string;
  error_all_fields: string;
  approve_all: string;
  approve_cargos: string;
  refuse_all: string;
  refuse_all_title: string;
  refuse_all_content: string;
  desist_content: string;
  discard_content: string;
  admin_users_title: string;
  admin_create_user_button: string;
  admin_create_user: string;
  create_user_header: string;
  create_user_rol: string;
  create_user_curp: string;
  create_user_username: string;
  create_user_adress: string;
  create_user_suburb: string;
  create_user_cp: string;
  create_user_button: string;
  create_input_invalid: string;
  create_input_puesto: string;
  create_input_modal: string;
  users_no: string;
  users_list_title: string;
  users_user_name: string;
  users_name: string;
  users_last_name: string;
  users_email: string;
  users_rol: string;
  users_actions: string;
  users_phone: string;
  no_column_user: string;
  user_column_user: string;
  last_column_user: string;
  rol_column_user: string;
  email_column_user: string;
  phone_column_user: string;
  users_adress: string;
  users_suburb: string;
  users_cp: string;
  edit_user_title: string;
  edit_user_sucess: string;
  edit_user_form: string;
  edit_user_paternal: string;
  edit_user_maternal: string;
  admin_fields_title: string;
  admin_signature_title: string;
  admin_create_field: string;
  admin_create_factor: string;
  admin_add_factor: string;
  admin_title_table: string;
  admin_title_UMA: string;
  admin_title_factors: string;
  fields_name: string;
  fields_orden: string;
  fields_description: string;
  fields_group: string;
  fields_type: string;
  fields_actions: string;
  field_column_field: string;
  orden_column_field: string;
  create_field_form: string;
  create_field_name: string;
  create_field_description: string;
  create_field_orden: string;
  create_field_type: string;
  create_field_group: string;
  create_field_header: string;
  create_field_color: string;
  label_nombre_banco: string;
  label_cuenta_deposito: string;
  label_referencia_bancaria: string;
  label_datos_banco: string;
  label_datos_efectivo: string;
  label_datos_pago_tarjeta: string;
  label_end_point_pago: string;
  label_field_name: string;
  label_field_color: string;
  label_field_orden: string;
  label_field_obligatorio: string;
  label_field_capturable: string;
  field_type_value1: string;
  field_type_value2: string;
  field_type_value3: string;
  admin_requests_title: string;
  admin_related_procedure_title: string;
  create_requests_button: string;
  create_related_procedure_button: string;
  edit_related_procedure_button: string;
  requests_title_table: string;
  admin_requests_name: string;
  admin_requests_description: string;
  admin_documents_title: string;
  admin_create_document: string;
  admin_document_table: string;
  documents_related: string;
  tramite_related: string;
  document_name: string;
  document_orden: string;
  name_column_document: string;
  orden_column_document: string;
  field_modal_sucess: string;
  edit_field_title: string;
  edit_field_form: string;
  edit_field_success: string;
  create_form_document: string;
  label_document_name: string;
  label_document_description: string;
  label_document_formato: string;
  label_document_orden: string;
  label_document_obligatorio: string;
  radio_firma_municipio: string;
  radio_firma_global: string;
  radio_sin_firma: string;
  radio_tarjeta: string;
  radio_efectivo: string;
  radio_deposito: string;
  radio_document_general: string;
  radio_document_dictamen: string;
  label_select_check: string;
  create_document_success: string;
  edit_document_title: string;
  edit_document_form: string;
  edit_document_sucess: string;
  document_select_option: string;
  create_request_form: string;
  create_related_form: string;
  label_name_request: string;
  label_base_procedure: string;
  label_description_request: string;
  label_notes_pdf_request: string;
  label_check_active_request: string;
  label_check_license_request: string;
  label_check_personal_request: string;
  label_check_pago_inicio: string;
  label_ir_a_pago: string;
  label_check_pago_despues: string;
  label_check_pago_normal: string;
  label_check_property_request: string;
  label_check_tenant_request: string;
  label_check_legal_request: string;
  label_group_checks_request: string;
  label_group_checks_request_pago: string;
  edit_request_title: string;
  edit_request_form: string;
  admin_button_add: string;
  admin_button_delete: string;
  add_document_title: string;
  add_document_form: string;
  label_add_document: string;
  label_add_fundament: string;
  label_add_orden: string;
  label_add_activo: string;
  label_add_obligatorio: string;
  check_dynamic_document: string;
  check_dynamic_active_document: string;
  confirm_modal_yes_create: string;
  label_add_query_document: string;
  add_field_title: string;
  edit_field_matriz: string;
  message_create_status: string;
  create_config_status: string;
  add_field_form: string;
  label_add_field: string;
  label_add_origen_field: string;
  label_add_orden_field: string;
  label_add_grupo_field: string;
  label_add_encabezado_field: string;
  label_add_color_field: string;
  label_add_capturable_field: string;
  label_add_ver_field: string;
  label_add_ver_officer: string;
  label_add_ver_inspector: string;
  label_add_ver_secretary: string;
  create_request_sucess_modal: string;
  created_requests_view: string;
  create_requests_sucess_modal: string;
  edit_related_sucess_modal: string;
  delete_modal_title: string;
  delete_modal_text: string;
  delete_modal_yes: string;
  delete_modal_no: string;
  delete_document_modal_title: string;
  delete_document_modal_text: string;
  delete_request_modal_title: string;
  delete_request_modal_text: string;
  error_upload_format: string;
  error_download_format: string;
  error_download_old_file: string;
  delete_field_modal: string;
  delete_document_modal: string;
  delete_request_modal: string;
  edit_request_title_modal: string;
  edit_request_text_modal: string;
  user_profile_title: string;
  user_profile_setting: string;
  user_profile_mail: string;
  user_profile_password: string;
  user_profile_confirmpassword: string;
  user_profile_fiscales: string;
  user_profile_rfc: string;
  user_profile_curp: string;
  user_profile_key_file: string;
  user_profile_password_cer: string;
  user_profile_cer_file: string;
  update_user_button: string;
  aprove_errorfirmpassword: string;
  null_errorfirmpassword: string;
  user_profile_message_rfc: string;
  profile_msgconfirmpassword: string;
  create_tax_sucess: string;
  null_firmpassword: string;
  save_file_sucess: string;
  success_payment_title: string;
  success_payment_message: string;
  error_pdf_acuse: string;
  button_continue: string;
  signature_required: string;
  signature_files_required: string;
  sign_request_required: string;
  password_content: string;
  button_next_step: string;
  button_dictamen: string;
  button_preventiva: string;
  invalid_phone_input: string;
  aviso_request: string;
  aviso_request_descripcion: string;
  acuse_pdf_error: string;
  acuse_pdf_error_p: string;
  acuse_send_docs: string;
  download_acuse_pdf: string;
  download_formato_preventiva_pdf: string;
  solvent_title_modal: string;
  solvent_confirm_send: string;
  title_technical_support: string;
  message_modal_support: string;
  message_technical_support: string;
  message_sucess_support: string;
  message_required_support: string;
  message_view_cargos: string;
  input_required_terms: string;
  accessTime: string;
  menu_admin_list_menu: string;
  admin_list_menu_title: string;
  admin_create_menu: string;
  admin_menu_title_table: string;
  menuList_column_menu: string;
  delete_modal_menu: string;
  create_menu_form: string;
  create_menu_name: string;
  create_menu_path: string;
  create_menu_order: string;
  create_menu_level: string;
  select_menu_pater_screen: string;
  create_menu_button: string;
  create_menu_modal: string;
  create_menu_path_invalid: string;
  menu_modal_sucess: string;
  tittle_menu_edit: string;
  edit_menu_form: string;
  edit_menu_success: string;
  delete_menu_modal: string;
  delete_menu_modal_title: string;
  menu_admin_roles: string;
  admin_roles_title: string;
  admin_create_role: string;
  admin_role_title_table: string;
  delete_role_modal: string;
  delete_role_modal_title: string;
  field_column_role: string;
  delete_role_message_modal: string;
  create_role_form: string;
  admin_create_new_role: string;
  create_role_name: string;
  label_filter_data_user: string;
  admin_edit_role: string;
  update_role_form: string;
  edit_role_success: string;
  create_role_success: string;
  message_edit_role: string;
  messageEmptyScreenList: string;
  messageEmptyProfileList: string;
  label_capture_document: string;
  label_view_document: string;
  label_document_type: string;
  label_active_document: string;
  label_required_document: string;
  label_required_field: string;
  title_update_document: string;
  edit_document_success: string;
  title_other_charges: string;
  title_total: string;
  title_cargos: string;
  label_other_charges: string;
  label_breakdown: string;
  label_not_any: string;
  label_total_request: string;
  label_start_of_process: string;
  label_completion_of_process: string;
  label_costo_adicional_construccion: string;
  label_tarifa: string;
  label_costo: string;
  label_costos: string;
  label_concepts: string;
  label_description: string;
  label_factor: string;
  label_get_uma: string;
  label_get_total_request: string;
  label_get_calculation: string;
  label_add_factor: string;
  label_add_procedencia: string;
  label_protesta: string;
  button_add_factor: string;
  button_cargo: string;
  button_send: string;
  button_cancel: string;
  button_cancel_procedure: string;
  button_save_calculation: string;
  button_cancel_calculation: string;
  button_cancel_factor: string;
  button_save_factor: string;
  label_warning_prod_close_modal: string;
  closeModalSessionProd: string;
  label_go_link_map: string;
  label_loading: string;
  invalid_number_int_ext: string;
  invalid_number_input: string;
  invalid_number_input_costo: string;
  invalid_input_costo: string;
  createValueUMA: string;
  admin_factors_title: string;
  admin_title_factors_table: string;
  admin_title_payments_table: string;
  admin_table_factor_name: string;
  admin_cost_title: string;
  admin_create_cost: string;
  title_costs_table: string;
  admin_uma_title: string;
  admin_add_uma_button: string;
  admin_title_uma_table: string;
  message_charge_success: string;
  message_delete_charge: string;
  message_next_procedures: string;
  admin_email_template: string;
  admin_title_email_template: string;
  admin_create_email_template: string;
  admin_edit_email_template: string;
  register_mail_confirmation: string;
  login_email_confirmation: string;
  label_name_template: string;
  label_text_template: string;
  template_email_modal_sucess: string;
  edit_template_modal: string;
  delete_template_modal_text: string;
  delete_template_modal_title: string;
  delete_template_modal: string;
  menu_reports: string;
  request_follow: string;
}

export class SpanishLocalization implements Localization {
  label_warning_prod_close_modal: 'Su sesión a expirado, por favor vuelva a iniciar sesión';
  status = 'Estatus';
  label_generales = 'Datos generales';
  label_styles_municipio = 'Estilos';
  label_color_botones = 'Color de botón de Login';
  label_color_background = 'Color de menu resaltado';
  label_color_pie = 'Color de pie de página';
  label_color_layout = 'Color de estilo';
  label_color_menu = 'Color de menú';
  label_color_header = 'Color de encabezado';
  label_name_municipio = 'Nombre del municipio';
  label_direccion_municipio = 'Dirección';
  label_url_firma_municipio = 'URL api firmado del municipio';
  label_url_firma_global = 'URL api firmado GlobalSoft';
  label_status_official = 'Funcionario';
  label_status_citizen = 'Ciudadano';
  title_dual_list_status = 'Estatus anteriores';
  label_status = 'Estatus:';
  label_procedure = 'Trámite:';
  title_dual_list_notification = 'Perfiles que reciben notificaciones';
  title_dual_list_see_status = 'Perfiles que pueden ver el estatus';
  title_dual_list_update_status = 'Perfiles que pueden modificar el estatus';
  title_config_status = 'Configuración de Estatus';
  button_edit_doc = 'Editar';
  accessTime = 'Acceso:';
  label_warning_close_modal = 'Su sesión ha expirado, vuelva a iniciar sesión';
  label_title_close_modal = 'Expiración de sesión';
  closeModalSession = 'Cerrar';
  closeModalSessionProd = 'Aceptar';
  label_tittle_document_edit = 'Editar documento';
  label_tittle_document = 'Documentos';
  label_tittle_request = 'Trámites';
  label_tittle_request_edit = 'Editar trámite';
  label_tittle_users = 'Usuarios';
  label_tittle_user_edit = 'Editar usuario';
  label_tittle_field_edit = 'Editar campo';
  label_tittle_fields = 'Campos';
  label_cancel_confirm = 'Confirmación de cancelación';
  label_cancel_confirm_create = 'Confirmación de creación de trámites';
  label_tramite_default = 'Para el perfil "ciudadano" se asignan todos los trámites por default ';
  label_preventive = 'Preventivas';
  label_procedure_inspected = 'Trámites inspeccionados';
  label_edit = 'Editar';
  label_procedure_process = 'Trámites en proceso';
  label_admin = 'Administración';
  menu = 'Menú';
  name_procedure = 'Nombre del trámite:';
  label_view_field = 'Perfiles que pueden ver el campo';
  label_capture_field = 'Perfiles que pueden capturar el campo';
  label_select = 'Seleccionado';
  label_dont_select = 'No seleccionado';
  label_suffix = 'Sufijo';
  label_prefix = 'Prefijo';
  label_alignment = 'Alineamiento';
  label_type_field = 'Tipo de campo';
  label_type_file = 'Tipo de archivo';
  label_no_confirm = 'No, cerrar'
  label_confirmation = 'Confirma que desea cancelar este trámite? Este cambio es irreversible'
  label_confirmation_create = 'Se creará(n) nuevo(s) trámite(s) ligados a este expediente, confirme para continuar'
  app_title = 'Visor Urbano San Pedro';
  app_credentialerror = 'El usuario y/o contraseña son incorrectos.';
  login_title = 'Iniciar Sesión';
  login_create = 'Crear usuario';
  login_login = 'Ingresar';
  login_forgotpasword = '¿Ha olvidado su contraseña?';
  login_email = 'Correo electrónico';
  login_password = 'Contraseña';
  register_title = 'Crear usuario';
  register_name = 'Nombre';
  register_firstname = 'Apellido materno';
  register_lastname = 'Apellido paterno';
  register_phone = 'Número de celular';
  register_confirmpassword = 'Confirmar contraseña';
  register_register = 'Registrarme';
  register_required = 'requerido para continuar...';
  register_requireda = 'requerida para continuar...';
  register_onlynumber = 'Es necesario capturar solo números para continuar...';
  register_mintendigits = 'Capture los 10 digitos para continuar...';
  register_correctemail = 'Escriba un correo válido para continuar...';
  register_correctcurp = 'La CURP no existe...';
  register_msgminpassword =
    'Contraseña debe de ser de al menos 6 caracteres para continuar...';
  register_msgconfirmpassword = 'Las contraseña no coindiden';
  register_usersucess = '¡Usuario registrado con éxito!';
  register_usermessage = 'se te ha enviado un correo de confirmación.';
  register_linkcontinue = 'Continuar';
  register_linkreturn = 'Ingresar al trámite';
  recovery_title = 'Recuperar Contraseña';
  recovery_subtitle =
    'Se enviará un correo a la cuenta especificada con las instrucciones para recuperar la contraseña.';
  recovery_send = 'Enviar';
  recovery_sendSuccess = 'Correo enviado correctamente.';
  recovery_sendError = 'Error al enviar el correo. Verifique.';
  recovery_emailnotfound = 'El correo proporcionado no existe.';
  menu_hidden = 'Contraer menú';
  menu_general = 'general';
  menu_title = 'Menú';
  menu_requests = 'Mi buzón de trámites';
  menu_mylicense = 'Mis Licencias';
  menu_mymessage = 'Mis Mensajes';
  menu_newtransact = 'Nuevo trámite';
  menu_status_visualizer = 'Visualizador de estatus';
  menu_historial = 'Historial';
  menu_status_pending_payment = 'Pendientes de confirmación';
  menu_settlement = 'Preventivas';
  menu_settlement_inspectory = 'Trámites inspeccionados';
  menu_requests_official = 'Trámites en proceso';
  menu_finished_requests = 'Trámites concluidos';
  menu_inspector_requests = 'Trámites en Inspección';
  menu_secretary_requests = 'Pendientes de Autorizar';
  menu_secretary_requests_discard = 'Trámites desechados';
  menu_users = 'Usuarios';
  menu_admin = 'Administración';
  menu_fields = 'Campos';
  menu_admin_requests = 'Trámites';
  menu_documents = 'Documentos';
  menu_admin_cost_request = 'Costos por trámite';
  menu_admin_factor = 'Factores trámite';
  menu_admin_UMA = 'UMA';
  menu_admin_Payment_Methods = 'Métodos de Pago';
  menu_admin_signature = 'Administrar uso de Firma electrónica';
  menu_desist = 'Desistidos / Desechados';
  menu_preventiva = 'Preventivas';
  menu_payment_methods = 'Métodos de pago';
  menu_settings_municipio = 'Configuración del municipio';
  menu_related_procedure = 'Trámites relacionados';
  menu_discard = 'Desechamientos';
  menu_email_templates = 'Plantillas de correo electrónico';
  menu_director_requests = 'Trámites en Dirección';
  transacts_cve1 = 'Número oficial';
  transacts_cve2 = 'Construcción de barda';
  transacts_cve3 = 'Construcción para casa habitación';
  transacts_cve4 = 'Alineamiento vial';
  transacts_cve5 = 'Demolición total';
  transacts_cve6 = 'Demolición parcial comercial y servicios';
  transacts_cve7 = 'Prorroga de construcción';
  transacts_cve8 = 'Demolición parcial unifamiliar';
  transacts_cve9 = 'Constancia de terminación de obra total';
  transacts_cve10 = 'Constancia de terminación de obra parcial';
  transacts_cve11 = 'Subdivisión, fusión y relotificación';
  transacts_cve67 = 'Uso de edificación';
  transacts_cve68 = 'Uso de suelo o proyecto arquitectónico';
  transacts_cve69 = 'Obra nueva de construcción habitacional unifamiliar';
  transacts_cve70 = 'Ampliación en construcción habitacional unifamiliar';
  transacts_cve71 = 'Demolición Total o Parcial';
  transacts_cve77 = 'Construcción\nCasa-Habitación';
  transacts_cve79 = 'Uso de suelo';
  transacts_cve80 = 'Dictamen para Establecimientos Comerciales';
  home_notification = 'Notificaciones';
  home_footer = 'Todos los derechos reservados.';
  error_404 = '404';
  error_404_title = 'La página solicitada no ha sido encontrada';
  menu_town_name = 'San Pedro Garza García';
  requests_title = 'Mi buzón de trámites';
  requests_preventiva_title = 'Preventivas';
  requests_user = 'Nombre de usuario';
  requests_folio = 'Folio';
  requests_expediente = 'Expediente';
  requests_folio_procedure = 'Folio del trámite';
  requests_street = 'Calle';
  requests_number = 'Núm';
  requests_suburb = 'Col';
  requests_payment = 'Total';
  requests_procedure = 'Trámite';
  requests_property = 'Expediente catastral';
  requests_date = 'Fecha de creación';
  requests_date_log = 'Fecha de actualización'
  requests_type = 'Tipo de trámite';
  requests_status = 'Estatus';
  requests_zona = 'Zona';
  requests_address = 'Dirección';
  requests_actions = 'Acciones';
  requests_selectbox = 'Seleccione una opción';
  requests_modal_title = 'Detalle de registro';
  request_modal_history = 'Historial';
  requests_modal_sing_title = 'Firmar trámite';
  requests_modal_payment_title = 'Cantidad a pagar';
  requests_modal_close = 'Cerrar';
  requests_modal_pay = 'Pagar';
  user_column_name = 'chrNombre' + 'chrPaterno';
  folio_column_name = 'chrFolioInterno';
  request_column_name = 'chrTramite';
  property_column_name = 'chrPredio';
  property_column_status = 'chrEstatus';
  property_column_zona = 'chrZona';
  property_column_expediente = 'chrExpediente';
  property_column_follow = 'chrFuncionario';
  property_column_address = 'campos';
  datecreate_column_name = 'createdAt';
  dateupdate_column_name = 'updateAt';
  unexpected_error = '¡Lo sentimos! Ocurrió un error inesperado';
  messages_title = 'Mis mensajes';
  aprove_payment_title = 'Aprobar pago';
  messages_no = 'No.';
  messages_subject = 'Asunto';
  messages_content = 'Mensaje';
  messages_date = 'Fecha';
  messages_type = 'Tipo';
  messages_notification = 'Notificación';
  messages_actions = 'Acciones';
  messages_modal_title = 'Detalle de mensaje';
  messages_modal_close = 'Cerrar';
  no_column_name = 'id';
  subject_column_name = 'chrTitulo';
  content_column_name = 'chrMensaje';
  date_column_name = 'dtdTimeStamp';
  type_column_name = 'chrTipo';
  licenses_title = 'Mis Licencias';
  licenses_type = 'Tipo de licencia';
  licenses_folio = 'No. Licencia';
  licenses_applicant = 'Solicitante';
  licenses_turn = 'Giro';
  licenses_procedure = 'Expediente catastral';
  licenses_actions = 'Acciones';
  licenses_selectcombo = 'Seleccione una opción';
  licenses_modal_title = 'Detalle de registro';
  licenses_modal_close = 'Cerrar';
  newtransact_title = 'Búsqueda por expediente catastral';
  newtransact_clvC = 'Introduce el expediente catastral';
  newtransact_required = 'es requerido para continuar';
  newtransact_button = 'Iniciar Trámite';
  status_display_title = 'Visualizador de estatus del trámite';
  settlement_title = 'Preventivas';
  personaldata_title = '¿Quién tramita?';
  owner_button = 'Propietario';
  lessee_button = 'Arrendatario';
  letter_button = 'Representante legal';
  am_owner_button = 'Persona física';
  am_agent_button = 'Persona moral';
  am_tenant_button = 'Soy arrendatario del inmueble';
  am_tenant_agent_button =
    'Soy representante de una persona jurídica que es la arrendataria del inmueble';
  am_poa_property_button = 'El propietario es persona física';
  am_poa_legal_button = 'El propietario es persona moral';
  owner_documents_title = 'Documentos - Propietario';
  documents_info = 'Subir archivos';
  historial_info = 'Historial de rechazos';
  historial_tramite = 'Historial de trámite';
  agent_documents_title = 'Documentos - Representante legal';
  owner_ine = 'Identificación oficial del propietario del predio.';
  agent_owner_ine = 'Identificación oficial del apoderado del predio.';
  owner_document_property =
    'Documento legal que acredite la propiedad del inmueble';
  agent_document_act = 'Acta constitutiva y poder vigente';
  button_delete_doc = 'Eliminar';
  button_download_doc = 'Descargar';
  button_cancel_doc = 'Cancelar';
  button_desist_procedure = 'Desistimiento';
  button_discard_procedure = 'Desechar Trámite';
  button_save_doc = 'Guardar';
  button_send_doc = 'Enviar';
  button_send_doc_sign = 'Firmar y enviar';
  button_send_preventiva = 'Enviar preventiva';
  button_return_data = 'Regresar';
  tenant_documents_title = 'Documentos - Arrendatario';
  tenant_ine = 'IFE / INE arrendatario';
  tenant_ine_property = 'IFE / INE propietario';
  tenant_document_legal =
    'Documento que acredite legal disposición y facultad para hacer el trámite que corresponda';
  table_files = 'Documentos Adjuntos';
  poa_title = 'Documentos - Persona física';
  agent_poa_title = 'Documentos - Persona jurídica';
  poa_ine_document =
    'Identificación oficial del apoderado del predio por carta simple.';
  poa_document = 'Carta poder simple.';
  ine_poa_agent =
    'Identificación oficial del apoderado del predio de una persona jurídica';
  ine_poa = 'IFE / INE apoderado carta poder simple';
  error_upload_file = '¡No se permite descargar, aprobar o rechazar un archivo que no ha sido adjunto!';
  info_send_file = 'Faltan archivos por cargar, no se puede enviar';
  success_upload_file = 'Archivo cargado correctamente';
  success_save_file = 'Archivo guardado';
  nav_citizen_request = 'Nombre del trámite:';
  nav_citizen_cveKey = 'Expediente catastral:';
  requirement_pdf_title = 'Ficha de requisitos';
  requirement_pdf_link = 'Descarga requisitos del trámite';
  requirement_pdf_button = 'Iniciar trámite';
  requirement_pdf_name = 'Nombre del trámite';
  star_request_title = 'Documentos';
  requests_officer_title = 'Trámites en proceso';
  requests_inspector_title = 'Trámites en Inspección';
  requests_secretary_title = 'Trámites por Autorizar';
  requests_director_title = 'Trámites en Dirección';
  finished_requests_title = 'Trámites concluidos';
  star_request_button = 'Descargar requisitos';
  star_request_modal = 'Información acerca del documento';
  end_request_close = 'Cerrar';
  end_request_title = '¡Documentos enviados!';
  end_request_message = 'Los documentos fueron enviados correctamente';
  end_request_button = 'Acuse de recibido';
  request_form_validate =
    '¡No se puede enviar! Necesitas subir todos los documentos requeridos';
  files_required = 'Todos los archivos son requeridos';
  validate_request_button = 'Validar trámite';
  continue_request_button = 'Continuar';
  view_request_info = 'Detalles';
  request_validate_title = 'Aprobación de trámite';
  request_validate_file = 'Nombre de archivo:';
  button_validate = 'Aprobar';
  button_invalidate = 'Rechazar';
  observation_to_inspector_button = 'Observación a inspector';
  request_validate_button = 'Aprobar trámite';
  request_invalidate_button = 'Enviar Preventiva';
  modal_refuse_title = 'Documento a observación';
  modal_preventiva_title = 'Documento a preventiva';
  modal_refese_text = 'Por favor escribe el motivo para mandar este documento a observación ';
  modal_preventiva_text = 'Por favor escribe el motivo para mandar este documento a preventiva ';
  input_refuse = 'Motivo de la observación';
  input_preventiva = 'Motivo de la preventiva';
  input_desist = 'Motivo del desistimiento';
  input_discard = 'Motivo del desechamiento';
  input_refuse_invalid =
    'Es necesario capturar el motivo de la observación para continuar';
  input_desist_invalid =
    'Es necesario capturar el motivo del desistimiento para continuar';
  input_discard_invalid =
    'Es necesario capturar el motivo del desechamiento para continuar';
  message_refuse_doc = '¡El trámite se ha enviado a observación!';
  message_preventiva_doc = '¡El trámite se ha enviado a preventiva!';
  message_approve_doc = '¡Documento aprobado correctamente!';
  refuse_request_title = 'Observación';
  approve_secretary_request_title = 'Autorización de trámite';
  preventiva_request_title = 'Preventiva';
  observation_to_inspector_title = 'Observación a inspección';
  desist_title = 'Desistimiento';
  discard_title = 'Desechamiento';
  refuse_request_content = 'Este trámite generó una preventiva';
  desist_request_content = 'Este trámite ha sido desistido';
  discard_request_content = 'Este trámite ha sido desechado';
  refuse_request_alert =
    '¡No se puede rechazar el trámite porque todos los documentos han sido aprobados!';
  approve_request_title = 'Aprobar trámite';
  approve_desecho_title = 'Enviar desechamiento';
  approve_request_pendiente_pago = 'Este trámite está pendiente de pago, podrás ver el estatus de este trámite en el Visualizador de estatus';
  approve_request_pago_aprobado = 'Se ha aprobado el pago de éste trámite';
  approve_request_content = 'Este trámite ha sido aprobado';
  approve_request_alert = 'Todos los archivos deben ser aprobados';
  approve_refuse_request_alert = 'Todos los archivos deben ser aprobados y/o rechazados';
  info_INE_tooltip =
    'Si el propietario es persona física deberá presentar copia del  IFE o INE, Pasaporte mexicano vigente.';
  info_INE_owner = 'Credencial de elector vigente del apoderado';
  info_INE_tenant = 'Credencial de elector vigente del arrendatario';
  info_INE_property = 'Credencial de elector vigente del propietario';
  solvent_label = 'Motivo de la observación';
  link_get_pdf = 'Descargar formato';
  request_name_user = 'Nombre de usuario';
  request_fields_panel = 'Datos generales';
  request_field_input = 'Campo vacío, no se puede guardar';
  request_field_num_input = 'El campo Número exterior es obligatorio';
  request_field_save = 'Dato guardado correctamente';
  fields_save = 'Guardando datos';
  invalid_fields_data = 'No se puede enviar, hay campos sin llenar';
  invalid_email_field = 'Campo incorrecto, ingrese un correo valido';
  invalid_fraccion_field = 'Campo incorrecto, solo se permite subdividir 5 como máximo';
  invalid_curp_field = 'Campo incorrecto, ingrese una curp valida';
  note_type_document =
    'Recuerda subir tus documentos en formato pdf, png y jpg según se indique en la información del campo.';
  continue_request = 'Continuar trámite';
  error_field_input = ' vacío, es requerido';
  error_add_fiel =
    'Este campo ya está agregado en la lista de campos del trámite.';
  error_add_doc =
    'Este documento ya está agregado en la lista de documentos del trámite.';
  invalid_email_input = 'Correo electrónico incorrecto';
  info_document_property =
    'Escritura o título de propiedad debidamente registrado en el Registro Público de la Propiedad.' +
    ' En caso de que el propietario del inmueble sea una fiduciaria, copia simple del contrato de fideicomiso y todas sus modificaciones.';
  info_INE_attorney =
    'Si es persona física deberá presentar copia del IFE o INE, Pasaporte mexicano vigente.';
  info_document_act =
    'Acta constitutiva debidamente registrada Registro Público de la Propiedad y el Comercio o en el' +
    ' Instituto Registral y Catastral del Estado que corresponda y su última modificación, identificación oficial y poder notarial que' +
    ' acredite la representación legal del solicitante el cual debe contar mínimo con facultades para realizar actos de administración.';
  info_power_attorney =
    'Carta poder simple es el documento en el cual una persona autoriza a otra a realizar un trámite en su nombre,' +
    ' debe de ser firmada en presencia de dos testigos y anexar las identificaciones de las personas que reciben el poder,' +
    ' el que lo otorga y los testigos.';
  info_ine_legal_person =
    'Deberá presentar acta constitutiva debidamente registrada Registro Público de la Propiedad y el Comercio o' +
    ' en el Instituto Registral y Catastral del Estado que Corresponda y su última modificación, identificación oficial y poder notarial' +
    ' que acredite la representación legal del solicitante el cual debe contar mínimo con facultades para realizar actos de' +
    ' administración.';
  error_all_fields = 'Hay campos vacíos, no se puede enviar';
  approve_all = 'Aprobar todo';
  approve_cargos = 'Ver cargos';
  refuse_all = 'Rechazar todo';
  refuse_all_title = 'Rechazar todos los documentos';
  refuse_all_content = 'Por favor escribe el motivo de la observación';
  desist_content = 'Por favor escribe el motivo del desistimiento';
  discard_content = 'Por favor escribe el motivo del desechamiento';
  admin_users_title = 'Usuarios';
  admin_create_user_button = 'Crear usuario';
  admin_create_user = 'Crear usuario';
  create_user_header = 'Nuevo usuario';
  create_user_rol = 'Selecciona rol de usuario';
  create_user_username = 'Usuario';
  create_user_curp = 'CURP';
  create_user_adress = 'Dirección';
  create_user_suburb = 'Colonia';
  create_user_cp = 'Código postal';
  create_user_button = 'Guardar';
  create_input_invalid = 'Este campo es requerido';
  create_input_puesto = 'Puesto';
  create_input_modal = 'Confirmación';
  users_list_title = 'Usuarios registrados';
  users_user_name = 'Usuario';
  users_name = 'Nombre';
  users_last_name = 'Apellido';
  users_email = 'Correo eléctronico';
  users_rol = 'Rol';
  users_no = 'No.';
  users_actions = 'Acciones';
  users_phone = 'Teléfono';
  users_adress = 'Dirección';
  no_column_user = 'chrUsuario';
  user_column_user = 'chrNombre';
  last_column_user = 'chrPaterno';
  rol_column_user = 'intFKIDRol';
  email_column_user = 'chrCorreo';
  phone_column_user = 'chrTelefono';
  users_suburb = 'Colonia';
  users_cp = 'Código postal';
  edit_user_title = 'Editar usuario';
  edit_user_sucess = '¡Usuario editado con éxito!';
  edit_user_form = 'Actualizar usuario';
  edit_user_paternal = 'Apellido paterno';
  edit_user_maternal = 'Apellido materno';
  admin_fields_title = 'Campos';
  admin_signature_title = 'Usar firma electrónica';
  admin_create_field = 'Crear campo';
  admin_title_table = 'Campos registrados';
  admin_title_UMA = 'Alta UMA';
  admin_title_factors = 'Realizar Cálculo';
  fields_name = 'Nombre de campo';
  fields_orden = 'Orden';
  fields_description = 'Descripción';
  fields_group = 'Grupo';
  fields_type = 'Tipo de campo';
  fields_actions = 'Acciones';
  field_column_field = 'chrCampo';
  orden_column_field = 'intOrden';
  create_field_form = 'Nuevo campo';
  create_field_name = 'Nombre del campo';
  create_field_description = 'Descripción';
  create_field_orden = 'Orden';
  create_field_type = 'Tipo de campo';
  create_field_group = 'Grupo';
  create_field_header = 'Encabezado';
  create_field_color = 'Color';
  label_nombre_banco = 'Nombre del banco';
  label_cuenta_deposito = 'Cuenta de depósito';
  label_referencia_bancaria = 'Referencia bancaria';
  label_datos_banco = 'Datos bancarios';
  label_datos_efectivo = 'Datos para pago en efectivo';
  label_end_point_pago = 'Liga para pago con tarjeta';
  label_datos_pago_tarjeta = 'Datos para pago con tarjeta';
  label_field_name = 'Nombre del campo';
  label_field_color = 'Color';
  label_field_orden = 'Orden';
  label_field_obligatorio = 'Obligatorio';
  label_field_capturable = 'Capturable';
  field_type_value1 = 'text';
  field_type_value2 = 'number';
  field_type_value3 = 'email';
  admin_requests_title = 'Trámites';
  admin_related_procedure_title = 'Trámites relacionados';
  create_requests_button = 'Crear trámite';
  create_related_procedure_button ='Crear relación';
  edit_related_procedure_button = 'Editar relación';
  requests_title_table = 'Trámites registrados';
  admin_requests_name = 'Nombre de trámite';
  admin_requests_description = 'Descripción';
  admin_documents_title = 'Documentos';
  admin_create_document = 'Crear documento';
  admin_create_factor = 'Factor';
  admin_add_factor = 'Agregar factor';
  admin_document_table = 'Documentos registrados';
  documents_related = 'Documentos relacionados';
  tramite_related = 'Trámites relacionados'
  document_name = 'Nombre del documento';
  document_orden = 'Orden';
  name_column_document = 'chrDocumento';
  orden_column_document = 'intOrden';
  field_modal_sucess = 'Campo creado con éxito';
  error_create_form = '¡Lo sentimos!, Ocurrio un error';
  create_form_document = 'Nuevo documento';
  label_document_name = 'Nombre del documento';
  label_document_description = 'Descripción';
  label_document_formato = 'Formato';
  label_document_orden = 'Orden';
  label_document_obligatorio = 'Obligatorio';
  radio_firma_municipio = 'Firma municipio';
  radio_firma_global = 'Firma GlobalSoft';
  radio_sin_firma = 'Sin Firma';
  radio_tarjeta = 'Pago con tarjeta';
  radio_efectivo = 'Pago en efectivo';
  radio_deposito = 'Depósito bancario';
  radio_document_general = 'Es un documento general';
  radio_document_dictamen = 'Es Dictamen';
  label_select_check = 'Selecciona una opción';
  create_document_success = 'Documento creado con éxito';
  edit_document_title = 'Editar documento';
  edit_document_form = 'Actualizar documento';
  edit_document_sucess = 'Documento editado con éxito';
  document_select_option = 'Selecciona una opción:';
  edit_field_title = 'Editar campo';
  edit_field_form = 'Actualizar campo';
  edit_field_success = 'Campo editado con éxito';
  create_request_form = 'Nuevo trámite';
  create_related_form = 'Nueva relación';
  label_name_request = 'Nombre del trámite';
  label_base_procedure = 'Trámite base';
  label_description_request = 'Descripción del trámite';
  label_notes_pdf_request = 'Notas pdf';
  label_check_active_request = 'Activo';
  label_check_license_request = 'Es licencia';
  label_check_pago_inicio= 'Pagar al iniciar el trámite';
  label_ir_a_pago = 'El siguiente paso es realizar el pago de tu trámite';
  label_check_pago_despues= 'Pagar al incio del trámite y después de inspeccionado';
  label_check_pago_normal= 'Pagar con el flujo normal';
  label_check_personal_request = 'Requiere preguntas personales';
  label_check_property_request = 'Mostrar preguntas propietario';
  label_check_tenant_request = 'Mostrar preguntas arrendatario';
  label_check_legal_request = 'Mostrar preguntas carta poder';
  label_group_checks_request = 'Selecciona las siguientes opciones:';
  label_group_checks_request_pago = 'Momento de pago';
  edit_request_title = 'Editar trámite';
  edit_request_form = 'Actualizar trámite';
  admin_button_add = 'Agregar';
  admin_button_delete = 'Eliminar';
  add_document_title = 'Agregar documento';
  add_document_form = 'Documento';
  label_add_document = 'Documento';
  label_add_fundament = 'Fundamento';
  label_add_orden = 'Orden';
  label_add_activo = 'Activo';
  label_add_obligatorio = 'Obligatorio';
  check_dynamic_document = 'Es requerido dinámico';
  check_dynamic_active_document = 'Es activo dinámico';
  confirm_modal_yes_create = 'Sí, crear';
  label_add_query_document = 'Consulta';
  add_field_title = 'Agregar Campo';
  edit_field_matriz = 'Editar Campo';
  message_create_status = 'La configuración se ha guardado exitosamente.';
  create_config_status = 'Guardar Configuración de Estatus';
  add_field_form = 'Campo';
  label_add_field = 'Campo';
  label_add_origen_field = 'Origen';
  label_add_orden_field = 'Orden';
  label_add_grupo_field = 'Grupo';
  label_add_encabezado_field = 'Encabezado';
  label_add_color_field = 'Color';
  label_add_capturable_field = 'Capturable';
  label_add_ver_field = 'Lo puede ver el ciudadano';
  label_add_ver_officer = 'Lo puede ver el funcionario';
  label_add_ver_inspector = 'Lo puede ver el inspector';
  label_add_ver_secretary = 'Lo puede ver el secretario';
  create_request_sucess_modal = 'Trámite creado con éxito';
  create_requests_sucess_modal = 'Trámite(s) creado(s) con éxito relacionados al expediente: ';
  created_requests_view = 'Podrá visualizar estos trámites en el menú "Mi buzón de trámites" ';
  create_related_sucess_modal = 'Relación creada con éxito';
  edit_related_sucess_modal = 'Trámite actualizado con éxito';
  delete_modal_title = 'Eliminar Campo';
  delete_modal_text = '¿Seguro que quieres eliminar el campo ';
  delete_modal_yes = 'Sí, eliminar';
  delete_modal_no = 'Cancelar';
  delete_document_modal_title = 'Eliminar documento';
  delete_document_modal_text = '¿Seguro que quieres eliminar el documento ';
  delete_request_modal_title = 'Eliminar trámite';
  delete_request_modal_text = '¿Seguro que quieres eliminar el trámite ';
  error_upload_format = '¡Lo sentimos!, no se pudo subir el archivo';
  error_download_format = '¡Lo sentimos!, no se pudo descargar el archivo';
  error_download_old_file = 'Este támite no cuenta con historial vigente';
  delete_field_modal = '¡Campo eliminado!';
  delete_document_modal = '¡Documento eliminado!';
  delete_request_modal = '¡Trámite eliminado!';
  edit_request_title_modal = 'Actualizar trámite';
  edit_request_text_modal = 'Trámite editado con éxito';
  user_profile_title = 'Perfil de usuario';
  user_profile_setting = 'Configuración de la cuenta';
  user_profile_mail = 'Correo electrónico';
  user_profile_password = 'Contraseña';
  user_profile_confirmpassword = 'Confirmar Contraseña';
  user_profile_fiscales = 'Datos de firma electrónica';
  user_profile_rfc = 'R.F.C';
  user_profile_curp = 'CURP'
  user_profile_key_file = 'Archivo KEY';
  user_profile_cer_file = 'Archivo CER';
  user_profile_password_cer = 'Contraseña de verificación RFC';
  user_profile_message_rfc = 'En caso de requerir firma electrónica agregar los archivos del RFC del usuario';
  update_user_button = 'Guardar';
  aprove_errorfirmpassword = 'La contraseña de verificación RFC es inválida';
  null_firmpassword = 'La contraseña de verificación RFC no ha sido agregada en tu perfil';
  null_errorfirmpassword = 'La contraseña de verificación RFC es inválida o aun no ha sido agregada en tu perfil';
  profile_msgconfirmpassword = 'Las contraseñas no coinciden';
  create_tax_sucess = '¡Datos guardados con éxito!';
  save_file_sucess = 'Archivo procesado';
  success_payment_title = '¡Pago exitoso!';
  success_payment_message = 'El pago se realizó con éxito';
  error_pdf_acuse = '¡Lo sentimos!, No fue posible firmar el formato';
  button_continue = 'Finalizar inspección';
  signature_required = 'R.F.C y Contraseña CER requerido para continuar';
  signature_files_required =
    'Archivo .KEY y Archivo .CER requeridos para continuar';
  sign_request_required =
    'R.F.C, contraseña CER, archivo .KEY y archivo .CER requeridos para continuar';
  password_content = 'Por favor ingresa tu contraseña de verificación RFC';
  button_next_step = 'Siguiente paso';
  button_dictamen = 'Ver dictamen o licencia';
  button_preventiva = 'Ver preventiva';
  invalid_phone_input = 'Capture solo los 10 digitos';
  aviso_request =
    'En caso de enviarlo después de las 4pm se tomará día hábil el siguiente día';
  aviso_request_descripcion = 'Agrega una descripción de lo que desea solicitar y adjunta un archivo en el que agregue todos los documentos solicitados';
  acuse_pdf_error = '¡Lo sentimos! No fue posible firmar el formato.';
  acuse_pdf_error_p = 'Por favor valide su contraseña e intente nuevamente';
  acuse_send_docs = 'Enviando documentos';
  download_acuse_pdf = 'No olvides descargar tu acuse de recibido';
  download_formato_preventiva_pdf = 'Si necesita el formato de contestación de preventiva, descárgalo aquí: ';
  solvent_title_modal = 'Confirmación';
  solvent_confirm_send = 'Los archivos fueron envíados correctamente';
  title_technical_support = 'Buzón de soporte técnico';
  message_modal_support = '¿Necesitas Ayuda?';
  message_technical_support =
    'Mándanos un mensaje y enseguida nos pondremos en contacto contigo.';
  message_sucess_support = '¡Mensaje enviado con éxito!';
  message_required_support = 'Escriba un mensaje para continuar.';
  message_view_cargos = 'El costo ha sido verificado.';
  input_required_terms = 'Debes aceptar los término y condiciones';
  menu_admin_list_menu = 'Menús';
  admin_list_menu_title = 'Menús';
  admin_create_menu = 'Crear menú';
  admin_menu_title_table = 'Menús registrados';
  menuList_column_menu = 'chrMenu';
  delete_modal_menu = '¿Seguro que quieres eliminar el menú';
  create_menu_form = 'Nuevo menú';
  create_menu_name = 'Nombre del menú';
  create_menu_path = 'Ruta del menú';
  create_menu_order = 'Orden del menú';
  create_menu_level = 'Nivel del menú';
  select_menu_pater_screen = 'Pantalla padre del menú';
  create_menu_button = 'Guardar menú';
  create_menu_modal = 'Confirmación';
  create_menu_path_invalid =
    'Ruta incorrecta: debe iniciar con diagonal (/). No se permiten espacios debe reemplazarlos por un guión (-).';
  menu_modal_sucess = 'Menú creado con éxito';
  tittle_menu_edit = 'Editar menú';
  edit_menu_form = 'Actualizar menú';
  edit_menu_success = '¡Menú actualizado con éxito!';
  delete_menu_modal = '¡Menú eliminado!';
  delete_menu_modal_title = 'Eliminar menú';
  menu_admin_roles = 'Roles de usuario';
  admin_roles_title = 'Roles de usuario';
  admin_create_role = 'Crear rol';
  admin_role_title_table = 'Roles de usuario registrados';
  delete_role_modal = '¿Seguro que desea eliminar el rol de usuario';
  delete_role_modal_title = 'Eliminar rol de usuario';
  field_column_role = 'chrRol';
  delete_role_message_modal = '¡Rol de usuario eliminado!';
  create_role_form = 'Nuevo rol de usuario';
  admin_create_new_role = 'Crear rol de usuario';
  create_role_name = 'Nombre del rol';
  label_filter_data_user = 'Filtrar datos del usuario';
  admin_edit_role = 'Editar rol de usuario';
  update_role_form = 'Actualizar rol de usuario';
  edit_role_success = '¡Rol actualizado con éxito!';
  create_role_success = '¡Rol creado con éxito!';
  message_edit_role =
    'Los cambios se verán reflejados cuando inicie sesión nuevamente.';
  messageEmptyScreenList =
    'Debe seleccionar las pantallas que puede visualizar el rol.';
  messageEmptyProfileList =
    'Debe seleccionar los perfiles que pueden capturar y ver el documento.';
  label_capture_document = 'Perfiles que pueden capturar el documento';
  label_view_document = 'Perfiles que pueden ver el documento';
  label_document_type = 'Tipo de documentos permitidos';
  label_active_document = ' El documento permanecerá:';
  label_required_document = 'El documento será:';
  label_required_field = 'El campo será:';
  title_update_document = 'Editar documento';
  edit_document_success = '¡Documento editado con éxito!';
  title_other_charges = 'Costo del trámite';
  title_total = 'Total a pagar';
  title_cargos = 'Cargos';
  title_calculo = 'Realizar Cálculo';
  label_costo = 'Costo trámite:';
  label_other_charges = 'Otros cargos';
  label_breakdown = 'Desglose';
  label_not_any = 'Ninguno:';
  label_total_request = 'Total de trámite';
  label_start_of_process = 'Costo por inicio de trámite';
  label_completion_of_process = 'Costo por licencia';
  label_costo_adicional_construccion = 'Costo adicional por construcción';
  label_tarifa = 'Tarifa aplicada';
  label_costos = 'Centros de costo';
  label_concepts = 'Conceptos';
  label_description = 'Descripción';
  label_factor = 'Factor';
  label_get_uma = 'UMA';
  label_get_calculation = 'Cálculo';
  label_get_total_request = 'Costo del trámite';
  label_add_factor = 'Factores';
  label_add_procedencia = 'Procedencía';
  label_protesta = 'Bajo protesta de decir la verdad manifiesto que los datos contenidos en la presente solicitud, cálculos y especificaciones que contengan los estudios, peritajes, memorias, responsivas, planos y demás documentos son válidos, auténticos y veraces, siendo mi cargo y responsabilidad que en caso contrario la inexactitud de la información será motivo de la inmediata revocación de la autorización otorgada además de la aplicación de las sanciones y medidas correspondientes conforme a la Ley de Asentamientos Humanos, Ordenamiento Territorial y Desarrollo Urbano para el Estado de Nuevo León, así como el Reglamento de Zonificación y Usos del Suelo de San Pedro Garza García, Nuevo León y demás legislación aplicable.';
  button_add_factor = 'Agregar factor';
  button_cargo = 'Agregar cargo';
  button_send = 'Enviar';
  button_cancel = 'Cancelar';
  button_cancel_procedure = 'Cancelar Trámite';
  button_save_calculation = 'Guardar cálculo';
  button_cancel_calculation = 'Cancelar cálculo';
  button_cancel_factor = 'Cancelar factor';
  button_save_factor = 'Guardar factor';
  label_go_link_map = 'Ir al mapa';
  label_loading = 'Cargando...';
  invalid_number_int_ext = 'Campo incorrecto, ingrese números';
  invalid_number_input = 'Campo incorrecto';
  invalid_number_input_costo =  'Es necesario capturar números positivos decimales.';
  invalid_input_costo = 'Por favor ingrese un costo';
  createValueUMA = 'Valor de UMA';
  admin_factors_title = 'Factores trámite';
  admin_title_factors_table = 'Factores registrados';
  admin_title_payments_table = 'Métodos registrados';
  admin_table_factor_name = 'Nombre';
  admin_cost_title = 'Costos por trámite';
  admin_create_cost = 'Agregar costo';
  title_costs_table = 'Costos registrados';
  admin_uma_title = 'UMA';
  admin_add_uma_button = 'Agregar UMA';
  admin_title_uma_table = 'UMA registrados';
  message_charge_success = 'El cargo ha sido agregado con éxito.';
  message_delete_charge = 'El cargo ha sido eliminado';
  message_next_procedures = 'Tal vez necesites crear otros trámites que se encuentran relacionados con este, si es el caso puedes seleccionarlos aquí abajo';
  admin_email_template = 'Plantillas de correo electrónico';
  admin_title_email_template = 'Plantillas registradas';
  admin_create_email_template = 'Crear Plantilla';
  admin_edit_email_template =  'Editar Plantilla';
  register_mail_confirmation = 'El correo electrónico no coincide.';
  login_email_confirmation = 'Confirmación de correo electrónico';
  label_name_template = 'Nombre de la plantilla';
  label_text_template = 'Contenido';
  template_email_modal_sucess = '¡Plantilla creada con éxito!';
  edit_template_modal = '¡Plantilla actualizada con éxito!';
  delete_template_modal_text = '¿Seguro que quieres eliminar la plantilla ';
  delete_template_modal_title = 'Eliminar plantilla';
  delete_template_modal = '¡Plantilla eliminada!';
  menu_reports = 'Reportes';
  request_follow = 'Último que atendió';
}
