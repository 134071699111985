import { RequestRepository } from "src/app/core/repositories/RequestRepository";
import {
  RequestRequestModel,
  RequestResponseModel,
  PaymentRequestModel,
  PaymentRequestResponseModel,
  FetchRequestPaidResponseModel,
} from "src/app/core/model/RequestModel";
import { Observable, throwError } from "rxjs";
import {
  RequestResponseMapper,
  RequestRequestMapper,
} from "src/app/data/dto/RequestMapper";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { RequestResponseDTO, TermsUserDTO, TermsUserResponseDTO } from "src/app/data/dto/RequestDTO";
import {
  RequestUpdateResponseDTO,
  RequestUpdateDTO,
} from "src/app/data/dto/RequestUpdateDTO";
import { environment } from "src/environments/environment";
import { retry, catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthService } from "src/app/auth/service/auth.service";
import { MatrixRequestEditDTO, MatrixRequestEditResponseDTO, FetchMatrixRequestDTO, FetchMatrixRequestResponseDTO } from '../dto/MatrixRequestEditDTO';
import {
  RequestCreateRequestDTO,
  RequestCreateResponseDTO,
} from "src/app/data/dto/RequestCreateDTO";
import {
  RequestDeleteResponseDTO,
  RequestDeleteDTO,
} from "src/app/data/dto/RequestDeleteDTO";
import {
  RequestFetchRequestDTO,
  RequestFetchResponseDTO,
} from "src/app/data/dto/RequestFetchDTO";
import {
  MatrixRequestCreateDTO,
  MatrixRequestCreateResponseDTO,
} from "src/app/data/dto/MatrixRequestCreateDTO";
import {
  MatrixRequestDeleteDTO,
  MatrixRequestDeleteResponseDTO,
} from "src/app/data/dto/MatrixRequestDeleteDTO";
import {
  SupportMessageResponseDTO,
  SupportMessageRequestDTO,
} from "../dto/SupportMessageDTO";
@Injectable({
  providedIn: "root",
})
export class RequestRestRepository implements RequestRepository {
  private headers: HttpHeaders;
  mapper = new RequestResponseMapper();
  requestMapper = new RequestRequestMapper();

  constructor(private http: HttpClient, private authService: AuthService) {
    const token = this.authService.currentUserValue.token;
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
  }

  fetch(request: RequestRequestModel): Observable<RequestResponseModel> {
    const endpoint = environment.requests_endpoint;
    const requestDTO: any = this.requestMapper.mapTo(request);
    const params = new HttpParams({
      fromObject: requestDTO,
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<RequestResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(retry(1), catchError(this.handleError))
      .pipe(map(this.mapper.mapFrom));
  }

  create(
    request: RequestCreateRequestDTO
  ): Observable<RequestCreateResponseDTO> {
    const endpoint = environment.request_create_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<RequestCreateResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  update(request: RequestUpdateDTO): Observable<RequestUpdateResponseDTO> {
    const endpoint = environment.request_update_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<any>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetchRequest(id: number): Observable<RequestFetchResponseDTO> {
    const endpoint = environment.request_fetch_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<RequestFetchResponseDTO>(
        `${environment.url_base}/${endpoint}/${id}`,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetchPaymentRequest(
    request: PaymentRequestModel
  ): Observable<PaymentRequestResponseModel> {
    const endpoint = environment.payment_request;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<PaymentRequestResponseModel>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetchPaidRequest(idFolio: string): Observable<FetchRequestPaidResponseModel> {
    const endpoint = environment.paid_request;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<FetchRequestPaidResponseModel>(
        `${environment.url_base}/${endpoint}/${idFolio}`,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  delete(requestID: RequestDeleteDTO): Observable<RequestDeleteResponseDTO> {
    const endpoint = environment.request_delete_endpoint;
    const params = new HttpParams({
      fromObject: {
        id: requestID.id.toString(),
      },
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .delete<RequestDeleteResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  createMatrix(
    request: MatrixRequestCreateDTO
  ): Observable<MatrixRequestCreateResponseDTO> {
    const endpoint = environment.matrix_request_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<MatrixRequestCreateResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteMatrix(
    request: MatrixRequestDeleteDTO
  ): Observable<MatrixRequestDeleteResponseDTO> {
    const endpoint = environment.delete_matrix_request_endpoint;
    const idRequest = request.idTramite;
    const idDocument = request.idDocumento;
    const httpOptions = { headers: this.headers };
    return this.http
      .delete<MatrixRequestDeleteResponseDTO>(
        `${environment.url_base}/${endpoint}/${idRequest}/${idDocument}`,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  createSupportMessage(
    message: SupportMessageRequestDTO
  ): Observable<SupportMessageResponseDTO> {
    const endpoint = environment.send_support_message;
    const httpOptions = { headers: this.headers };
    return this.http
      .post<SupportMessageResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(message),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  editMatrix(request:MatrixRequestEditDTO): Observable<MatrixRequestEditResponseDTO> {
    const endpoint = environment.matrix_request_update_endpoint;
    const httpOptions = { headers: this.headers };
    return this.http
      .put<MatrixRequestEditResponseDTO>(
        `${environment.url_base}/${endpoint}`,
        JSON.stringify(request),
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  fetchMatrixRequest(request:FetchMatrixRequestDTO): Observable<FetchMatrixRequestResponseDTO> {
    const endpoint = environment.matrix_request_fetch_endpoint;
    const params = new HttpParams({
      fromObject: {
        idTramite: request.idTramite.toString(),
        idDocumento: request.idDocumento.toString(),
      }
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
      .get<FetchMatrixRequestResponseDTO>(
        `${environment.url_base}/${endpoint}`, httpOptions)
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }


  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
