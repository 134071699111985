import { Observable } from 'rxjs';
import { RolesResponseDTO,
        CreateRoleRequestDTO,
        CreateRoleResponseDTO,
        DeleteRoleResponseDTO,
        FetchRoleResponseDTO,
        UpdateRoleRequestDTO,
        UpdateRoleResponseDTO} from '../../data/dto/RolesDTO';
import { RolesRequestModel, RolesResponseModel } from '../model/RoleModel';
export abstract class RolesListRepository {
    abstract fetch(): Observable<RolesResponseDTO>;
    abstract listRoles(params: RolesRequestModel): Observable<RolesResponseModel>;
    abstract create(role: CreateRoleRequestDTO): Observable<CreateRoleResponseDTO>;
    abstract delete(roleId: number): Observable<DeleteRoleResponseDTO>;
    abstract fetchRole(roleId: number): Observable<FetchRoleResponseDTO>;
    abstract update(role: UpdateRoleRequestDTO): Observable<UpdateRoleResponseDTO>;
}
