import { RequestTypeResponseDTO, RequestTypeRequestDTO } from './RequestTypeDTO';
import { Mapper } from 'src/app/core/base/Mapper';
import { RequestTypeResponseModel, RequestTypeRequestModel } from '../../core/model/RequestTypeModel';

export class RequestTypeResponseMapper implements Mapper<RequestTypeResponseDTO, RequestTypeResponseModel> {
    mapFrom(dto: RequestTypeResponseDTO): RequestTypeResponseModel {
        const model = {
            result: dto.result,
            requests: dto.tramites.map( item => {
                return {
                    id: item.id,
                    name: item.chrTramite,
                    description: item.chrDescripcion,
                    enabled: item.bolActivo,
                    idTown: item.intFKIDMunicipio
                };
            } )
        };
        return model;
    }

    mapTo(model: RequestTypeResponseModel): RequestTypeResponseDTO {
        return {
            result: model.result,
            tramites: null
        };
    }
}

export class RequestTypeRequestMapper implements Mapper<RequestTypeRequestDTO, RequestTypeRequestModel> {
    mapFrom(dto: RequestTypeRequestDTO): RequestTypeRequestModel {
        const model = {
            idTown: dto.idMunicipio,
            token: '',
        };
        return model;
    }

    mapTo(model: RequestTypeRequestModel): RequestTypeRequestDTO {
        return {
            idMunicipio: model.idTown,
        };
    }

}

