import { Mapper } from 'src/app/core/base/Mapper';
import { DataTableHelper } from './DataTableHelper';
import { ListMenuResponseDTO, ListMenuRequestDTO } from './MenuDTO';
import { ListMenuResponseModel, ListMenuRequestModel } from '../../core/model/MenusModel';

export class MenuResponseMapper implements Mapper<ListMenuResponseDTO, ListMenuResponseModel> {

    mapFrom(dto: ListMenuResponseDTO): ListMenuResponseModel {
        const model = {
            result: dto.result.map( item => {
                return {
                  chrMenu: item.chrMenu,
                  chrPath: item.chrPath,
                  intOrden: item.intOrden,
                  intNivelMenu: item.intNivelMenu,
                  intFKIDMenuPadre: item.intFKIDMenuPadre,
                  id: item.id
                };
            } ),
            total: dto.total,
            rowsFiltered: dto.rowsFiltered
        };
        return model;
    }

    mapTo(model: ListMenuResponseModel): ListMenuResponseDTO {
        return {
            result: null
        };
    }
}

export class MenuRequestMapper implements Mapper<ListMenuRequestDTO, ListMenuRequestModel> {
    mapFrom(dto: ListMenuRequestDTO): ListMenuRequestModel {
        const model: ListMenuRequestModel = {
          token: '',
          dataTableParams: null
        };
        return model;
    }

    mapTo(model: ListMenuRequestModel): ListMenuRequestDTO {
        return {
            token: '',
            draw: model.dataTableParams.draw,
            length: model.dataTableParams.length,
            start: model.dataTableParams.start,
            search: model.dataTableParams.search.value,
            order: DataTableHelper.makeOrderObject(model.dataTableParams)
        };
    }
}
