import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { PersonalDataRepository } from '../../core/repositories/PersonalDataRepository';
import { PersonalDataDTO, PersonalDataFilesDTO,  GetPersonalDataDTO,  ResponsePersonalDataFilesDTO } from '../dto/PersonalDataDTO';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from './../../auth/service/auth.service'
import { environment } from '../../../environments/environment';

@Injectable({
providedIn: 'root'
})
export class PersonalDataRestRepository extends PersonalDataRepository {
    private headers: HttpHeaders;

    constructor( private http: HttpClient, private auth: AuthService) {
        super();
        let token = this.auth.currentUserValue.token
        this.headers = new HttpHeaders({
            'Authorization': "Bearer "+ token,
            'Content-Type': "application/json"
        });
    }

    send(file: PersonalDataDTO): Observable<PersonalDataFilesDTO> {
        let endpoint = environment.send_files_endpoint;
        let httpOptions = { headers: this.headers };
        return this.http
        .post<PersonalDataFilesDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(file), httpOptions)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }



  consultar(document: GetPersonalDataDTO): Observable<ResponsePersonalDataFilesDTO> {
    const endpoint = environment.send_files_endpoint;
    const params = new HttpParams({
        fromObject: {
            idSeguimiento: document.idSeguimiento.toString(),
            idMunicipio: document.idMunicipio.toString(),
        }
    });
    const httpOptions = { headers: this.headers, params };
    return this.http
        .get<ResponsePersonalDataFilesDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
        .pipe(
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
}


    handleError(error: HttpErrorResponse) {
        return throwError(error);
    };
}
