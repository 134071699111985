import { MunicipiosRepository } from 'src/app/core/repositories/MunicipioRepository';
import { ListMunicipiosModelDTO, ListMunicipiosDTO, EditMunicipiosDTO } from 'src/app/data/dto/MunicipiosDTO';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MunicipiosRestRepository implements MunicipiosRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient, private authService: AuthService) {
        const token = this.authService.currentUserValue.token;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        });
    }

    list(): Observable<ListMunicipiosDTO> {
      const endpoint = environment.municipio_endpoint;
      const httpOptions = { headers: this.headers};
      return this.http
        .get<ListMunicipiosDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
        .pipe(
          catchError(this.handleError)
        )
        .pipe(map(result => {
          return result;
        }));
    }

    update(request: EditMunicipiosDTO): Observable<ListMunicipiosDTO> {
      const endpoint = environment.update_municipio_endpoint;
      const httpOptions = { headers: this.headers };

      return this.http
        .put<ListMunicipiosDTO>(`${environment.url_base}/${endpoint}`, request, httpOptions)
        .pipe(
          catchError(this.handleError)
        )
        .pipe(map(result => {
          return result;
        }));
    }


    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
